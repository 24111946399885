import React, { ComponentClass, FunctionComponent, MouseEvent, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import MessageIcon from '@material-ui/icons/Message';
import { Badge } from '@material-ui/core';

interface DialogPropsLike {
  open: boolean;
  handleClose: () => void;
}

interface ChatButtonProps<DialogProps extends DialogPropsLike> {
  hasUnreadMessages: boolean;
  chatComponent: FunctionComponent<DialogProps> | ComponentClass<DialogProps>;
  chatProps: Omit<DialogProps, keyof DialogPropsLike>;
  fontSize?: 'small' | 'large' | 'inherit' | 'default';
}

function ChatButton<DialogProps extends DialogPropsLike>({
  hasUnreadMessages,
  chatComponent: Chat,
  chatProps,
  fontSize = 'large',
}: ChatButtonProps<DialogProps>) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = (e: MouseEvent) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const dialogProps = { ...chatProps, open, handleClose } as DialogProps;

  return (
    <>
      <IconButton color="primary" size="small" onClick={handleClickOpen}>
        <Badge color="secondary" variant="dot" invisible={!hasUnreadMessages} style={{ marginRight: '0.1em' }}>
          <MessageIcon fontSize={fontSize} />
        </Badge>
      </IconButton>
      <Chat {...dialogProps} />
    </>
  );
}

export default ChatButton;
