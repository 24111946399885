import React from 'react';
import { ThemeProvider } from 'styled-components';
import { listColumns, viewColumns } from './columns';
import ProcedureTypeInput from '../../inputs/procedureType/ProcedureTypeInput';
import GraphQLCRUD from '../../../se/components/GraphQLCRUD';
import scheme from '../../../graph/procedureTypes';
import ProcedureTypeMobileListItem from './ProcedureTypeMobileListItem';
import { GlobalAverageTimesButton } from './GlobalAvgTimesConfig';
import procedureTypeSource from '../../../omnisearch/sources/hospital/procedureTypes';
import { InlineOmniSearchWithRouter } from '../../OmniSearch';
import { useApolloClient } from '@apollo/client';

const Empty = { illustration: theme => theme.illustrations.patients };

const ProcedureTypes = GraphQLCRUD({
  entityName: 'ProcedureType',
  scheme,
  List: {
    tableKey: 'ProcedureTypes',
    columns: listColumns,
    MobileItemComponent: ProcedureTypeMobileListItem,
    Empty,
    Actions: GlobalAverageTimesButton,
    simpleFilter: true,
    FilterComponent: props => {
      const client = useApolloClient();
      return (
        <InlineOmniSearchWithRouter
          history={props.history}
          location={props.location}
          source={[client, procedureTypeSource]}
          width="100%"
        />
      );
    },
  },
  Create: {
    Input: ProcedureTypeInput,
    Empty,
  },
  Show: {
    columns: viewColumns,
    Empty,
  },
  Edit: {
    Input: ProcedureTypeInput,
    Empty,
  },
});

export default props => (
  <ThemeProvider theme={{ gridListColumnsTemplate: 'minmax(10vw, 2fr) auto' }}>
    <ProcedureTypes {...props} />
  </ThemeProvider>
);
