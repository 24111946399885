import Box from '@material-ui/core/Box';
import React from 'react';
import hipaaBadge from '../assets/images/HIPAA-Compliance-Verification.png';

const HipaaBadge = () => (
  <Box component="a" href="https://compliancy-group.com/hipaa-compliance-verification" target="_blank" display="flex">
    <img src={hipaaBadge} alt="HIPAA Seal of Compliance" width="auto" height="65" />
  </Box>
);

export default HipaaBadge;
