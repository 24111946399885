import React, { LegacyRef, useEffect, useMemo, useRef, useState } from 'react';
import legacyPredefinedForms from '../../../entities/surgeonProcedures/predefinedForms/index.legacy';
import { isMedicalPassport, resolveFormRecursively } from '../../../entities/surgeonProcedures/FormContent';
import { useScope } from '../../../../hooks/useScope';
import get from 'lodash/get';
import merge from 'lodash/merge';
import { difference, omit } from 'lodash';
import getObjectDeepKeys from '../../../entities/procedures/utils/getObjectDeepKeys';
import { useSubscription } from '@apollo/client';
import form from '../../../../graph/surgeon/forms';
import flow from 'lodash/fp/flow';
import update from 'lodash/fp/update';
import { tryParseJson } from '../../../../util/parseJson';
import queryString from 'query-string';
import LegacyDatabaseHtmlForm from '../../../../components/entities/surgeonProcedures/DatabaseHtmlForm.legacy';
import FormIframe from '../../../entities/surgeonProcedures/FormIframe';

interface LegacyPredefinedFormProps {
  name: string;
  content: string;
  defaultValue: any;
  value: any;
  realValue: any;
  procedureId: string;
  signature: any;
  signedAt: any;
  entryQuestionnaire: any;
  lastPreOpCompletedEvent: any;
}

const LegacyPredefinedForm = ({
  name,
  content,
  defaultValue: defaultValueProp,
  value: valueProp,
  realValue: realValueProp,
  procedureId,
  signature,
  signedAt,
  entryQuestionnaire,
  lastPreOpCompletedEvent,
}: LegacyPredefinedFormProps) => {
  const LegacyForm = legacyPredefinedForms[content] || LegacyDatabaseHtmlForm;
  const Form = useMemo(() => resolveFormRecursively(content), [content]);

  const scope = useScope();
  const hospitalName = scope?.hospital?.name;
  const hospitalId = scope?.hospital?.id;
  const showQRCode = scope?.hospital?.modules?.showQRCode || false;

  const contentRef = useRef<any | undefined>();

  const questionnaire = get(entryQuestionnaire, 'questions');
  const questionnaireAnswers = get(entryQuestionnaire, 'answers');
  const defaultValue = useMemo(() => JSON.parse(defaultValueProp || '{}'), [defaultValueProp]);
  const value = useMemo(() => JSON.parse(valueProp || '{}'), [valueProp]);
  const realValue = useMemo(() => JSON.parse(realValueProp || '{}') || {}, [realValueProp]);
  const remoteValue = useMemo(() => merge({}, defaultValue, value), [defaultValue, value]);
  const actualValue = useMemo(() => (isMedicalPassport(content) ? realValue : value), [content, realValue, value]);
  const combinedValue = useMemo(() => {
    const keys = difference(getObjectDeepKeys(remoteValue), getObjectDeepKeys(actualValue));
    /* @ts-ignore */
    return omit(merge({}, remoteValue, actualValue), keys);
  }, [remoteValue, actualValue]);

  const scopeRef = useRef(scope);

  useEffect(() => {
    scopeRef.current = scope;
  }, [scope]);

  const [originalReadyForPrint, setOriginalReadyForPrint] = useState(false);
  const [iframeReadyForPrint, setIframeReadyForPrint] = useState(false);

  useEffect(() => {
    const defined = (get: () => any): Promise<void> =>
      new Promise(resolve => {
        const loop = () => {
          if (get()) {
            resolve();
          } else {
            requestAnimationFrame(loop);
          }
        };
        loop();
      });

    const loaded = async (doc: Document): Promise<void> => {
      await (doc.readyState === 'complete'
        ? Promise.resolve()
        : new Promise(resolve => {
            window.addEventListener(
              'load',
              () => {
                resolve(undefined);
              },
              { once: true }
            );
          }));

      const images = doc.querySelectorAll('img');

      for (const image of images) {
        await (image.complete
          ? Promise.resolve()
          : new Promise(resolve => {
              image.addEventListener(
                'load',
                () => {
                  resolve(undefined);
                },
                { once: true }
              );
            }));
      }
    };

    (async () => {
      try {
        await defined(() => scopeRef.current);
        await loaded(document);
        for (const iframe of document.querySelectorAll('iframe')) {
          if (iframe.contentDocument) {
            await loaded(iframe.contentDocument);
          }
        }
      } finally {
        requestIdleCallback(() => {
          requestIdleCallback(() => {
            setOriginalReadyForPrint(true);
          });
        });
      }
    })();
  }, []);

  useEffect(() => {
    if (originalReadyForPrint && iframeReadyForPrint) {
      document.body.classList.add('readyForPrint');
    }
  }, [originalReadyForPrint, iframeReadyForPrint]);

  return Form ? (
    <FormIframe ref={contentRef as LegacyRef<any>} onReady={() => setIframeReadyForPrint(true)}>
      <Form
        procedureId={procedureId}
        hospitalId={hospitalId}
        hospitalName={hospitalName}
        content={content}
        defaultValue={remoteValue}
        localValue={isMedicalPassport(content) ? actualValue : combinedValue}
        combinedValue={combinedValue}
        setValue={() => {}}
        signature={signature}
        signedAt={signedAt}
        questionnaire={questionnaire}
        questionnaireAnswers={questionnaireAnswers}
        lastPreOpCompletedEvent={lastPreOpCompletedEvent}
        formName={name}
        showQRCode={showQRCode}
      />
    </FormIframe>
  ) : (
    <LegacyForm
      procedureId={procedureId}
      hospitalId={hospitalId}
      hospitalName={hospitalName}
      ref={contentRef}
      content={content}
      defaultValue={remoteValue}
      value={isMedicalPassport(content) ? actualValue : combinedValue}
      setValue={() => {}}
      signature={signature}
      signedAt={signedAt}
      questionnaire={questionnaire}
      questionnaireAnswers={questionnaireAnswers}
      lastPreOpCompletedEvent={lastPreOpCompletedEvent}
      editable={false}
      formName={name}
      showQRCode={showQRCode}
    />
  );
};

export default () => {
  const queryParams = queryString.parse(location.search);

  const { data, loading } = useSubscription(form.getSubscription, {
    variables: { id: Number(queryParams.id), procedureId: Number(queryParams.procedureId) },
    skip: !isFinite(Number(queryParams.id)) || !isFinite(Number(queryParams.procedureId)),
  });

  const formData = flow(
    update('entryQuestionnaire.questions', tryParseJson),
    update('entryQuestionnaire.answers', tryParseJson),
    update('entryQuestionnaire.defaultValues', tryParseJson),
    update('entryQuestionnaire.defaultValues', (entryQuestionnaire: any) =>
      merge(get('defaultValues', entryQuestionnaire), get('answers', entryQuestionnaire))
    )
  )(data?.form || {});

  if (queryParams.content) {
    formData.content = queryParams.content;
  }

  return loading || !data ? null : <LegacyPredefinedForm {...formData} procedureId={queryParams.procedureId} />;
};
