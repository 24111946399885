import React, { FC, useState } from 'react';
import styled, { css } from 'styled-components';
import { PACU, POST_OP, PRE_OP, ANESTHESIOLOGIST } from '../../../entities/room/enums';
import responsiveStyles, { breakpoint } from '../../../../se/utilities/responsive';
import { Icon } from '../SchedulePage';
import { withTheme } from '../../../../se/theme';
import StaffShifts from './staff/StaffShifts';
import StaffShiftMenu from './staff/StaffShiftMenu';
import { StaffShiftRoomContext } from './staff/StaffShiftContext';
import { StaffSlotRoomContext } from './staff/StaffSlotContext';
import { Room } from '../../../../types/Room';
import MultipleSelectionCheckbox from './staff/MultipleSelectionCheckbox';
import { alpha, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Anesthesiologist } from '../schedule/anesthesiologist/PersonnelUtilityRoom';

const responsive = responsiveStyles as any;

const Staff = styled.div<{ expand: boolean; isFooter: boolean }>`
  display: flex;
  flex: 0 0 auto;
  padding: 0;
  background-color: #fff;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow: hidden;

  ${(props: any) =>
    props.isFooter
      ? `max-height: 70%;`
      : `
             max-height: 100%
             padding-left: 0.25em;
             padding-right: 0.25em;
             ;
          `}
  transition: all 0.3s ease;
  z-index: 3;

  ${responsive.md.andSmaller`
    flex-direction: column;
    padding: 0;
    line-height: 1.5;
    overflow-y: auto;
    ${(props: any) =>
      props.expand
        ? css`
            height: 60%;
          `
        : css`
            height: 5em;
          `};
  `}
`;

const Rooms = styled.div`
  display: flex;
  flex-wrap: wrap;

  padding-top: 0.25rem;
  padding-bottom: 0.25rem;

  margin-left: 0.25rem;
  margin-right: 0.25rem;

  > div {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }

  ${responsive.md.andSmaller`
    display: block;

    > div:first-of-type {
      border: none;
    }
  `}
`;

const RoomRow = styled.div<{ visible: boolean }>`
  display: flex;
  align-items: center;
  padding: 0.2em 0.25em;
  max-width: 100%;
  margin-left: -0.5em;
  margin-right: -0.5em;

  > div:first-of-type {
    font-weight: bold;
  }

  ${responsive.md.andSmaller`
    flex-direction: column;
    border-top: 1px solid rgba(0, 0, 0, 0.25);
    padding: .75em 1em;
  `}

  ${responsive.md.andSmaller`
    ${(props: any) =>
      props.visible
        ? null
        : css`
            display: none;
          `}
  `}
`;

const StaffMobile = styled.div<{ visible: boolean }>`
  display: flex;
  align-items: center;
  padding: 0 1em;
  margin: 1.375em 0;

  > i:first-of-type {
    background-color: ${withTheme((theme: any) => theme.button.primary.backgroundColor.string())};
    border-radius: 5em;
    padding: 0.25em;
  }

  > span {
    font-size: 1.125em;
  }

  ${responsive.lg.andLarger`
    display: none;
  `}
  ${(props: any) =>
    props.visible
      ? null
      : css`
          display: none;
        `}
`;

interface AnesthesiologistScheduleFooterProps {
  date?: Date;
  rooms: Array<Room>;
  staffId?: number;
  myProceduresChecked: boolean;
  isFooter: boolean;
  isKiosk?: boolean;
}

const getSortedRooms = (rooms: Array<Room>, myProceduresChecked: boolean, staffId?: number): Array<Room> =>
  staffId && myProceduresChecked
    ? [
        ...rooms
          .filter(({ type }) => type === ANESTHESIOLOGIST)
          .filter(room => room?.staffShifts.filter(s => s?.staff?.staffId === staffId).length > 0),
      ]
    : [...rooms.filter(({ type }) => type === ANESTHESIOLOGIST)];

const AnesthesiologistScheduleFooter: FC<AnesthesiologistScheduleFooterProps> = ({
  date,
  rooms,
  staffId,
  myProceduresChecked,
  isFooter,
  isKiosk,
}) => {
  const isClickable = window.innerWidth < breakpoint.md;
  const sortedRooms = getSortedRooms(rooms, myProceduresChecked, staffId);
  const showFooter = sortedRooms?.length > 0;

  const [expanded, setExpand] = useState(false);

  const onClickFooter = () => {
    isClickable && setExpand(!expanded);
  };

  if (!showFooter) return null;

  const classes = useStyles();

  return (
    <>
      <Box
        className={clsx(classes.curtain, {
          [classes.invisible]: !expanded,
        })}
        onClick={onClickFooter}
      />
      <Box
        className={clsx(classes.staff, {
          [classes.expand]: expanded,
          [classes.isFooter]: isFooter,
        })}
        style={{ cursor: isClickable ? 'pointer' : 'normal', flexDirection: isFooter ? 'row' : 'column' }}
      >
        <Rooms style={{ flexDirection: isFooter ? 'row' : 'column' }}>
          {sortedRooms.map(room => (
            <RoomRow style={{ flexDirection: isFooter ? 'row' : 'column' }} key={room.id} visible={expanded}>
              <Box mr={1}>{room.name}</Box>
              <Anesthesiologist date={date} roomId={room.id} roomType={room?.type} room={room} isKiosk={isKiosk} />
            </RoomRow>
          ))}
        </Rooms>
      </Box>
    </>
  );
};

export const useStyles = makeStyles(theme => ({
  curtain: {
    position: 'fixed',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 3,
    backgroundColor: theme.palette.background.default,
    opacity: 0.65,
    transition: 'opacity 0.3s ease',
  },
  invisible: {
    display: 'none',
    opacity: 0,
  },
  staff: {
    display: 'flex',
    flex: '0 0 auto',
    padding: 0,
    backgroundColor: alpha(theme.palette.text.primary, 0.1),
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    overflow: 'hidden',
    transition: 'all 0.3s ease',
    zIndex: 3,
    maxHeight: '100%',
    paddingLeft: '0.25em',
    paddingRight: '0.25em',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      padding: 0,
      lineHeight: 1.5,
      overflowY: 'auto',
      height: '5em',
    },
  },
  expand: {
    [theme.breakpoints.down('md')]: {
      height: '60%',
    },
  },
  isFooter: {
    maxHeight: '70%',
  },
  hdtv: {
    fontSize: '1rem',
  },
  kiosk: {
    [theme.breakpoints.down('lg')]: {
      fontSize: '1rem',
    },
  },
  kioskMaxWidth1600: {
    fontSize: '0.8125rem',
  },
  kioskMaxWidth1400: {
    fontSize: '0.6875rem',
  },
}));

export default AnesthesiologistScheduleFooter;
