import React from 'react';
import Header from './components/Header';
import PatientInfo from './components/PatientInfo';
import styles, { getGridParentStyles, getGridChildStyles2 } from './styles';
import MedicalPassportCheckbox from '../components/MedicalPassportCheckbox';
import { useValue } from '../ValueContext';
import get from 'lodash/get';
import Footer from './components/Footer';
import MedicationTextArea from '../components/MedicationTextArea';

const Page3extra = () => {
    const { questionnaireAnswers } = useValue();

    // medication
    function checkedCont(value) {
        if (value === 'Continue Med') {
            return true;
        }
        return false;
    }
    function checkedHold(value) {
        if (value === 'Hold medication') {
            return true;
        }
        return false;
    }
    function checkedNew(value) {
        if (value === 'New Prescription given') {
            return true;
        }
        return false;
    }
    function checkedPCP(value) {
        if (value === 'Check with PCP') {
            return true;
        }
        return false;
    }

    function formatDate(inputDate) {
        const day = inputDate.substr(0, 2);
        const month = inputDate.substr(2, 2);
        const year = inputDate.substr(4);

        return `${day}/${month}/${year}`;
    }

    const medicationList = get(
        questionnaireAnswers,
        'sections.medications.schema.medicationList.yes[1].schema.medication_list.list',
        []
    );
    let nameArr = [medicationList.length];
    let doseArr = [medicationList.length];
    let routeArr = [medicationList.length];
    let freqArr = [medicationList.length];
    let result = [medicationList.length];
    let purposeArr = [medicationList.length];
    let last = [medicationList.length];
    let status = [medicationList.length];

    for (let i = 0; i < medicationList.length; i++) {
        nameArr[i] = get(medicationList[i], 'schema.name.text', '');
        doseArr[i] = get(medicationList[i], 'schema.dosage.text', '');
        routeArr[i] = get(medicationList[i], 'schema.route.option', '');
        freqArr[i] = get(medicationList[i], 'schema.frequency.option', '');
        result[i] = nameArr[i] + ',' + doseArr[i] + ',' + routeArr[i] + ',' + freqArr[i];
        last[i] = get(medicationList[i], 'schema.lastTaken.text', '');

        purposeArr[i] = get(medicationList[i], 'schema.purpose.text', '');
        status[i] = get(medicationList[i], 'schema.status.option');
    }

    if (medicationList.length > 5) {
        return (
            <div id="page3extra" className="page" style={{ ...styles.root, fontFamily: 'Arial', gap:'10px' }}>
                <Header pageNumber={3} pageCount={10} />
                <div><b>MEDICATION RECONCILIATION</b></div>
                <PatientInfo />
                <div style={{ fontSize: '14px' }}>Medications Prior to Admission</div>
                <p style={{ textAlign: 'left', fontSize: '14px' }}>
                    List all medications, vitamins, nutritional and/or herbal supplements, including medications administrated via
                    pump, or patch, prior to admission.
                </p>
                <div style={medicationTable}>
                    <div style={{ fontSize: '14px', borderRight: '1px solid black' }}>Medication, dosage, route, frequency</div>
                    <div style={{ fontSize: '14px', textAlign: 'right' }}>Last </div>
                    <div style={{ fontSize: '14px', textAlign: 'left', borderRight: '1px solid black' }}>&nbsp;Taken</div>
                    <div style={{ fontSize: '14px', borderRight: '1px solid black' }}>Reason</div>
                    <div>
                        <div style={{ fontSize: '11px' }}>Cont = Continue Med</div>
                        <div style={{ fontSize: '11px' }}>Hold=Hold medication</div>
                        <div style={{ fontSize: '11px' }}>New = New Prescription given</div>
                        <div style={{ fontSize: '11px' }}>PCP = Check with PCP</div>
                    </div>
                    {[...Array(medicationList.length-5)].map((_, i) => (
                        <>
                            <div style={{ border: '1px solid black', borderBottom: 0, borderRight: 0, borderLeft: 0, wordBreak: 'break-all', fontSize: '12px' }}>
                                <MedicationTextArea name={`medication.r${i+5}.c0`} value={result[i+5]} />
                            </div>
                            <div style={{ border: '1px solid black', borderBottom: 0, borderRight: 0, fontSize: '12px' }}>
                                <MedicationTextArea name={`medication.r${i+5}.c1`} value={last[i+5]} />
                            </div>
                            <div style={{ border: '1px solid black', borderBottom: 0, borderRight: 0, fontSize: '12px' }}>
                                <MedicationTextArea name={`medication.r${i+5}.c4`} />
                            </div>
                            <div style={{ border: '1px solid black', borderBottom: 0, borderRight: 0, fontSize: '12px' }}>
                                <MedicationTextArea name={`medication.r${i+5}.c2`} value={purposeArr[i+5]} />
                            </div>
                            <div style={{ display: 'flex' }}>
                                <div style={{ border: '1px solid black', borderBottom: 0, borderRight: 0, paddingRight: '7px' }}>
                                    <input type="checkbox" name={`medication.r${i+5}.cont`} label="Cont" />
                                    <div style={{ fontSize: '10px' }}>Cont</div>
                                </div>
                                <div style={{ border: '1px solid black', borderBottom: 0, borderRight: 0, paddingRight: '7px' }}>
                                    <input type="checkbox" name={`medication.r${i+5}.hold`} label="Hold" />
                                    <div style={{ fontSize: '10px' }}>Hold</div>
                                </div>
                                <div style={{ border: '1px solid black', borderBottom: 0, borderRight: 0, paddingRight: '7px' }}>
                                    <input type="checkbox" name={`medication.r${i+5}.new`} label="New" />
                                    <div style={{ fontSize: '10px' }}>New</div>
                                </div>
                                <div style={{ border: '1px solid black', borderBottom: 0, borderRight: 0, paddingRight: '7px' }}>
                                    <input type="checkbox" name={`medication.r${i+5}.pcp`} label="PCP" />
                                    <div style={{ fontSize: '10px' }}>PCP</div>
                                </div>
                            </div>
                        </>
                    ))}
                </div>
            </div>
        );
    }
    else {
        return ''
    };
}


const medicationTable = { display: 'grid', border: '1px solid black', gridTemplateColumns: '45% 10% 10% 20% 15%' };
const prescriptionTable = { display: 'grid', border: '1px solid black', gridTemplateColumns: '50% 50%' };

export default Page3extra;
