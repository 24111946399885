import { useSubscription, useMutation } from '@apollo/client';
import { Box } from '@material-ui/core';
import MuiTab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import format from 'date-fns/format';
import React, { useCallback, useMemo, useState } from 'react';
import { lifecycle } from 'recompose';
import { ThemeProvider } from 'styled-components';
import waitingReady from '../../../assets/sound/waitingReady.mp3';
import scheme, { patientSubscription } from '../../../graph/patients';
import GraphQLCRUD from '../../../se/components/GraphQLCRUD';
import { getNestedValue } from '../../../se/utilities/data/object';
import { PatientType } from '../../../types/Patient';
import PatientInputFactory from '../../inputs/patient/PatientInput';
import { AutomaticPatientTracking } from '../../ModuleOptional';
import { soundAlert } from '../../pages/kiosk/monitor/Monitor';
import { getLogEntries, isPatientReady } from '../../pages/kiosk/tablet/utils';
import PatientShowTitle from '../../patient/PatientShowTitle';
import UnregisteredPatients from '../frontProcedures/UnregisteredPatients';
import {
  caretakerChatColumn,
  entryTimeColumn,
  familySmsStatusColumn,
  idColumn,
  nameColumn,
  note,
  phoneNumberColumn,
  physicianColumn,
  procedureTypeColumn,
  procedureTypeColumnList,
  rideHomeNumberColumn,
  roomColumn,
  smsNotificationNumbersColumn,
  visitDurationColumn,
  braceletIdColumn,
} from './columns';
import { mapEditItemProps, prepareCreateData } from './transducers';
import ActivePatientListHeader from './views/ActivePatientListHeader';
import EmptyPatientListHeader from './views/EmptyPatientListHeader';
import LocationPatientView from './views/LocationPatientView';
import { actionColumn } from './views/PatientLocationChangeColumn';
import ActivePatientListEmptyState from './views/ActivePatientListEmptyState';
import { arrivedUnregisteredPatients } from '../../../graph/procedures';
import { listColumns } from '../frontProcedures/columns';
import EntityCreate from '../../../se/components/entity/EntityCreate';
import routerHistory from '../../../essentials/routerHistory';
import omitDeep from 'omit-deep-lodash';
import { isFunction } from '../../../se/utilities/check';
import { ScopeProvider, withScope } from '../../../contexts/ScopeContext';

const PatientCreationInput = PatientInputFactory(true, true);
const PatientUpdateInput = PatientInputFactory(false);
const Empty = { illustration: theme => theme.illustrations.patients };

const isPACUReady = patient => isPatientReady(getLogEntries(patient))('PACU');
const readyPatientCount = patients =>
  (patients || []).filter(patient => isPatientReady(getLogEntries(patient))('PACU')).length;

/**
 * Used for the view given to the front desk users.
 */
const AutoCRUD = withScope(
  GraphQLCRUD({
    entityName: 'Patient',
    scheme: { ...scheme, list: patientSubscription, item: scheme.subscription },
    List: {
      tableKey: 'ActivePatients',
      useColumnSelection: true,
      columns: [
        nameColumn('name'),
        braceletIdColumn,
        physicianColumn,
        procedureTypeColumnList,
        visitDurationColumn,
        roomColumn,
        caretakerChatColumn,
        familySmsStatusColumn,
      ],
      TitleAndActions: EmptyPatientListHeader,
      Empty: ActivePatientListEmptyState,
      highlightedProvider: isPACUReady,
      highlightedRowStyles: {
        border: '3px solid #48e400',
        background: '#ecffe5',
      },
      effects: [
        lifecycle({
          UNSAFE_componentWillUpdate(nextProps) {
            if (!getNestedValue('data.loading', this.props)) {
              const shouldPlaySound =
                readyPatientCount(getNestedValue('data.patients', nextProps)) >
                readyPatientCount(getNestedValue('data.patients', this.props));
              soundAlert(waitingReady, shouldPlaySound);
            }
          },
        }),
      ],
    },
    Show: {
      columns: [
        idColumn,
        physicianColumn,
        procedureTypeColumn,
        smsNotificationNumbersColumn,
        phoneNumberColumn,
        rideHomeNumberColumn,
        note,
      ],
      Empty,
      Title: PatientShowTitle,
      View: LocationPatientView,
    },
    Create: {
      Input: PatientCreationInput,
      Empty,
      prepareCreateData,
      initialValue: { type: PatientType.Out },
    },
    Edit: {
      mapEditItemProps,
      Input: PatientUpdateInput,
      Empty,
      prepareUpdateData: prepareCreateData,
      skipRefetch: true,
    },
  })
);

const ManualCRUD = withScope(
  GraphQLCRUD({
    entityName: 'Patient',
    scheme: { ...scheme, list: patientSubscription, item: scheme.subscription },
    List: {
      tableKey: 'ActivePatients',
      useColumnSelection: true,
      columns: [
        nameColumn('name'),
        entryTimeColumn,
        physicianColumn,
        roomColumn,
        familySmsStatusColumn,
        caretakerChatColumn,
        actionColumn,
      ],
      TitleAndActions: EmptyPatientListHeader,
      Empty: ActivePatientListEmptyState,
      highlightedProvider: isPACUReady,
      highlightedRowStyles: {
        border: '3px solid #48e400',
        background: '#ecffe5',
      },
      effects: [
        lifecycle({
          UNSAFE_componentWillUpdate(nextProps) {
            if (!getNestedValue('data.loading', this.props)) {
              const shouldPlaySound =
                readyPatientCount(getNestedValue('data.patients', nextProps)) >
                readyPatientCount(getNestedValue('data.patients', this.props));
              soundAlert(waitingReady, shouldPlaySound);
            }
          },
        }),
      ],
    },
    Show: {
      columns: [
        idColumn,
        physicianColumn,
        procedureTypeColumn,
        smsNotificationNumbersColumn,
        phoneNumberColumn,
        rideHomeNumberColumn,
        note,
      ],
      Empty,
      Title: PatientShowTitle,
      View: LocationPatientView,
    },
    Create: {
      Input: PatientCreationInput,
      Empty,
      prepareCreateData,
      initialValue: { type: PatientType.Out },
    },
    Edit: {
      mapEditItemProps,
      Input: PatientUpdateInput,
      Empty,
      prepareUpdateData: prepareCreateData,
      skipRefetch: true,
    },
  })
);

const ArrivedUnregisteredPatients = onViewClick =>
  GraphQLCRUD({
    entityName: 'Arrival',
    scheme: { ...scheme, list: arrivedUnregisteredPatients },
    List: {
      tableKey: 'FScheduledProcedure',
      useColumnSelection: false,
      columns: listColumns,
      TitleAndActions: null,
      queryOptionsProvider: () => ({
        variables: {
          date: format(new Date(), 'YYYY-MM-DD'),
        },
      }),
      onViewClick,
    },
  });

const CRUD = AutomaticPatientTracking(AutoCRUD, ManualCRUD);

const TabRoutes = props => {
  const [activeTab, setActiveTab] = useState('Registered');

  const handleTabChange = tabTitle => {
    setActiveTab(tabTitle);
  };

  const { data } = useSubscription(arrivedUnregisteredPatients, {
    variables: {
      date: format(new Date(), 'YYYY-MM-DD'),
    },
  });

  let hasArrivedAt = false;

  if (data && data?.arrivals?.length > 0) {
    hasArrivedAt = true;
  }

  const renderTabContent = () => {
    switch (activeTab) {
      case 'Registered':
        return <CRUD {...props} />;
      case 'Unregistered':
        return <UnregisteredPatients {...props} />;
      default:
        return null;
    }
  };

  const [modalOpen, setModalOpen] = useState(false);
  const [procedureData, setProcedureData] = useState(null);

  const onRowClick = useCallback((data, ind) => {
    setModalOpen(true);
    setProcedureData(data);
  }, []);

  const ArrivedPatients = useMemo(() => ArrivedUnregisteredPatients(onRowClick), [onRowClick]);
  const initialValue = {
    type: PatientType.Out,
    name: procedureData?.patientName,
    physician: procedureData?.physicianId,
    procedureType: procedureData?.procedureTypeId,
    notificationNumbers: procedureData?.caretakerCellPhone,
    phoneNumber: procedureData?.patientCellPhone,
  };

  const sanitize = data => omitDeep(data, '__typename');
  const [mutate] = useMutation(scheme.create);
  const handleCreate = _ => {
    let data = isFunction(prepareCreateData) ? prepareCreateData(_) : _;
    data.procedureId = procedureData?.id;
    if (data.braceletId === null || data.braceletId === undefined) {
      data.braceletId = 'manual';
    }
    return mutate({
      variables: sanitize(data),
    });
  };

  return (
    <Box>
      {(location.pathname === '/patients' || location.pathname === '/patients/create') && (
        <Box mb={hasArrivedAt ? 8 : 0}>
          {hasArrivedAt && <ArrivedPatients />}
          {modalOpen && (
            <EntityCreate
              entityName="Patient"
              backUrlProvider={() => '/'}
              Input={PatientCreationInput}
              handleCreate={handleCreate}
              initialValue={initialValue}
              history={routerHistory}
            />
          )}
        </Box>
      )}
      <Tabs
        indicatorColor="primary"
        value={activeTab === 'Registered' ? 0 : 1}
        onChange={(event, newValue) => handleTabChange(newValue === 0 ? 'Registered' : 'Unregistered')}
        aria-label="simple tabs example"
      >
        <MuiTab label="Registered" />
        <MuiTab label="Unregistered" />
      </Tabs>
      {renderTabContent()}
    </Box>
  );
};

export default props => (
  <ScopeProvider>
    <ThemeProvider theme={{ gridListColumnsTemplate: '1fr' }}>
      <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h1">Patients</Typography>
        <ActivePatientListHeader />
      </Box>
      <TabRoutes {...props} />
    </ThemeProvider>
  </ScopeProvider>
);
