import React from 'react';
import Header from './components/Header';
import MedicalPassportTextArea from '../components/MedicalPassportTextArea';
import MedicalPassportRow3 from '../components/MedicalPassportRow3';
import MedicationTextArea from '../components/MedicationTextArea';
import MedicalPassportField from '../components/MedicalPassportField';
import styles from '../styles';
import toHighlightsString from '../../../../../questionnaire/toHighlightsString';
import toHighlightsString2 from '../../../../../questionnaire/toHighlightsString2';
import { useValue } from '../ValueContext';
import {
  cardiovascular,
  endocrine,
  gastrointestinal,
  gynaecological,
  hematological,
  musculoskeletal,
  neurological,
  other,
  psychiatric,
  pulmonary,
  renal,
  liver,
  airway,
  ob,
} from './paths';
import Footer from './components/Footer';
import toAge from '../../../../../questionnaire/toAge';
import toHeight from '../../../../../questionnaire/toHeight';
import toWeight from '../../../../../questionnaire/toWeight';
import toBMI from '../../../../../questionnaire/toBMI';
import get from 'lodash/get';
import toWeightLbs from '../../../../../questionnaire/toWeightLbs';
import {additionalcontent} from '../golfCoast/pages/contentlimit'
import toHighlightsString5 from '../../../../../questionnaire/toHighlightsString5';
import MedicalPassportTextArea2 from '../components/MedicalPassportTextArea2';
import toHighlightsString4 from '../../../../../questionnaire/toHighlightsString4';

function yesOrNo(value) {
  if (value === true) {
    return 'yes';
  } else if (value === false) {
    return 'no';
  }
  return '';
}

const Page5extra = () => {
  const { questionnaire, questionnaireAnswers } = useValue();
  console.log(questionnaireAnswers);
  let patientProblem = get(
    questionnaireAnswers,
    'sections.PreProcedureAssessment.schema.associatedProblem.yes[1].schema.associatedProblem_text2.text',
    ''
  )
    ? 'Patient:' +
      get(
        questionnaireAnswers,
        'sections.PreProcedureAssessment.schema.associatedProblem.yes[1].schema.associatedProblem_text2.text',
        ''
      )
    : '';

  let familyProblem = get(
    questionnaireAnswers,
    'sections.PreProcedureAssessment.schema.associatedProblem.yes[1].schema.associatedProblem_text.text',
    ''
  )
    ? 'Family:' +
      get(
        questionnaireAnswers,
        'sections.PreProcedureAssessment.schema.associatedProblem.yes[1].schema.associatedProblem_text.text',
        ''
      )
    : '';

  let anesthesiaProblem = patientProblem + '\n' + familyProblem;

  let alcohol = yesOrNo(get(questionnaireAnswers, 'sections.social.schema.drinking.yes', ''));
  let drugs = yesOrNo(get(questionnaireAnswers, 'sections.social.schema.drugs.yes', ''));
  // allergy
  const allergyList = get(questionnaireAnswers, 'sections.allergies.schema.q1.yes[1].schema.allergy_list.list', []);
  const allergyList2 = get(questionnaireAnswers, 'sections.allergies.schema.q2.yes[0]', false);
  const allergyList3 = get(questionnaireAnswers, 'sections.allergies.schema.q3.yes[0]', false);

  const allergyName = [];
  const reaction = [];

  for (let i = 0; i < allergyList.length; i++) {
    allergyName.push(get(allergyList[i], 'schema.q1_name.text', ''));
    reaction.push(get(allergyList[i], 'schema.q1_reaction.text', ''));
  }

  if (allergyList2) {
    allergyName.push('Latex');
    reaction.push(get(questionnaireAnswers, 'sections.allergies.schema.q2.yes[1].schema.q2_reaction.text', ''));
  }

  if (allergyList3) {
    allergyName.push(get(questionnaireAnswers, 'sections.allergies.schema.q3.yes[1].schema.q3_name.text', ''));
    reaction.push(get(questionnaireAnswers, 'sections.allergies.schema.q3.yes[1].schema.q3_reaction.text', ''));
  }

  function combineArrays(arr1, arr2) {
    let result = '';

    for (let i = 0; i < arr1.length && i < arr2.length; i++) {
      result += arr1[i] + ', ' + arr2[i] + '\n';
    }

    return result;
  }

  const smoker =
    get(questionnaireAnswers, 'sections.social.schema.smoking.option[0]', '') === 'N'
      ? get(questionnaireAnswers, 'sections.social.schema.smoking.option', '')
      : get(questionnaireAnswers, 'sections.social.schema.smoking.option[0]', '');

  function isSmoker(value) {
    if (value === 'Former Smoker') {
      return (
        'Former Smoker, ' +
        get(questionnaireAnswers, 'sections.social.schema.smoking.option[1].schema.former_amount.option') +
        ', ' +
        get(questionnaireAnswers, 'sections.social.schema.smoking.option[1].schema.former_quit.text')
      );
    } else if (value === 'Current Smoker') {
      return (
        'Current Smoker, ' +
        get(questionnaireAnswers, 'sections.social.schema.smoking.option[1].schema.current_amount.option') +
        ', ' +
        get(questionnaireAnswers, 'sections.social.schema.smoking.option[1].schema.current_howLong.text')
      );
    } else if (value === 'Never Smoked') {
      return 'Never Smoked';
    }
    return '';
  }
  const surgery = additionalcontent(toHighlightsString, [['priorSurgeries']], 70);
  const medication = additionalcontent(toHighlightsString, [['medications']], 70);
  const allergy = additionalcontent(toHighlightsString4, [['allergies']], 70);

  if (surgery=='No additional info' && medication=='No additional info'&&allergy=='No additional info') {
    return ''
  }
  else {
    return (
        <div id="page5extra" className="page" style={{fontFamily:'Arial', gap:'10px'}}>
          <Header pageNumber={5} pageCount={11} />
          <div style={styles.twoNotEqualColumns}>
            <div style={{fontSize:'14px'}}>
              <div style={styles.oneColumn}>Surgical History and Physical/Medication Reconciliation</div>
              <div style={styles.oneColumnLeftAlign}>
                <MedicalPassportField label="PreOp Diagnosis" name="page5.preOpDiag" fontSize={12} />
              </div>
              <div style={{...styles.twoNotEqualColumns1, border:'1px solid black'}}>
                <div>Planned Procedure</div>
                <MedicalPassportTextArea name="procedure.name" fontSize={12}/>
              </div>
              <div style={{...styles.sixEqualColumns, borderTop:'0'}}>
                <MedicalPassportField
                  label="Age"
                  name="page5.age"
                  path={['demographicInformation', 'dob']}
                  reducer={toAge}
                fontSize={12}/>
                <MedicalPassportField
                  label="Sex"
                  name="page5.sex"
                  path={['demographicInformation', 'sex']}
                  reducer={toHighlightsString}
                fontSize={12}/>
                <MedicalPassportField
                  label="Ht"
                  name="page5.Ht"
                  path={['demographicInformation', 'bmi']}
                  reducer={toHeight}
                fontSize={12}/>
                <MedicalPassportField
                  label="Wt"
                  name="page5.Wt"
                  path={['demographicInformation', 'bmi']}
                  reducer={toWeightLbs}
                fontSize={12}/>
                <MedicalPassportField
                  label="KG"
                  name="page5.KG"
                  path={['demographicInformation', 'bmi']}
                  reducer={toWeight}
                fontSize={12}/>
                <MedicalPassportField
                  label="BMI"
                  name="page5.BMI"
                  path={['demographicInformation', 'bmi']}
                  reducer={toBMI}
                fontSize={12}/>
              </div>
            </div>
            <div>
              <div style={styles.oneColumnNoLeftYesBottom}>Polaris Surgery Center</div>
              <div style={{ borderRight: '1px solid black', fontSize: '12px' }}>
                <MedicalPassportField label="Patient Name" name="procedure.patient.name" fontSize={12}/>
              </div>
              <div style={{ borderRight: '1px solid black', fontSize: '12px' }}>
                <div style={styles.twoNotEqualColumnsNoBorder}>
                  <MedicalPassportField label="DOB" name="page5.DOB" path={['demographicInformation', 'dob']} fontSize={12}/>
                  <MedicalPassportField label="Physician" name="procedure.physician.name" fontSize={12}/>
                </div>
              </div>
              <div style={{ borderRight: '1px solid black', borderBottom: '1px solid black', fontSize: '12px' }}>
                <div style={styles.twoNotEqualColumnsNoBorder}>
                  <MedicalPassportField label="DOS" name="procedure.serviceTime" fontSize={12}/>
                  <MedicalPassportField label="Account #" name="page5.AccountNum" fontSize={12}/>
                </div>
              </div>
            </div>
          </div>
          <div style={{fontSize:'14px'}}>
            <div style={{ marginRight: '3px' }}>
              <MedicalPassportTextArea2
                label="Previous Surgical History"
                name="anesthesia1"
                value={surgery}
             fontSize={12} />
            </div>
            <div style={{ marginRight: '3px' }}>
              <MedicalPassportTextArea2
                label={'Current Medications'}
                name="medications1"
                value={medication}
             fontSize={12} />
            </div>
            <div>
            <MedicalPassportTextArea2
                label={'Allergies'}
                name="medications1"
                value={allergy}
             fontSize={12} />
               </div>
          </div>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <Footer pageNumber={5} pageCount={10} />
        </div>
      );
    };
    
  }

  
export default Page5extra;
