import React, { useEffect, useMemo, useRef, useState } from 'react';
import Header from './components/Header';
import MedicalPassportField from '../components/MedicalPassportField';
import MedicalPassportCheckbox from '../components/MedicalPassportCheckbox';
import styles from '../styles';
import PatientInfo from './components/PatientInfo';
import Footer from './components/Footer';
import { useValue } from '../ValueContext';
import get from 'lodash/get';
import {
  toBooleanNegative,
  toBooleanPositive,
  toMale,
  toFemale,
  bmiGreaterThan35,
  bmiLessThanOrEqual35,
  age50AndOlderYes,
  age50AndOlderNo,
} from '../../../../../questionnaire/fallRiskReducers';
import FallRiskCheckbox from '../components/FallRiskCheckbox';

function initializeValues(rootRef, setChecked) {
  const root = rootRef.current;

  if (!root) {
    return;
  }

  const checkboxes = root.querySelectorAll('input[type="checkbox"]');

  setChecked(
    [...checkboxes].reduce((acc, checkbox) => {
      const value = checkbox.checked ? parseInt(checkbox.value) : 0;
      return { ...acc, [checkbox.name]: isFinite(value) ? value : 1 };
    }, {})
  );
}
const Page10 = ({ value, defaultValue }) => {
  const [values1, setValues1] = useState({});

  const total1 = useMemo(() => Object.values(values1).reduce((acc, value) => acc + value, 0), [values1]);

  const root1Ref = useRef(undefined);

  useEffect(() => {
    initializeValues(root1Ref, setValues1);
  }, []);

  const handleCheckboxChange = setValues => (value, name) => {
    setValues(prev => ({ ...prev, [name]: value }));
  };

  function yesOrNo(value, value1) {
    if (value === true) {
      return 'yes';
    } else if (value1 === false) {
      return 'no';
    }
    return '';
  }
  const { questionnaireAnswers } = useValue();

  let sleepApneaYes = get(questionnaireAnswers, 'sections.Pulmonary.schema.SleepApnea.yes[0]', '');
  let sleepApneaNo = get(questionnaireAnswers, 'sections.Pulmonary.schema.SleepApnea.yes', '');
  let sleepApnea = yesOrNo(sleepApneaYes, sleepApneaNo);

  function sleep(value) {
    const q1 = get(questionnaireAnswers, 'sections.sleepApneaQ.schema.SleepApneaQ_q1.yes', false);
    const q2 = get(questionnaireAnswers, 'sections.sleepApneaQ.schema.SleepApneaQ_q2.yes', false);
    const q3 = get(questionnaireAnswers, 'sections.sleepApneaQ.schema.SleepApneaQ_q3.yes', false);
    const q4 = get(questionnaireAnswers, 'sections.sleepApneaQ.schema.SleepApneaQ_q4.yes', false);

    if (q1 || q2 || q3 || q4) {
      return value;
    }
    return false;
  }

  function reverse(value) {
    return !value;
  }

  const bmiHeightFeet = get(
    questionnaireAnswers,
    'sections.demographicInformation.schema.bmi.bodyMassIndex.heightFt',
    ''
  );
  const bmiHeightInch = get(
    questionnaireAnswers,
    'sections.demographicInformation.schema.bmi.bodyMassIndex.heightIn',
    ''
  );
  const bmiWeight = get(questionnaireAnswers, 'sections.demographicInformation.schema.bmi.bodyMassIndex.weightLb', '');

  function calculateBMI(heightFeet, heightInches, weight) {
    // Convert input strings to numbers
    const feet = parseFloat(heightFeet);
    const inches = parseFloat(heightInches);
    const weightNum = parseFloat(weight);

    // Check if the conversion was successful
    if (isNaN(feet) || isNaN(inches) || isNaN(weightNum)) {
      // Handle invalid input
      return 'Invalid input. Please provide valid numbers for height and weight.';
    }

    // Convert height to inches
    const totalHeightInInches = feet * 12 + inches;

    // Calculate BMI (weight in pounds / (height in inches * height in inches)) * 703
    const bmi = (weightNum / (totalHeightInInches * totalHeightInInches)) * 703;

    // Check if BMI is greater than 35
    if (bmi > 35) {
      return true;
    } else {
      return false;
    }
  }

  const birthDay = get(questionnaireAnswers, 'sections.demographicInformation.schema.dob.dateOfBirth.day', '');
  const birthMonth = get(questionnaireAnswers, 'sections.demographicInformation.schema.dob.dateOfBirth.month', '');
  const birthYear = get(questionnaireAnswers, 'sections.demographicInformation.schema.dob.dateOfBirth.year', '');

  function calculateAge(birthDay, birthMonth, birthYear) {
    // Get the current date
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; // Months are zero-indexed
    const currentDay = currentDate.getDate();

    // Calculate the age
    let age = currentYear - birthYear;

    // Adjust the age based on the birth month and day
    if (currentMonth < birthMonth || (currentMonth === birthMonth && currentDay < birthDay)) {
      age--;
    }

    // Check if the age is greater than 50
    return age > 50;
  }

  const male = get(questionnaireAnswers, 'sections.demographicInformation.schema.sex.option', '');
  function isMale(value) {
    if (value === 'Male') {
      return true;
    }
    return false;
  }
  const neckSize = get(questionnaireAnswers, 'sections.sleepApneaQ.schema.SleepApneaQ_q5.option', '');
  function yesNeck(value) {
    if (value === 'Yes') {
      return true;
    }
    return false;
  }
  function noNeck(value) {
    if (value === 'No') {
      return true;
    }
    return false;
  }

  return (
    <div id="page10" className="page">
      <Header pageNumber={10} pageCount={11} />
      <br></br>
      <div style={{ textAlign: 'center' }}>
        <h3>Patient Sleep Apnea Questionnaire</h3>
      </div>
      <PatientInfo />
      <br></br>
      <div ref={root1Ref}>
        <div style={styles.threeNotEqualColumns}>
          <MedicalPassportField label="Sleep Apnea" name="page10.sleepApnea" value={sleepApnea} />
        </div>
        <br></br>
        <div style={styles.threeNotEqualColumnsBorder}>
          <div style={{ borderRight: '1px solid black', textAlign: 'center' }}>
            <strong>Yes</strong>
          </div>
          <div style={{ borderRight: '1px solid black', textAlign: 'center' }}>
            <strong>No</strong>
          </div>
        </div>
        <div style={styles.threeNotEqualColumnsBorder}>
          <div style={{ borderRight: '1px solid black', textAlign: 'center' }}>
            <div style={{ display: 'inline-block', marginRight: '0.5rem' }}>
              <FallRiskCheckbox
                name="page10.snoreYes"
                label=""
                path={['sleepApneaQ', 'SleepApneaQ_q1']}
                reducer={toBooleanPositive}
                value={1}
                onCheckboxChange={handleCheckboxChange(setValues1)}
              />
            </div>
          </div>
          <div style={{ borderRight: '1px solid black', textAlign: 'center' }}>
            <div style={{ display: 'inline-block', marginRight: '0.5rem' }}>
              <FallRiskCheckbox
                name="page10.snoreNo"
                label=""
                value={0}
                path={['sleepApneaQ', 'SleepApneaQ_q1']}
                reducer={toBooleanNegative}
              />
            </div>
          </div>
          Have you been told that you snore?
        </div>

        <div style={styles.threeNotEqualColumnsBorder}>
          <div style={{ borderRight: '1px solid black', textAlign: 'center' }}>
            <div style={{ display: 'inline-block', marginRight: '0.5rem' }}>
              <FallRiskCheckbox
                name="page10.tiredYes"
                label=""
                value={1}
                path={['sleepApneaQ', 'SleepApneaQ_q2']}
                reducer={toBooleanPositive}
                onCheckboxChange={handleCheckboxChange(setValues1)}
              />
            </div>
          </div>
          <div style={{ borderRight: '1px solid black', textAlign: 'center' }}>
            <div style={{ display: 'inline-block', marginRight: '0.5rem' }}>
              <FallRiskCheckbox
                name="page10.tiredNo"
                label=""
                value={0}
                path={['sleepApneaQ', 'SleepApneaQ_q2']}
                reducer={toBooleanNegative}
              />
            </div>
          </div>
          Are you often tired during the day?
        </div>

        <div style={styles.threeNotEqualColumnsBorder}>
          <div style={{ borderRight: '1px solid black', textAlign: 'center' }}>
            <div style={{ display: 'inline-block', marginRight: '0.5rem' }}>
              <FallRiskCheckbox
                name="page10.stopBreathingYes"
                label=""
                path={['sleepApneaQ', 'SleepApneaQ_q3']}
                reducer={toBooleanPositive}
                value={1}
                onCheckboxChange={handleCheckboxChange(setValues1)}
              />
            </div>
          </div>
          <div style={{ borderRight: '1px solid black', textAlign: 'center' }}>
            <div style={{ display: 'inline-block', marginRight: '0.5rem' }}>
              <FallRiskCheckbox
                name="page10.stopBreatingNo"
                label=""
                value={0}
                path={['sleepApneaQ', 'SleepApneaQ_q3']}
                reducer={toBooleanNegative}
              />
            </div>
          </div>
          Do you know if you stop breathing or has anyone witnessed you stop breathing while you were sleeping?
        </div>

        <div style={styles.threeNotEqualColumnsBorder}>
          <div style={{ borderRight: '1px solid black', textAlign: 'center' }}>
            <div style={{ display: 'inline-block', marginRight: '0.5rem' }}>
              <FallRiskCheckbox
                name="page10.highBloodPressureYes"
                label=""
                path={['sleepApneaQ', 'SleepApneaQ_q4']}
                reducer={toBooleanPositive}
                value={1}
                onCheckboxChange={handleCheckboxChange(setValues1)}
              />
            </div>
          </div>
          <div style={{ borderRight: '1px solid black', textAlign: 'center' }}>
            <div style={{ display: 'inline-block', marginRight: '0.5rem' }}>
              <FallRiskCheckbox
                name="page10.highBloodPressureNo"
                label=""
                value={0}
                path={['sleepApneaQ', 'SleepApneaQ_q4']}
                reducer={toBooleanNegative}
              />
            </div>
          </div>
          Do you have high blood pressure or take medication to control high blood pressure?
        </div>

        <div style={styles.threeNotEqualColumnsBorder}>
          <div style={{ borderRight: '1px solid black', textAlign: 'center' }}></div>
          <div style={{ borderRight: '1px solid black', textAlign: 'center' }}></div>
          <strong>
            If any of the above four questions are answered 'yes', please proceed to questions 5 through 8 below.
          </strong>
        </div>

        <div style={styles.threeNotEqualColumnsBorder}>
          <div style={{ borderRight: '1px solid black', textAlign: 'center' }}>
            <div style={{ display: 'inline-block', marginRight: '0.5rem' }}>
              <input
                type="checkbox"
                name="page10.bmi35Yes"
                label=""
                checked={sleep(calculateBMI(bmiHeightFeet, bmiHeightInch, bmiWeight))}
                value={1}
                onCheckboxChange={handleCheckboxChange(setValues1)}
              />
            </div>
          </div>
          <div style={{ borderRight: '1px solid black', textAlign: 'center' }}>
            <div style={{ display: 'inline-block', marginRight: '0.5rem' }}>
              <input
                type="checkbox"
                name="page10.bmi35No"
                label=""
                value={0}
                checked={sleep(reverse(calculateBMI(bmiHeightFeet, bmiHeightInch, bmiWeight)))}
              />
            </div>
          </div>
          Is your body mass index greater than 35?
        </div>

        <div style={styles.threeNotEqualColumnsBorder}>
          <div style={{ borderRight: '1px solid black', textAlign: 'center' }}>
            <div style={{ display: 'inline-block', marginRight: '0.5rem' }}>
              <input
                type="checkbox"
                name="page10.older50Yes"
                label=""
                checked={sleep(calculateAge(birthDay, birthMonth, birthYear))}
                value={1}
                onCheckboxChange={handleCheckboxChange(setValues1)}
              />
            </div>
          </div>
          <div style={{ borderRight: '1px solid black', textAlign: 'center' }}>
            <div style={{ display: 'inline-block', marginRight: '0.5rem' }}>
              <input
                type="checkbox"
                name="page10.older50No"
                label=""
                value={0}
                checked={sleep(reverse(calculateAge(birthDay, birthMonth, birthYear)))}
              />
            </div>
          </div>
          Are you 50 years old or older?
        </div>

        <div style={styles.threeNotEqualColumnsBorder}>
          <div style={{ borderRight: '1px solid black', textAlign: 'center' }}>
            <div style={{ display: 'inline-block', marginRight: '0.5rem' }}>
              <input
                type="checkbox"
                name="page10.neckYes"
                label=""
                path={['Pulmonary', 'Sleep apnea', 'yes', 'sleepApnea_q5']}
                checked={sleep(yesNeck(neckSize))}
                value={1}
                onCheckboxChange={handleCheckboxChange(setValues1)}
              />
            </div>
          </div>
          <div style={{ borderRight: '1px solid black', textAlign: 'center' }}>
            <div style={{ display: 'inline-block', marginRight: '0.5rem' }}>
              <input type="checkbox" name="page10.neckNo" label="" value={0} checked={sleep(noNeck(neckSize))} />
            </div>
          </div>
          Does your neck measure more than 15 3/4 inches &#40;40cm&#41; around &#40;collar size&#41;?
        </div>

        <div style={styles.threeNotEqualColumnsBorder}>
          <div style={{ borderRight: '1px solid black', textAlign: 'center' }}>
            <div style={{ display: 'inline-block', marginRight: '0.5rem' }}>
              <input
                type="checkbox"
                name="page10.maleYes"
                label=""
                value={1}
                checked={sleep(isMale(male))}
                onCheckboxChange={handleCheckboxChange(setValues1)}
              />
            </div>
          </div>
          <div style={{ borderRight: '1px solid black', textAlign: 'center' }}>
            <div style={{ display: 'inline-block', marginRight: '0.5rem' }}>
              <input type="checkbox" name="page10.maleNo" label="" checked={sleep(reverse(isMale(male)))} value={0} />
            </div>
          </div>
          Are you a male?
        </div>
        <div style={{ borderBottom: '1px solid black' }}>
          <div style={styles.oneColumn}>
            <div style={styles.threeNotEqualColumns}>
              <MedicalPassportField label="Risk Assessment" name="page10.riskAssessment" value={total1} />
            </div>
          </div>
        </div>
      </div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <Footer pageNumber={10} pageCount={10} />
    </div>
  );
};

export default Page10;
