import StaffShift from './StaffShift';
import Booking from './Booking';
import { Procedure } from './Procedure';
import { Patient } from './Patient';
import Anesthesiologist from './Anesthesiologist';

export enum RoomType {
  WaitingRoom = 'WAITING_ROOM',
  PreOp = 'PRE_OP',
  OR = 'OR',
  POST_OP = 'POST_OP',
  PACU = 'PACU',
  Utility = 'UTILITY',
  ANESTHESIOLOGIST = 'ANESTHESIOLOGIST',
}

export interface Room {
  id: number;
  name: string;
  type: RoomType;
  capacity: number;
  availability: number;
  airFiltrationEndsAt: string;
  airFiltrationWarningOn: boolean;
  airFiltrationDurationMin: number;
  procedures: Array<Procedure>;
  staffShifts: Array<StaffShift>;
  anesthesiologistShifts: Array<AnesthesiologistShiftRoom>;
  bookings: Array<Booking>;
  patient?: Patient;
  cleaningStartedAt: string;
  turnoverStartedAt: string;
  procedureStartedAt: string;
  order: number;
}
