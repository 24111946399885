import React, { useEffect } from 'react';
import styles from '../../styles';
import MedicalPassportField from '../../components/MedicalPassportField';
import toAge from '../../../../../../questionnaire/toAge';
import toSex from '../../../../../../questionnaire/toSex';
import toBMI, { calculateBMI } from '../../../../../../questionnaire/toBMI';
import toWeight from '../../../../../../questionnaire/toWeight';
import toHeight from '../../../../../../questionnaire/toHeight';
import MedicalPassportCheckbox from '../../components/MedicalPassportCheckbox';
import toStringWithoutQuestions from '../../../../../../questionnaire/toStringWithoutQuestions';
import getAgeAndBmi from './pageUtils';
import { getcontent } from './contentlimit';
import {
  cardiovascular,
  vascular,
  endocrine,
  gastrointestinal,
  gynaecological,
  hepatic,
  musculoskeletal,
  neurological,
  psychiatric,
  pulmonary,
  renal,
  other,
  drugs,
} from '../paths';
import MedicalPassportTextArea2 from '../../components/MedicalPassportTextArea2';
import toHighlightsString3 from '../../../../../../questionnaire/toHighlightsString3';
import toHighlightsString5 from '../../../../../../questionnaire/toHighlightsString5';
import toStringWithoutQuestions2 from '../../../../../../questionnaire/toStringWithoutQuestions2';
import toStringWithoutQuestions3 from '../../../../../../questionnaire/toStringWithoutQuestions3';
import { useValue } from '../../ValueContext';
import { get } from 'lodash';
import Barcode from '../../../Barcode';

const Page3 = ({
  formName,
  pageNumber,
  showQRCode,
  hospitalName,
  value,
  defaultValue,
  lastPreOpCompletedEvent,
  ...props
}) => {
  const providerId = defaultValue?.procedure?.patient?.providerId;
  const { questionnaireAnswers } = useValue();

  const surgery = getcontent(toHighlightsString5, [['priorSurgeries']], 150);
  const medication = getcontent(toHighlightsString5, [['medications']], 200);
  const drug = getcontent(toStringWithoutQuestions, drugs, 30)
    ? getcontent(toStringWithoutQuestions, drugs, 30)
    : 'N/A';
  const allergy = getcontent(toHighlightsString5, [['allergies']], 200);

  const cardiaclist = getcontent(toStringWithoutQuestions3, cardiovascular, 150);
  const pulmonarylist = getcontent(toStringWithoutQuestions3, pulmonary, 150);
  const neurologicallist = getcontent(toStringWithoutQuestions3, neurological, 150);
  const hematologicallist = getcontent(toStringWithoutQuestions3, vascular, 150);
  const endocrinelist = getcontent(toStringWithoutQuestions3, endocrine, 150);
  const musculolist = getcontent(toStringWithoutQuestions3, musculoskeletal, 150);
  const otherlist = getcontent(toStringWithoutQuestions3, other, 150);

  const { age } = getAgeAndBmi(defaultValue?.demographicInformation?.dob, defaultValue?.demographicInformation?.bmi);
  const bmi = calculateBMI(
    parseInt(defaultValue?.demographicInformation?.bmi?.weightLb, 10),
    parseInt(defaultValue?.demographicInformation?.bmi?.heightFt, 10),
    parseInt(defaultValue?.demographicInformation?.bmi?.heightIn, 10)
  );
  return (
    <div id="page3" className="page" style={{ height: '100%', paddingBottom: '0', fontFamily: 'Arial' }}>
      <div style={{ minHeight: '50rem', paddingBottom: '0' }}>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '50% 50%',
            gridGap: '5px',
            alignContent: 'start',
            fontSize: '12px',
          }}
        >
          <div style={styles.div}>
            <b>
              <div style={{ fontSize: '12px', paddingBottom: '0', lineHeight: '14px' }}>
                {(hospitalName || '').toUpperCase()}
              </div>
              <div style={{ fontSize: '20px', paddingBottom: '0' }}>Anesthesia Record</div>
            </b>
            <div style={{ fontSize: '12px' }}>
              <MedicalPassportTextArea2
                name="procedure.name"
                label="Procedure"
                style={{ display: 'flex' }}
                fontSize={11}
                nobackground
              />
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              alignItems: 'flex-end',
            }}
          >
            <div>
              {showQRCode && (
                <Barcode
                  formName={formName}
                  pageNumber={pageNumber}
                  providerId={defaultValue?.procedure?.patient?.providerId}
                  serviceTime={defaultValue?.procedure?.serviceTime}
                />
              )}
            </div>
            <div
              style={{
                fontSize: '12px',
                border: '1px solid black',
                padding: '5px',
                lineHeight: '15px',
              }}
            >
              <MedicalPassportField
                name="procedure.patient.name"
                label="Patient"
                fontWeight={'bold'}
                fontSize={14}
                nobackground
              />
              <MedicalPassportField name="procedure.patient.providerId" label="MRN#" fontSize={11} nobackground />
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: '40% 60%',
                  alignContent: 'start',
                }}
              >
                <MedicalPassportField
                  name="dob"
                  label="DOB"
                  path={['demographicInformation', 'dob']}
                  fontSize={11}
                  nobackground
                />
                <MedicalPassportField
                  name="age"
                  label="Age"
                  path={['demographicInformation', 'dob']}
                  reducer={toAge}
                  warning={age > 70}
                  fontSize={11}
                  nobackground
                />
                <MedicalPassportField name="procedure.serviceTime" label="DOS" fontSize={11} nobackground />
                <MedicalPassportField
                  name="sex"
                  label="Sex"
                  path={['demographicInformation', 'sex']}
                  reducer={toSex}
                  fontSize={11}
                  nobackground
                />
              </div>
              <MedicalPassportField name="procedure.physician.name" label="Surgeon" fontSize={11} nobackground />
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '35% 65%',
            borderBottom: 0,
          }}
        >
          <div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                fontSize: '11px',
              }}
            >
              <MedicalPassportField
                name="ht"
                label="Ht"
                path={['basicscreening', 'bmi']}
                reducer={toHeight}
                fontSize={11}
                nobackground
              />
              <MedicalPassportField
                name="wt"
                label="Wt"
                path={['basicscreening', 'bmi']}
                reducer={toWeight}
                fontSize={11}
                nobackground
              />
              <MedicalPassportField
                name="bmi"
                label="BMI"
                path={['basicscreening', 'bmi']}
                reducer={toBMI}
                warning={bmi !== '-' ? bmi > 40 : false}
                fontSize={11}
                nobackground
              />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                fontSize: '11px',
              }}
            >
              <MedicalPassportField name="drugHxPage4" label="Drug Hx" value={drug} fontSize={11} nobackground />
            </div>
          </div>
          <div>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '10% 90%',
                fontSize: '11px',
              }}
            >
              <div>Smoking:</div>
              <MedicalPassportTextArea2
                name="smokingPage3"
                paths={[
                  ['basicscreening', 'smokingStatus'],
                  ['basicscreening', 'smoking', 'yes'],
                ]}
                reducer={toHighlightsString3}
                fontSize={11}
                placeholder={'N/A'}
                nobackground
              />
            </div>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '10% 90%',
                fontSize: '11px',
              }}
            >
              <div>ETOH:</div>
              <div>
                <MedicalPassportTextArea2
                  name="etohpagae3"
                  paths={[
                    ['basicscreening', 'drinking'],
                    ['basicscreening', 'alcholAbuse'],
                  ]}
                  reducer={toStringWithoutQuestions2}
                  fontSize={11}
                  placeholder={'N/A'}
                  nobackground
                />
              </div>
            </div>
          </div>
        </div>
        <div style={styles.threeEqualColumns3}>
          <div
            style={{
              borderBottom: '1px solid black',
              marginBottom: '5px',
              fontSize: '12px',
              marginRight: '3px',
            }}
          >
            <b>SURGICAL HISTORY:</b>
          </div>
          <div
            style={{
              borderBottom: '1px solid black',
              marginBottom: '5px',
              fontSize: '12px',
              marginRight: '3px',
            }}
          >
            <b>CURRENT MEDICATIONS:</b>
          </div>
          <div
            style={{
              borderBottom: '1px solid black',
              marginBottom: '5px',
              fontSize: '12px',
              marginRight: '3px',
            }}
          >
            <b>ALLERGIES/SENSITIVITIES:</b>
          </div>
          <div
            style={{
              fontSize: '12px',
              marginRight: '3px',
              borderRight: '1px solid lightgray',
            }}
          >
            <MedicalPassportTextArea2
              name="surgeries1"
              value={surgery}
              fontSize={11}
              nobackground
              style={{ minHeight: '140px' }}
            />
          </div>
          <div
            style={{
              fontSize: '12px',
              marginRight: '3px',
              borderRight: '1px solid lightgray',
            }}
          >
            <MedicalPassportTextArea2
              name="medications1"
              value={medication}
              fontSize={11}
              nobackground
              style={{ minHeight: '140px' }}
            />
          </div>
          <div
            style={{
              fontSize: '12px',
              marginRight: '3px',
              borderRight: '1px solid lightgray',
            }}
          >
            <MedicalPassportTextArea2
              name="allergies1"
              value={allergy}
              fontSize={11}
              nobackground
              style={{ minHeight: '140px' }}
            />
          </div>
        </div>
        <div style={{ fontSize: '13px', borderBottom: '1px solid black', marginBottom: '2px' }}>
          <b>Hx of Anesthesia Complications</b>
        </div>
        <MedicalPassportTextArea2
          name="page4.hxAnesthesiaComplications"
          path={['anesthesia complications']}
          reducer={toStringWithoutQuestions2}
          fontSize={'12px'}
          style={{ minHeight: '35px' }}
          nobackground
        />
        <div style={{ fontSize: '14px' }}>
          <b>REVIEW OF SYSTEMS</b>
        </div>
        <section
          style={{
            breakInside: 'avoid',
          }}
        >
          <div
            style={{
              border: '1px solid black',
              lineHeight: '12px',
              borderLeft: 0,
              borderRight: 0,
              borderTop: 0,
            }}
          >
            <div style={styles.systemrow2}>
              <div
                style={{
                  borderRight: '1px solid black',
                  paddingLeft: '5px',
                  paddingTop: '3px',
                  fontSize: '12px',
                }}
              >
                Cardiovascular
              </div>
              <div
                style={{
                  display: 'flex',
                  padding: '1px',
                }}
              >
                <MedicalPassportTextArea2
                  name="systems.cardiovascular"
                  value={cardiaclist}
                  style={{ width: '100%', minHeight: '33px' }}
                  fontSize={'12px'}
                  nobackground
                  placeholder={'No'}
                />
              </div>
            </div>
            <div style={styles.systemrow2}>
              <div
                style={{
                  borderRight: '1px solid black',
                  paddingLeft: '5px',
                  paddingTop: '3px',
                  fontSize: '12px',
                }}
              >
                Pulmonary
              </div>
              <div
                style={{
                  display: 'flex',
                  padding: '1px',
                }}
              >
                <MedicalPassportTextArea2
                  name="systems.pulmonary"
                  value={pulmonarylist}
                  style={{ width: '100%', minHeight: '33px' }}
                  fontSize={'12px'}
                  nobackground
                  placeholder={'No'}
                />
              </div>
            </div>
            <div style={styles.systemrow2}>
              <div
                style={{
                  borderRight: '1px solid black',
                  paddingLeft: '5px',
                  paddingTop: '3px',
                  fontSize: '12px',
                }}
              >
                Renal
              </div>
              <div
                style={{
                  display: 'flex',
                  padding: '1px',
                }}
              >
                <MedicalPassportTextArea2
                  name="systems.renal"
                  paths={renal}
                  style={{ width: '100%', minHeight: '33px' }}
                  fontSize={11}
                  nobackground
                  placeholder={'No'}
                />
              </div>
            </div>
            <div style={styles.systemrow2}>
              <div
                style={{
                  borderRight: '1px solid black',
                  paddingLeft: '5px',
                  paddingTop: '3px',
                  fontSize: '12px',
                }}
              >
                Hepatic
              </div>
              <div
                style={{
                  display: 'flex',
                  padding: '1px',
                }}
              >
                <MedicalPassportTextArea2
                  name="systems.hepatic"
                  paths={hepatic}
                  style={{ width: '100%', minHeight: '14px' }}
                  fontSize={11}
                  nobackground
                  placeholder={'No'}
                />
              </div>
            </div>
            <div style={styles.systemrow2}>
              <div
                style={{
                  borderRight: '1px solid black',
                  paddingLeft: '5px',
                  paddingTop: '3px',
                  fontSize: '12px',
                }}
              >
                Neurological
              </div>
              <div
                style={{
                  display: 'flex',
                  padding: '1px',
                }}
              >
                <MedicalPassportTextArea2
                  name="systems.neurological"
                  value={neurologicallist}
                  style={{ width: '100%', minHeight: '33px' }}
                  fontSize={11}
                  nobackground
                  placeholder={'No'}
                />
              </div>
            </div>
            <div style={styles.systemrow2}>
              <div
                style={{
                  borderRight: '1px solid black',
                  paddingLeft: '5px',
                  paddingTop: '3px',
                  fontSize: '12px',
                }}
              >
                Gastrointestinal
              </div>
              <div
                style={{
                  display: 'flex',
                  padding: '1px',
                }}
              >
                <MedicalPassportTextArea2
                  name="systems.gastrointestinal"
                  paths={gastrointestinal}
                  style={{ width: '100%', minHeight: '33px' }}
                  fontSize={11}
                  nobackground
                  placeholder={'No'}
                />
              </div>
            </div>
            <div style={styles.systemrow2}>
              <div
                style={{
                  borderRight: '1px solid black',
                  paddingLeft: '5px',
                  paddingTop: '3px',
                  fontSize: '12px',
                }}
              >
                Hematological
              </div>
              <div
                style={{
                  display: 'flex',
                  padding: '1px',
                }}
              >
                <MedicalPassportTextArea2
                  name="systems.hematological"
                  value={hematologicallist}
                  style={{ width: '100%', minHeight: '33px' }}
                  fontSize={11}
                  nobackground
                  placeholder={'No'}
                />
              </div>
            </div>
            <div style={styles.systemrow2}>
              <div
                style={{
                  borderRight: '1px solid black',
                  paddingLeft: '5px',
                  paddingTop: '3px',
                  fontSize: '12px',
                }}
              >
                Endocrine/Metabolic
              </div>
              <div
                style={{
                  display: 'flex',
                  padding: '1px',
                }}
              >
                <MedicalPassportTextArea2
                  name="systems.endocrine2"
                  value={endocrinelist}
                  style={{ width: '100%', minHeight: '33px' }}
                  fontSize={11}
                  nobackground
                  placeholder={'No'}
                />
              </div>
            </div>
            <div style={styles.systemrow2}>
              <div
                style={{
                  borderRight: '1px solid black',
                  paddingLeft: '5px',
                  paddingTop: '3px',
                  fontSize: '12px',
                }}
              >
                Musculoskeletal
              </div>
              <div
                style={{
                  display: 'flex',
                  padding: '1px',
                }}
              >
                <MedicalPassportTextArea2
                  name="systems.musculoskeletal"
                  value={musculolist}
                  background="none"
                  style={{ width: '100%', minHeight: '33px' }}
                  fontSize={11}
                  nobackground
                  placeholder={'No'}
                />
              </div>
            </div>
            <div style={styles.systemrow2}>
              <div
                style={{
                  borderRight: '1px solid black',
                  paddingLeft: '5px',
                  paddingTop: '3px',
                  fontSize: '12px',
                }}
              >
                Psychiatric
              </div>
              <div
                style={{
                  display: 'flex',
                  padding: '1px',
                }}
              >
                <MedicalPassportTextArea2
                  name="systems.psychiatric"
                  paths={psychiatric}
                  background="none"
                  style={{ width: '100%', minHeight: '33px' }}
                  fontSize={11}
                  nobackground
                  placeholder={'No'}
                />
              </div>
            </div>
            <div style={styles.systemrow2}>
              <div
                style={{
                  borderRight: '1px solid black',
                  paddingLeft: '5px',
                  paddingTop: '3px',
                  fontSize: '12px',
                }}
              >
                OB/Gyn
              </div>
              <div
                style={{
                  display: 'flex',
                  padding: '1px',
                }}
              >
                <MedicalPassportTextArea2
                  name="systems.gyn"
                  paths={gynaecological}
                  reducer={toStringWithoutQuestions3}
                  style={{ width: '100%', minHeight: '33px' }}
                  fontSize={11}
                  nobackground
                  placeholder={'No'}
                />
              </div>
            </div>
            <div style={styles.systemrow2}>
              <div
                style={{
                  borderRight: '1px solid black',
                  paddingLeft: '5px',
                  paddingTop: '3px',
                  fontSize: '12px',
                }}
              >
                Other
              </div>
              <div
                style={{
                  display: 'flex',
                  padding: '1px',
                }}
              >
                <MedicalPassportTextArea2
                  name="systems.other"
                  value={otherlist}
                  style={{ width: '100%', minHeight: '33px' }}
                  fontSize={11}
                  nobackground
                  placeholder={'No'}
                />
              </div>
            </div>
          </div>
        </section>
        <div style={{ fontSize: '12px' }}>
          <div style={styles.darkGreyBackground}>PRE-ANESTHESIA EVALUATION</div>
        </div>

        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '45% 27% 28%',
            borderBottom: 0,
            fontSize: '11px',
          }}
        >
          <div style={{ borderRight: '1px solid lightgray', padding: '1px' }}>
            <b>Impression/Plan:</b>&nbsp;&nbsp;&nbsp;&nbsp;ASA&nbsp;&nbsp;&nbsp;&nbsp;1&nbsp;&nbsp;&nbsp;&nbsp;
            2&nbsp;&nbsp;&nbsp;&nbsp;3&nbsp;&nbsp;&nbsp;&nbsp;4
            <div
              style={{
                fontSize: '10px',
                lineHeight:'10px'
              }}
            >
              <div style={styles.twoEqualColumnsNoBorder}>
                <MedicalPassportCheckbox name="page3.general" label="General:"/>
                <MedicalPassportCheckbox name="page3.MAC" label="MAC/Sedation" />
              </div>
              <MedicalPassportCheckbox name="page3.regional" label="Regional" />
              <MedicalPassportCheckbox name="page3.ivra" label="IVRA" />
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: '86% 14%',
                  borderBottom: 0,
                }}
              >
                <MedicalPassportCheckbox
                  name="page3.riskOfDental"
                  label="Risk of Dental / Airway injury explained & accepted"
                />
                <MedicalPassportCheckbox name="page3.NA" label="N/A" />
              </div>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: '35% 65%',
                  borderBottom: 0,
                }}
              >
              <MedicalPassportCheckbox name="page3.NPO" label="NPO Satus Verified" />
              <div style={{paddingTop:'7px'}}>I have reviewed:</div>
              </div>
              <MedicalPassportCheckbox
                name="page3.vital"
                label="Vital signs, BMI, PMhx, Medications, NPO Status, SHx/Habits"
              />
              <MedicalPassportCheckbox name="page3.lab" label="Any labs/HCG/Dx Testing/Medical note" />
            </div>
          </div>

          <div
            style={{
              borderRight: '1px solid lightgray',
              padding: '2px 40px 2px 2px',
              fontSize: '11px',
              lineHeight: '13px',
            }}
          >
            <div>
              <b>Airway Exam:</b>&nbsp;Class&nbsp;&nbsp;I&nbsp;&nbsp; II&nbsp;&nbsp;III&nbsp;&nbsp;IV
            </div>
            <div>Mallampati:&nbsp;&nbsp;I&nbsp;&nbsp; II&nbsp;&nbsp;III&nbsp;&nbsp;IV</div>
            <MedicalPassportField
              name="page3.thyromental"
              label="Thyromental Distance"
              fontSize={'11px'}
              nobackground
              underline
            />
            <MedicalPassportField
              name="page3.mouthOpening"
              label="Mouth Opening"
              fontSize={'11px'}
              nobackground
              underline
            />
            <MedicalPassportField name="page3.neckROM" label="Neck ROM" fontSize={'11px'} nobackground underline />
            Comments:
            <MedicalPassportTextArea2 name="page3.comments" fontSize={'11px'} nobackground />
          </div>
          <div>
            <div
              style={{
                padding: '2px',
                fontSize: '11px',
                lineHeight: '13px',
              }}
            >
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: '52% 25% 23%',
                  borderBottom: 0,
                }}
              >
                <b>Denition:</b>
                <MedicalPassportCheckbox name="page3.intact" label="Intact" />
                <MedicalPassportCheckbox name="page3.poor" label="Poor" />
              </div>
              <div>
                Missing:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Caps/Bridge/Dentures
              </div>
              <MedicalPassportField name="page3.chipped" label="Chipped" fontSize={'11px'} nobackground />
              <div style={{ borderBottom: '1px solid lightgray' }}></div>
              <MedicalPassportField name="page3.heart" label="Heart" fontSize={'11px'} nobackground />
              <MedicalPassportField name="page3.lungs" label="Lungs" fontSize={'11px'} nobackground />
              <div style={{ borderBottom: '1px solid lightgray' }}></div>
              <MedicalPassportField name="page3.other" label="Other" fontSize={'11px'} nobackground />
              <MedicalPassportField name="page3.labs" label="Labs" fontSize={'11px'} nobackground />
            </div>
          </div>
        </div>
        <div
          style={{
            fontSize: '10px',
            marginTop: '3px',
          }}
        >
          Anesthesiologist - I have personally reviewed and discussed the Preoperative History with the patient. Risks,
          benefits, and anesthetic alternatives have been outlined to the patient. Patient agrees to proceed with
          proposed anesthetic.
        </div>
        <div
          style={{
            fontSize: '10px',
            marginTop: '5px',
          }}
        >
          __________________________________________________________ Date: __________________ Time: ____________________
        </div>
      </div>
      <footer
        style={{
          display: 'grid',
          gridTemplateColumns: '13.25% 14.5% 11.5% 17% 0.05% 14.5% 29%',
          fontSize: '11px',
          height: '20px',
        }}
      >
        <div style={{ paddingTop: '3px' }}>Provided by Patient{'('}</div>
        <MedicalPassportField name="questionnaire.firstSubmittedAt" fontSize={11} nobackground />
        <div style={{ paddingTop: '3px' }}>{')'} Last updated by</div>
        <MedicalPassportField name="procedure.patient.name" fontSize={11} nobackground />
        <div style={{ paddingTop: '3px' }}>{'('}</div>
        <MedicalPassportField name="questionnaire.submittedAt" fontSize={11} nobackground />
        <div>
          {')'}{' '}
          <span style={{ fontSize: '12px', marginLeft: '130px' }}>
            <b>Ospitek, Inc</b>
          </span>
        </div>
      </footer>
    </div>
  );
};

export default Page3;
