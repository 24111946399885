import React, { useEffect, useMemo, useState } from 'react';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Box from '@material-ui/core/Box';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { FormType } from '../../../types/Form';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { useApolloClient, useQuery, useSubscription } from '@apollo/client';
import { bulkPrintForms, bulkPrintFormsJob, list } from '../../../graph/surgeon/forms';
import groupBy from 'lodash/groupBy';
import { formTypeLabels } from '../procedures/enums';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import formatRelative from 'date-fns-3/formatRelative';
import { useTheme } from '@material-ui/core/styles';
import Spinner from '../../../se/components/Spinner';
import { useToaster } from '../../core/Toaster';
import { z } from 'zod';
import { tryParseJson2 } from '../../../util/parseJson';
import { Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';

interface BulkPrintDialogProps {
  open: boolean;
  onClose: () => void;
  items: any[];
}

const BulkPrintDialog = ({ open, onClose, items }: BulkPrintDialogProps) => {
  const client = useApolloClient();
  const toaster = useToaster();

  const [state, setState] = useState({});
  const [job, setJob] = useState<z.infer<typeof Job> | null>(null);

  const { data: formQuery } = useQuery(list);

  const reportForms = useMemo(
    () =>
      (formQuery?.forms || []).filter(
        e =>
          e.type === FormType.PreOpForm ||
          e.type === FormType.InTakeForm ||
          e.type === FormType.PreOpChartForm ||
          e.type === FormType.OrChartForm ||
          e.type === FormType.PacuChartForm
      ),
    [formQuery]
  );

  const sections = useMemo(() => groupBy(reportForms, _ => _.type), [reportForms]);

  useEffect(() => {
    setState(reportForms.reduce((acc, curr) => ({ ...acc, [curr.id]: true }), {}));
  }, [reportForms]);

  const anyChecked = useMemo(() => Object.values(state).includes(true), [state]);

  const handleCancel = () => onClose();

  const handleGenerate = async () => {
    if (anyChecked) {
      setBusy(true);

      const formIds = Object.entries(state)
        .filter(([_, value]) => !!value)
        .map(([key, _]) => parseInt(key, 10))
        .sort((a, b) => reportForms.find(x => x.id === a).order - reportForms.find(x => x.id === b).order);

      try {
        const buildResult = await client.mutate({
          mutation: bulkPrintForms,
          variables: { procedureIds: items.map(i => i.id), ids: formIds },
          fetchPolicy: 'network-only',
        });

        if (buildResult.data) {
          const parsed = z
            .object({
              id: z.number(),
              status: z.string(),
              itemStatuses: z.string(),
            })
            .safeParse(buildResult.data.bulkPrintForms);

          if (parsed.success) {
            const parsedStatus = JobStatus.safeParse(tryParseJson2(parsed.data.status, {}));

            if (parsedStatus.success) {
              const parsedItemStatuses = ItemStatuses.safeParse(tryParseJson2(parsed.data.itemStatuses, {}));

              if (parsedItemStatuses.success) {
                setJob({ id: parsed.data.id, status: parsedStatus.data, itemStatuses: parsedItemStatuses.data });
              } else {
                toaster.error(`Unable to prepare forms for printing.`);
              }
            } else {
              toaster.error(`Unable to prepare forms for printing.`);
            }
          } else {
            toaster.error(`Unable to prepare forms for printing.`);
          }
        } else {
          toaster.error(`Unable to prepare forms for printing.`);
        }
      } catch (e) {
        console.error(e);
        toaster.error(`Unable to prepare forms for printing.`);
      } finally {
        setBusy(false);
      }
    }
  };

  const handleCheckboxChange = (id, checked) => {
    setState({ ...state, [id]: checked });
  };

  const selectAll = () => {
    setState(Object.keys(state).reduce((acc, curr) => ({ ...acc, [curr]: true }), {}));
  };

  const deselectAll = () => {
    setState(Object.keys(state).reduce((acc, curr) => ({ ...acc, [curr]: false }), {}));
  };

  const theme = useTheme();

  const renderItem = item => {
    const s: { sentAt: string } | null = null as any;

    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={state[item.id]}
            onChange={e => handleCheckboxChange(item.id, e.target.checked)}
            name={item.name}
          />
        }
        label={
          <>
            {item.name}
            {s ? (
              <div style={{ fontSize: '0.6em ', color: theme.palette.success.dark }}>
                Last printed {(formatRelative as any)(new Date(s.sentAt), new Date())}{' '}
              </div>
            ) : (
              <div style={{ fontSize: '0.6em ', opacity: 0.6 }}>Never printed</div>
            )}
          </>
        }
      />
    );
  };

  const [busy, setBusy] = useState(false);

  const handleDone = () => {
    onClose();
    setJob(null);
  };

  return (
    <Dialog maxWidth="lg" open={open} onClose={onClose}>
      <DialogTitle>Bulk Print Documents</DialogTitle>
      {job ? (
        <JobProgress job={job} onDone={handleDone} />
      ) : (
        <>
          <DialogContent>
            <DialogContentText>
              {items.length} procedure{items.length === 1 ? '' : 's'} selected. Please select sections to print.
            </DialogContentText>
            <Box mt={2} display="flex" flexDirection="row" width="100%">
              <FormGroup style={{ width: '100%' }}>
                <Grid container>
                  <Grid item xs={12}>
                    <Box mb={2}>
                      <Button color="primary" size="small" onClick={selectAll}>
                        Select all
                      </Button>
                      <Button color="primary" size="small" onClick={deselectAll}>
                        Deselect all
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item md={4}>
                    <Typography variant="subtitle1" gutterBottom>
                      {formTypeLabels[FormType.PreOpForm]}
                    </Typography>
                    <Box display="flex" flexDirection="column">
                      {(sections[FormType.PreOpForm] ?? []).map(renderItem)}
                    </Box>
                  </Grid>
                  <Grid item md={4}>
                    <Typography variant="subtitle1" gutterBottom>
                      {formTypeLabels[FormType.InTakeForm]}
                    </Typography>
                    <Box display="flex" flexDirection="column">
                      <Box display="flex" flexDirection="column">
                        {(sections[FormType.InTakeForm] ?? []).map(renderItem)}
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item md={4}>
                    <Typography variant="subtitle1" gutterBottom>
                      {formTypeLabels[FormType.PreOpChartForm]}
                    </Typography>
                    <Box display="flex" flexDirection="column" mb={2}>
                      {(sections[FormType.PreOpChartForm] ?? []).map(renderItem)}
                    </Box>
                    <Typography variant="subtitle1" gutterBottom>
                      {formTypeLabels[FormType.OrChartForm]}
                    </Typography>
                    <Box display="flex" flexDirection="column" mb={2}>
                      {(sections[FormType.OrChartForm] ?? []).map(renderItem)}
                    </Box>
                    <Typography variant="subtitle1" gutterBottom>
                      {formTypeLabels[FormType.PacuChartForm]}
                    </Typography>
                    <Box display="flex" flexDirection="column">
                      {(sections[FormType.PacuChartForm] ?? []).map(renderItem)}
                    </Box>
                  </Grid>
                </Grid>
              </FormGroup>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button disabled={busy || !anyChecked} onClick={handleGenerate} color="primary">
              Generate
            </Button>

            {busy && (
              <Spinner
                style={{
                  verticalAlign: 'middle',
                  marginLeft: '0.5rem',
                }}
              />
            )}
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default BulkPrintDialog;

const JobStatus = z.discriminatedUnion('type', [
  z.object({
    type: z.literal('Pending'),
  }),
  z.object({
    type: z.literal('Running'),
  }),
  z.object({
    type: z.literal('Ready'),
    downloadLink: z.string(),
  }),
  z.object({
    type: z.literal('Failed'),
  }),
]);

const ItemStatuses = z.record(z.enum(['Pending', 'Running', 'Ready', 'Failed']));

const Job = z.object({
  id: z.number(),
  status: JobStatus,
  itemStatuses: ItemStatuses,
});

interface JobProgressProps {
  job: z.infer<typeof Job>;
  onDone: () => void;
}

const JobProgress = ({ job, onDone }: JobProgressProps) => {
  const bulkPrintFormsSubscription = useSubscription(bulkPrintFormsJob, {
    variables: {
      bulkPrintJobId: job.id,
    },
  });

  const [status, setStatus] = useState<z.infer<typeof JobStatus>>(job.status);
  const [itemStatuses, setItemStatuses] = useState<z.infer<typeof ItemStatuses>>(job.itemStatuses);

  useEffect(() => {
    const rawStatus = bulkPrintFormsSubscription.data?.bulkPrintFormsJob?.status;
    const parsedStatus = JobStatus.safeParse(tryParseJson2(rawStatus, {}));

    if (parsedStatus.success) {
      setStatus(parsedStatus.data);
    }
  }, [bulkPrintFormsSubscription.data?.bulkPrintFormsJob?.status]);

  useEffect(() => {
    const rawItemStatuses = bulkPrintFormsSubscription.data?.bulkPrintFormsJob?.itemStatuses;
    const parsedItemStatuses = ItemStatuses.safeParse(tryParseJson2(rawItemStatuses, {}));

    if (parsedItemStatuses.success) {
      setItemStatuses(parsedItemStatuses.data);
    }
  }, [bulkPrintFormsSubscription.data?.bulkPrintFormsJob?.itemStatuses]);

  const ready = status.type === 'Ready';

  const [busy, setBusy] = useState(false);

  const handleCancel = () => {
    onDone();
  };

  const handleDownload = () => {
    if (status.type === 'Ready') {
      window.open(status.downloadLink, '_blank');
      onDone();
    }
  };

  return (
    <>
      <DialogContent>
        {status.type === 'Pending' ? (
          <Typography gutterBottom>
            Preparing documents…{' '}
            <Spinner
              style={{
                verticalAlign: 'middle',
                marginLeft: '0.5rem',
              }}
            />
          </Typography>
        ) : status.type === 'Running' ? (
          <Typography gutterBottom>
            Preparing documents…{' '}
            <Spinner
              style={{
                verticalAlign: 'middle',
                marginLeft: '0.5rem',
              }}
            />
          </Typography>
        ) : status.type === 'Ready' ? (
          <Typography gutterBottom>Documents are ready for download.</Typography>
        ) : status.type === 'Failed' ? (
          <Typography gutterBottom>Failed to prepare documents.</Typography>
        ) : null}
        <TableContainer>
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  Pending
                </TableCell>
                <TableCell align="right">
                  <pre>{Object.values(itemStatuses).filter(s => s === 'Pending').length}</pre>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  In progress
                </TableCell>
                <TableCell align="right">
                  <pre>{Object.values(itemStatuses).filter(s => s === 'Running').length}</pre>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Ready
                </TableCell>
                <TableCell align="right">
                  <pre>{Object.values(itemStatuses).filter(s => s === 'Ready').length}</pre>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Failed
                </TableCell>
                <TableCell align="right">
                  <pre>{Object.values(itemStatuses).filter(s => s === 'Failed').length}</pre>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button disabled={!ready || busy} onClick={handleDownload} color="primary">
          Download
        </Button>

        {busy && (
          <Spinner
            style={{
              verticalAlign: 'middle',
              marginLeft: '0.5rem',
            }}
          />
        )}
      </DialogActions>
    </>
  );
};
