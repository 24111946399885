import ThreadInfo from './ThreadInfo';
import { Room } from './Room';
import { Procedure, ProcedureType } from './Procedure';
import Physician from './Physician';
import Anesthesiologist from './Anesthesiologist';

export enum PatientStatus {
  Admitted = 'Admitted',
  InOr = 'InOr',
  AnestheticStart = 'AnestheticStart',
  ReadyForSurgery = 'ReadyForSurgery',
  Ready = 'Ready',
  TimeOut = 'TimeOut',
  TimeOut2 = 'TimeOut2',
  Ongoing = 'Ongoing',
  CallNextPatient = 'CallNextPatient',
  Closing = 'Closing',
  SurgeonLeftOR = 'SurgeonLeftOR',
  DressingOn = 'DressingOn',
  ProcedureEnd = 'ProcedureEnd',
  AnestheticEnd = 'AnestheticEnd',
  Done = 'Done',
  Discharged = 'Discharged',
  Canceled = 'Canceled',
}

export interface PatientEventTimes {
  admittedAt: any;
  preOpAt: any;
  orAt: any;
  orOutAt: any;
  recoveryAt: any;
  postOpAt: any;
  dpuAt: any;
  ableToVisitPACUAt: any;
  ableToVisitPOSTOPAt: any;
  dischargedAt: any;
  readyForSurgeonAt: any;
  readyForOrAt: any;
  holdProcedureAt: any;
  blockNerveAt: any;
  helpAt: any;
}

export type Bed = string | number | undefined | null;
export type Note = string | undefined | null;

export interface Patient {
  id: number;
  name?: string;
  nameUnformatted?: string;
  type: PatientType;
  initials?: string;
  notificationNumbers?: string;
  phoneNumber?: string;
  procedureId?: number;
  caretakerThread?: ThreadInfo;
  patientThread?: ThreadInfo;
  status: string;
  room?: Room;
  bed?: Bed;
  priority?: string;
  events?: PatientEventTimes;
  note?: string;
  preOpNote?: string;
  pacuNote?: string;
  procedureType?: ProcedureType;
  physician?: Physician;
  anesthesiologist?: Anesthesiologist;
  procedure?: Procedure;
  changedRoomAt?: string;
  log?: any[];
  caretakerConsent?: boolean;
  caretakerName?: string;
  caretakerNote?: string;
  blockNerveFollow?: boolean;
}

export enum PatientType {
  In = 'In',
  Out = 'Out',
  Emergency = 'Emergency',
  ICU = 'ICU',
}
