import React, { FC, useCallback, useEffect, useState } from 'react';
import { Destination } from '../../../../../types/NotificationTemplate';
import { useQuery } from '@apollo/client';
import { getNotificationTemplate } from '../../../../../graph/notificationTemplates';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ScheduleAccess from '../ScheduleAccess';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import { makeStyles } from '@material-ui/core/styles';
import Vendor from '../../../../../types/Vendor';

export type Contact = {
  id: number;
  phoneNumbers: string[];
};

export type Recipient = Vendor;

type State = {
  [recipientId: string]: {
    value?: boolean;
    representatives?: boolean[];
  };
};

const SendNotificationDialog: FC<{
  date: Date;
  open: boolean;
  handleClose: () => void;
  recipients: Recipient[];
  sendNotifications: (recipients: Contact[], message: String) => Promise<void>;
  destination: Destination;
}> = ({ date, open, handleClose, recipients, sendNotifications, destination }) => {
  const { data: notification } = useQuery(getNotificationTemplate, {
    variables: { trigger: 'ScheduleCreated', destination: destination },
  });

  const notificationText = notification?.getNotificationTemplate[0]?.content || '';
  const [message, setMessage] = useState<string>('');

  const [sending, setSending] = useState<boolean>(false);
  const [sent, setSent] = useState<boolean>(false);

  useEffect(() => {
    setMessage(notificationText);
  }, [notificationText]);

  const handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setMessage(e.target.value);
  };
  const classes = useStyles();

  useEffect(() => {
    setSent(false);
  }, [open]);

  const [checkboxes, setCheckboxes] = useState<State>({});

  const changeAllCheckboxes = useCallback(
    turnOn => {
      recipients?.length &&
        setCheckboxes(
          recipients.reduce(
            (acc: any, v: Recipient) => ({
              ...acc,
              [v.id]: {
                value: turnOn ? v.hasScheduleAccess : false,
                representatives: v?.representatives?.map(e =>
                  turnOn ? v.hasScheduleAccess && !!e.phoneNumber : false
                ),
              },
            }),
            {}
          )
        );
    },
    [setCheckboxes, recipients]
  );

  useEffect(() => {
    changeAllCheckboxes(true);
  }, [recipients]);

  const handleChangeParent = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckboxes({
      ...checkboxes,
      [event.target.name]: {
        value: event.target.checked,
        representatives: checkboxes?.[event.target.name]?.representatives?.map(_ => event.target.checked),
      },
    });
  };

  const handleChangeChild = (event: React.ChangeEvent<HTMLInputElement>) => {
    const [parent, child] = event.target.name?.split('-');
    const representatives = checkboxes?.[parent]?.representatives?.map((e, i) =>
      i === Number(child) ? event.target.checked : e
    );
    setCheckboxes({
      ...checkboxes,
      [parent]: {
        value: representatives?.some(e => e),
        representatives,
      },
    });
  };

  const doSend = async () => {
    setSending(true);
    setSent(false);

    await sendNotifications(
      recipients
        ?.filter(e => checkboxes?.[e.id]?.value)
        ?.map(e => {
          const reps: string[] =
            e?.representatives
              ?.filter((r, i) => r?.phoneNumber && checkboxes?.[e.id]?.representatives?.[i])
              ?.map(r => r.phoneNumber as string) || [];
          return {
            id: e.id,
            phoneNumbers: reps,
          };
        }),
      message
    );

    setSending(false);
    setSent(true);
  };

  const selectAll = () => changeAllCheckboxes(true);

  const deselectAll = () => changeAllCheckboxes(false);

  return (
    <Dialog onClose={handleClose} open={open} classes={{ paper: classes.paper }} maxWidth="lg">
      <DialogTitle>
        <Typography variant="h6">
          Send schedule notifications to &nbsp;
          {`${destination.split(/(?=[A-Z])/).map(word => word.toLowerCase())}`}
          &nbsp;representatives
        </Typography>
        <div style={{ fontSize: '.8rem', color: 'silver' }}>
          Make sure that the phone numbers of all vendor representatives are added to the vendor profile
        </div>
        {handleClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent>
        <div style={{ display: 'grid', gridTemplateColumns: '1.05fr .95fr', color: 'silver' }}>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr 1fr',
              textAlign: 'center',
              borderBottom: '1px solid silver',
            }}
          >
            <div>Select Vendor</div>
            <div>Current Access</div>
            <div>Change Access</div>
          </div>
          <div></div>
        </div>
        <Box className={classes.dialog}>
          <Box className={classes.content}>
            <FormControl component="fieldset">
              <FormLabel
                component="legend"
                style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '1rem' }}
              >
                <Button color="primary" variant="text" onClick={selectAll} style={{ width: 'fit-content' }}>
                  Select all
                </Button>
                <Button color="primary" variant="text" onClick={deselectAll} style={{ width: 'fit-content' }}>
                  Deselect all
                </Button>
              </FormLabel>
              <div style={{ height: '600px', overflowY: 'auto' }}>
                <FormGroup>
                  {recipients.map((e: Recipient) => (
                    <div key={e.id}>
                      <div style={{ display: 'grid', gridTemplateColumns: '.33fr .67fr .33fr', alignItems: 'stretch' }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={(checkboxes as any)[e.id]?.value || false}
                              onChange={handleChangeParent}
                              name={`${e.id}`}
                            />
                          }
                          label={<strong>{e.name}</strong>}
                          disabled={!e.hasScheduleAccess}
                        />
                        <ScheduleAccess date={date} scheduleUser={e} destination={destination} />
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '1em' }}>
                        {(checkboxes as any)[e.id]?.representatives?.map((r: boolean, i: number) => (
                          <FormControlLabel
                            control={
                              <Checkbox checked={r || false} onChange={handleChangeChild} name={`${e.id}-${i}`} />
                            }
                            label={
                              <span>
                                {e?.representatives?.[i]?.phoneNumber !== null &&
                                e?.representatives?.[i]?.phoneNumber !== undefined ? (
                                  `${e?.representatives?.[i]?.name} (${e?.representatives?.[i]?.phoneNumber})`
                                ) : (
                                  <>
                                    <span>{`${e?.representatives?.[i]?.name} `}</span>
                                    <span style={{ color: 'red' }}>{`(x)`}</span>
                                  </>
                                )}
                              </span>
                            }
                            disabled={!(e.hasScheduleAccess && e?.representatives?.[i]?.phoneNumber)}
                          />
                        ))}
                      </div>
                      <div style={{ borderTop: '1px solid gray', marginRight: '20px' }}></div>
                    </div>
                  ))}
                </FormGroup>
              </div>
            </FormControl>
            <div></div>
            <TextField
              label="This is the message that will be sent to the vendor representative"
              multiline
              minRows={10}
              variant="filled"
              fullWidth={true}
              value={message}
              onChange={handleMessageChange}
              InputProps={{
                style: {
                  paddingTop: '45px',
                },
              }}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions className={classes.action}>
        {sent && (
          <Box className={classes.success}>
            <DoneAllIcon style={{ color: 'green' }} />
            <Typography style={{ color: 'green' }}>Notifications Sent</Typography>
          </Box>
        )}
        <Button variant="contained" color="primary" onClick={doSend} fullWidth={false} disabled={sending}>
          Send Notifications
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  paper: {
    minWidth: '75rem',
    minHeight: '40rem',
  },
  dialog: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1),
  },
  content: {
    display: 'grid',
    gridTemplateColumns: '8fr .2fr 6fr',
    marginBottom: theme.spacing(1),
  },
  action: {
    margin: theme.spacing(1),
  },
  success: {
    '& > *': {
      marginRight: theme.spacing(1),
    },
    display: 'flex',
    flexDirection: 'row',
  },
}));

export default SendNotificationDialog;
