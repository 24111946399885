import React from 'react';
import Header from './components/Header';
import MedicalPassportTextArea from '../components/MedicalPassportTextArea';
import MedicalPassportRow from '../components/MedicalPassportRow';
import MedicalPassportRow3 from '../components/MedicalPassportRow3';
import MedicationTextArea from '../components/MedicationTextArea';
import MedicalPassportField from '../components/MedicalPassportField';
import MedicalPassportCheckbox from '../components/MedicalPassportCheckbox';
import styles from '../styles';
import toHighlightsString from '../../../../../questionnaire/toHighlightsString';
import toHighlightsString2 from '../../../../../questionnaire/toHighlightsString2';
import {
  cardiovascular,
  endocrine,
  gastrointestinal,
  gynaecological,
  hematological,
  hepatic,
  musculoskeletal,
  neurological,
  other,
  psychiatric,
  pulmonary,
  renal,
  derm,
  infectious,
  liver,
  assist,
  substance,
  ob,
} from './paths';
import toAge from '../../../../../questionnaire/toAge';
import toHeight from '../../../../../questionnaire/toHeight';
import toWeight from '../../../../../questionnaire/toWeight';
import toBMI from '../../../../../questionnaire/toBMI';
import toWeightLbs from '../../../../../questionnaire/toWeightLbs';
import get from 'lodash/get';
import { useValue } from '../ValueContext';
import { getcontent } from '../golfCoast/pages/contentlimit'
import toHighlightsString4 from '../../../../../questionnaire/toHighlightsString4';
import MedicalPassportTextArea2 from '../components/MedicalPassportTextArea2';
import toHighlightsString3 from '../../../../../questionnaire/toHighlightsString3';
import toStringWithoutQuestions3 from '../../../../../questionnaire/toStringWithoutQuestions3';
import toStringWithoutQuestions2 from '../../../../../questionnaire/toStringWithoutQuestions2';
import toStringWithoutQuestions from '../../../../../questionnaire/toStringWithoutQuestions';
import toHighlightsString5 from '../../../../../questionnaire/toHighlightsString5';

export const ReviewofSystemWrapper = ({ label, value }) => (
  <div
    style={{
      lineHeight: '12px',
      borderLeft: 0,
      borderRight: 0,
      borderTop: 0,
    }}
  >
    <div style={styles.systemrow2}>
      <div
        style={{
          borderRight: '1px solid black',
          paddingLeft: '5px',
          paddingTop: '3px',
          fontSize: '12px',
          width:'95px'
        }}
      >
        {label}
      </div>
      <div
        style={{
          display: 'flex',
          padding: '1px',
        }}
      >
        <MedicalPassportTextArea2
          value={value}
          style={{ width: '100%', minHeight: '33px' }}
          fontSize={'11px'}
          nobackground
          placeholder={'No'}
        />
      </div>
    </div>
  </div>
);

const Page7 = () => {
  const { questionnaireAnswers } = useValue();

  function yesOrNo(value) {
    if (value === true) {
      return 'yes';
    } else if (value === false) {
      return 'no';
    }
    return '';
  }
  let patientProblem = get(
    questionnaireAnswers,
    'sections.PreProcedureAssessment.schema.associatedProblem.yes[1].schema.associatedProblem_text2.text',
    ''
  );

  let familyProblem = get(
    questionnaireAnswers,
    'sections.PreProcedureAssessment.schema.associatedProblem.yes[1].schema.associatedProblem_text.text',
    ''
  );

  // allergy
  const allergyList = get(questionnaireAnswers, 'sections.allergies.schema.q1.yes[1].schema.allergy_list.list', []);
  const allergyList2 = get(questionnaireAnswers, 'sections.allergies.schema.q2.yes[0]', false);
  const allergyList3 = get(questionnaireAnswers, 'sections.allergies.schema.q3.yes[0]', false);

  const allergyName = [];
  const reaction = [];

  for (let i = 0; i < allergyList.length; i++) {
    allergyName.push(get(allergyList[i], 'schema.q1_name.text', ''));
    reaction.push(get(allergyList[i], 'schema.q1_reaction.text', ''));
  }

  if (allergyList2) {
    allergyName.push('Latex');
    reaction.push(get(questionnaireAnswers, 'sections.allergies.schema.q2.yes[1].schema.q2_reaction.text', ''));
  }

  if (allergyList3) {
    allergyName.push(get(questionnaireAnswers, 'sections.allergies.schema.q3.yes[1].schema.q3_name.text', ''));
    reaction.push(get(questionnaireAnswers, 'sections.allergies.schema.q3.yes[1].schema.q3_reaction.text', ''));
  }

  function combineArrays(arr1, arr2) {
    let result = '';

    for (let i = 0; i < arr1.length && i < arr2.length; i++) {
      result += arr1[i] + ', ' + arr2[i] + '\n';
    }

    return result;
  }

  const surgery = getcontent(toHighlightsString, [['priorSurgeries']], 100);
  const medication = getcontent(toHighlightsString, [['medications']], 600);
  const allergy = getcontent(toHighlightsString4, [['allergies']], 100);

  const cardiovascularList = getcontent(toHighlightsString2, cardiovascular, 100);
  const endocrineList = getcontent(toHighlightsString2, endocrine, 100);
  const GIList = getcontent(toHighlightsString2, gastrointestinal, 100);
  const gynaecologicalList = getcontent(toHighlightsString2, gynaecological, 100);
  const hematologicalList = getcontent(toHighlightsString2, hematological, 100);
  const hepaticList = getcontent(toHighlightsString2, hepatic, 100);
  const muscularSkeletalList = getcontent(toHighlightsString2, musculoskeletal, 100);
  const neurologicalList = getcontent(toHighlightsString2, neurological, 100);
  const otherList = getcontent(toHighlightsString2, other, 100);
  const psychiatricList = getcontent(toHighlightsString2, psychiatric, 100);
  const pulmonaryList = getcontent(toHighlightsString2, pulmonary, 100);
  const renalList = getcontent(toHighlightsString2, renal, 100);
  const dermList = getcontent(toHighlightsString2, derm, 100);
  const infectiousList = getcontent(toHighlightsString2, infectious, 100);
  const liverList = getcontent(toHighlightsString2, liver, 100);
  const assistList = getcontent(toHighlightsString2, assist, 100);
  const substanceUseList = getcontent(toHighlightsString2, substance, 100);
  const obList = getcontent(toHighlightsString2, ob, 100);
  return (
    <div id="page7" className="page" style={{ fontFamily: 'Arial', fontSize: '12px' }}>
      <Header pageNumber={7} pageCount={11} />
      <h2>Pre Operative Anesthesia Assessment</h2>
      <div style={styles.twoNotEqualColumns}>
        <div>
          <div style={{ ...styles.twoNotEqualColumns1, border: '1px solid black' }}>
            <div>Planned Procedure</div>
            <MedicalPassportTextArea name="procedure.name" fontSize={12} />
          </div>
          <div style={{ ...styles.sixEqualColumns, borderTop: '0' }}>
            <MedicalPassportField
              label="Age"
              name="page5.age"
              path={['demographicInformation', 'dob']}
              reducer={toAge}
              fontSize={12} />
            <MedicalPassportField
              label="Sex"
              name="page5.sex"
              path={['demographicInformation', 'sex']}
              reducer={toHighlightsString}
              fontSize={12} />
            <MedicalPassportField
              label="Ht"
              name="page5.Ht"
              path={['demographicInformation', 'bmi']}
              reducer={toHeight}
              fontSize={12} />
            <MedicalPassportField
              label="Wt"
              name="page5.Wt"
              path={['demographicInformation', 'bmi']}
              reducer={toWeightLbs}
              fontSize={12} />
            <MedicalPassportField
              label="KG"
              name="page5.KG"
              path={['demographicInformation', 'bmi']}
              reducer={toWeight}
              fontSize={12} />
            <MedicalPassportField
              label="BMI"
              name="page5.BMI"
              path={['demographicInformation', 'bmi']}
              reducer={toBMI}
              fontSize={12} />
          </div>
          <div style={{ fontSize: '.75rem' }}>Printed data provided through online survey:</div>
        </div>
        <div>
          <div
            style={{
              borderTop: '1px solid black',
              borderLeft: '1px solid black',
              borderRight: '1px solid black',
              fontSize: '.8rem',
            }}
          >
            <MedicalPassportField label="Patient Name" name="procedure.patient.name" fontSize={12} />
            <MedicalPassportField label="DOB" name="page5.DOB" path={['demographicInformation', 'dob']} fontSize={12} />
            <MedicalPassportField label="Physician" name="procedure.physician.name" fontSize={12} />
            <MedicalPassportField label="DOS" name="procedure.serviceTime" fontSize={12} />
          </div>
        </div>
      </div>

      <div style={styles.twoNotEqualColumns}>
        <div>
          <div style={styles.greyBackground}>History of Anesthesia Complications</div>
          <div style={styles.oneColumnLeftAlign}>
            <div style={{ display: 'grid', gridTemplateColumns: '13% 87%' }}>
              Patient:
              <MedicationTextArea name="page7.anesthesiaPatientName" value={patientProblem} />
            </div>
            <div style={{ display: 'grid', gridTemplateColumns: '24% 76%' }}>
              Family Member:
              <MedicationTextArea name="page7.anesthesiaFamilyMember" value={familyProblem} />
            </div>
          </div>
          <div style={styles.greyBackground}>Review of Systems</div>
          <section style={{}}>
            <div
              style={{
                border: '1px solid black',
                lineHeight: '1.8em',
                borderLeft: 0,
                borderRight: 0,
              }}
            >
              <ReviewofSystemWrapper label='Cardiovascular' value={cardiovascularList} />
              <ReviewofSystemWrapper label='Pulmonary' value={pulmonaryList} />
              <ReviewofSystemWrapper label='Renal' value={renalList} />
              <ReviewofSystemWrapper label='Hepatic' value={liverList} />
              <ReviewofSystemWrapper label='Neurological' value={neurologicalList} />
              <ReviewofSystemWrapper label='Gastrointestinal' value={GIList} />
              <ReviewofSystemWrapper label='Hematological' value={hematologicalList} />
              <ReviewofSystemWrapper label='Endocrine / Metabolic' value={endocrineList} />
              <ReviewofSystemWrapper label='Musculo-skeletal' value={muscularSkeletalList} />
              <ReviewofSystemWrapper label='Psychiatric' value={psychiatricList} />
              <ReviewofSystemWrapper label='OB/Gyn' value={obList} />
              <ReviewofSystemWrapper label='Coronavirus' value={infectiousList} />
              <ReviewofSystemWrapper label='ENT / Dermatological' value={dermList} />
              <ReviewofSystemWrapper label='Substance Use' value={substanceUseList} />
              <ReviewofSystemWrapper label='Other MRSA, glasses, contacts, assistive devices' value={assistList} />
            </div>
          </section>
        </div>
        <div style={{ paddingLeft: '5px' }}>
          <div style={styles.greyBackground}>Allergies</div>
          <div>
            <MedicalPassportTextArea2 label="" name="allergies1" value={allergy} fontSize={12} />
          </div>
          <div style={styles.greyBackground}>Current Medications</div>
          <div>
            <MedicalPassportTextArea2
              label={''}
              name="medications1"
              value={medication}
              fontSize={12}
            />
          </div>
          <div style={styles.greyBackground}>Previous Surgical History</div>
          <div>
            <MedicalPassportTextArea2
              label=""
              name="anesthesia1"
              value={surgery}
              fontSize={12}
            />
          </div>
        </div>
      </div>
      <div style={styles.greyBackground}>Physical Exam / Labs / Anesthesia Plan</div>
      <div style={styles.twoNotEqualColumns4}>
        <div>
          <div style={styles.threeEqualColumns3}>
            <div style={{ borderRight: '1px solid black' }}>
              <strong>
                <u>Neuro</u>
              </strong>
              <MedicalPassportCheckbox name="page6.oriented" label="Oriented" />
            </div>
            <div style={{ borderRight: '1px solid black' }}>
              <strong>
                <u>Cardiac</u>
              </strong>
              <MedicalPassportCheckbox name="page6.normalRhythm" label="Normal Rhythm" />
              <MedicalPassportCheckbox name="page6.noMurmurs" label="No Murmurs" />
            </div>
            <div style={{ borderRight: '1px solid black' }}>
              <strong>
                <u>Pulmonary</u>
              </strong>
              <MedicalPassportCheckbox name="page6.clear" label="Clear to Auscultation Wheezes/Rhonchi/Rales" />
            </div>
          </div>
          <div>
            <div style={styles.twoNotEqualColumnsNoBorder}>
              <div style={{ borderTop: '1px solid black', borderRight: '1px solid black', paddingRight: '10px' }}>
                <strong>
                  <u>EKG</u>
                </strong>
              </div>
              <div style={{ borderTop: '1px solid black', borderRight: '1px solid black', paddingRight: '10px' }}>
                <strong>
                  <u>Labs</u>
                </strong>
                <div style={styles.threeNotEqualColumns}>
                  <MedicalPassportField label="Na" name="page7.Na" fontSize={10} />
                  <MedicalPassportField label="Hgb" name="page7.Hgb" fontSize={10} />
                  <MedicalPassportField label="Accucheck" name="page7.Accucheck" fontSize={10} />
                </div>

                <div style={styles.threeEqualColumns3}>
                  <MedicalPassportField label="K" name="page7.k" fontSize={10} />
                  <MedicalPassportField label="Hct" name="page7.Hct" fontSize={10} />
                </div>
                <div style={styles.threeEqualColumns3}>
                  <MedicalPassportField label="Cr" name="page7.Cr" fontSize={10} />
                  <MedicalPassportField label="PLT" name="page7.PLT" fontSize={10} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <strong>
            <u>Airway</u>
          </strong>
          <MedicalPassportField label="Class" name="page7.Class" fontSize={12}/>

          <div style={{ display: 'flex' }}>
            OralOpening
            <MedicalPassportCheckbox name="page7.NormalAir" label="Normal" />
          </div>
          Dentition
          <div style={{ display: 'flex' }}>
            <MedicalPassportCheckbox name="page7.normalDentition" label="Normal" />
            <MedicalPassportCheckbox name="page7.dentures" label="Dentures" />
          </div>
          <MedicalPassportCheckbox name="page7.chipped" label="Chipped / Broken" />
        </div>
      </div>
    </div>
  );
};

export default Page7;
