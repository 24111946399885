import React from 'react';
import styles from '../../styles';
import MedicalPassportField from '../../components/MedicalPassportField';
import MedicalPassportTextArea from '../../components/MedicalPassportTextArea';
import toAge from '../../../../../../questionnaire/toAge';
import toSex from '../../../../../../questionnaire/toSex';
import getAgeAndBmi from './pageUtils';
import Barcode from '../../../Barcode';
import { additionalcontent } from '../../golfCoast/pages/contentlimit';
import toHighlightsString5 from '../../../../../../questionnaire/toHighlightsString5';
import MedicalPassportTextArea2 from '../../components/MedicalPassportTextArea2';


export const CommonPartP5P6 = ({ providerId, showQRCode, age }) => (
    <div style={{ ...styles.border, position: 'relative' }}>
        <div style={{ bottom: '5px', paddingLeft: '3px' }}>
            <div style={{ marginBottom: '1px' }}>
                <MedicalPassportField name="procedure.patient.name" label="Patient" fontWeight={'bold'} fontSize={14} />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '1px' }}>
                <MedicalPassportField name="dob" label="DOB" path={['demographicInformation', 'dob']} fontSize={12}/>
                <MedicalPassportField name="procedure.serviceTime" label="DOS" fontWeight={700} fontSize={12}/>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '1px' }}>
                <MedicalPassportField name="procedure.patient.providerId" label="MRN#" fontSize={12}/>
                <MedicalPassportField name="procedure.physician.name" label="Surgeon" fontSize={12}/>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '1px', paddingBottom: '1px' }}>
                <MedicalPassportField
                    name="age"
                    label="Age"
                    path={['demographicInformation', 'dob']}
                    reducer={toAge}
                    warning={age > 70}
                    fontSize={12}
                />
                <MedicalPassportField name="sex" label="Sex" path={['demographicInformation', 'sex']} reducer={toSex} fontSize={12}/>
            </div>
        </div>
    </div>
);

const Page5Extra = ({ formName, pageNumber, showQRCode, hospitalName, value, defaultValue }) => {
    const providerId = defaultValue?.procedure?.patient?.providerId;
    const { age, bmi } = getAgeAndBmi(
        defaultValue?.demographicInformation?.dob,
        defaultValue?.demographicInformation?.bmi
    );

    const allergy = additionalcontent(toHighlightsString5, [['allergies']], 140);

    if (allergy == 'No additional info') {
        return ''
    } else {
        return (
            <div id="page5Extra" className="page" style={{ fontSize: '0.8em', paddingTop: '1rem' }}>
                <div style={styles.twoColumns}>
                    <div>
                        <div>{hospitalName || ''}</div>
                        <MedicalPassportTextArea name="procedure.name" label="Procedure" />
                        <h3 style={{ margin: '2px 0' }}>Pre-Procedure Nursing Assessment</h3>
                    </div>
                    <div>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '2px' }}>
                            {showQRCode && (
                                <Barcode
                                    formName={formName}
                                    pageNumber={pageNumber}
                                    providerId={defaultValue?.procedure?.patient?.providerId}
                                    serviceTime={defaultValue?.procedure?.serviceTime}
                                />
                            )}
                        </div>
                        <CommonPartP5P6 age={age} showQRCode={showQRCode} providerId={providerId} />
                    </div>
                </div>
                <div style={styles.allergiesSection}>
                    <div style={styles.activeTab}>ALLERGIES</div>
                    <MedicalPassportTextArea2 name="allergies2" value={allergy} warning={true} fontSize={11} />
                </div>
            </div>
        );
    };
}

export default Page5Extra;
