import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import MedicalPassportField from '../components/MedicalPassportField';
import MedicalPassportCheckbox from '../components/MedicalPassportCheckbox';
import styles from '../styles';
import toHeight from '../../../../../questionnaire/toHeight';
import toWeight from '../../../../../questionnaire/toWeight';
import toBMI from '../../../../../questionnaire/toBMI';
import toWeightLbs from '../../../../../questionnaire/toWeightLbs';
import toAge from '../../../../../questionnaire/toAge';
import toHighlightsString from '../../../../../questionnaire/toHighlightsString';
import MedicalPassportTextArea from '../components/MedicalPassportTextArea';

const Page6 = () => {
  return (
    <div id="page6" className="page" style={{fontFamily:'Arial', fontSize:'14px'}}>
      <Header pageNumber={6} pageCount={11} />
      <div style={styles.twoNotEqualColumns}>
        <div>
          <div style={styles.oneColumn}>Surgical History and Physical/Medication Reconciliation</div>
          <div style={styles.oneColumnLeftAlign}>
            <MedicalPassportField label="PreOp Diagnosis" name="page5.preOpDiag" />
          </div>
          <div style={{ ...styles.twoNotEqualColumns1, border: '1px solid black' }}>
            <div>Planned Procedure</div>
            <MedicalPassportTextArea name="procedure.name" />
          </div>
          <div style={{ ...styles.sixEqualColumns, borderTop: '0' }}>
            <MedicalPassportField
              label="Age"
              name="page5.age"
              path={['demographicInformation', 'dob']}
              reducer={toAge}
            />
            <MedicalPassportField
              label="Sex"
              name="page5.sex"
              fontSize=".6rem"
              path={['demographicInformation', 'sex']}
              reducer={toHighlightsString}
            />
            <MedicalPassportField
              label="Ht"
              name="page5.Ht"
              path={['demographicInformation', 'bmi']}
              reducer={toHeight}
            />
            <MedicalPassportField
              label="Wt"
              name="page5.Wt"
              path={['demographicInformation', 'bmi']}
              reducer={toWeightLbs}
              fontSize={11}
            />
            <MedicalPassportField
              label="KG"
              name="page5.KG"
              path={['demographicInformation', 'bmi']}
              reducer={toWeight}
            />
            <MedicalPassportField
              label="BMI"
              name="page5.BMI"
              fontSize={9.5}
              path={['demographicInformation', 'bmi']}
              reducer={toBMI}
            />
          </div>
        </div>
        <div>
          <div style={styles.oneColumnNoLeftYesBottom}>Polaris Surgery Center</div>
          <div style={{ borderRight: '1px solid black', fontSize: '.8rem' }}>
            <MedicalPassportField label="Patient Name" name="procedure.patient.name" />
          </div>
          <div style={{ borderRight: '1px solid black', fontSize: '.75rem' }}>
            <div style={styles.twoNotEqualColumnsNoBorder}>
              <MedicalPassportField label="DOB" name="page5.DOB" path={['demographicInformation', 'dob']} />
              <MedicalPassportField label="Physician" name="procedure.physician.name" />
            </div>
          </div>
          <div style={{ borderRight: '1px solid black', borderBottom: '1px solid black', fontSize: '.75rem' }}>
            <div style={styles.twoNotEqualColumnsNoBorder}>
              <MedicalPassportField label="DOS" name="procedure.serviceTime" />
              <MedicalPassportField label="Account #" name="page5.AccountNum" />
            </div>
          </div>
        </div>
        <br></br>
      </div>
      <div style={styles.greyBackground}>
        <div style={{ textAlign: 'left' }}>H&P</div>
      </div>
      <div style={styles.threeEqualColumnsBorder}>
        <div style={{ borderRight: '1px solid black', borderBottom: '1px solid black' }}>
          <strong>
            <u>Neuro</u>
          </strong>
          <MedicalPassportCheckbox name="page6.oriented" label="Oriented" />
        </div>
        <div style={{ borderRight: '1px solid black', paddingLeft: '5px', borderBottom: '1px solid black' }}>
          <strong>
            <u>Cardiac</u>
          </strong>
          <MedicalPassportCheckbox name="page6.normalRhythm" label="Normal Rhythm" />
          <MedicalPassportCheckbox name="page6.noMurmurs" label="No Murmurs" />
        </div>
        <div style={{ paddingLeft: '5px', borderBottom: '1px solid black' }}>
          <strong>
            <u>Pulmonary</u>
          </strong>
          <MedicalPassportCheckbox name="page6.clear" label="Clear to Auscultation Wheezes/Rhonchi/Rales" />
        </div>
      </div>
      <div style={styles.threeEqualColumns3}>
        <div style={styles.signatureBox1}>
          <div>
            <div style={{ paddingTop: '1.5rem' }}>___________________________</div>
            <div style={{ textAlign: 'left', fontSize: '.8rem' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Physician
              Signature
            </div>
          </div>
        </div>
        <div style={styles.signatureBox1}>
          <div>
            <div style={{ paddingTop: '1.5rem' }}>___________/______/__________</div>
            <div style={{ textAlign: 'left', fontSize: '.8rem' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
              Date
            </div>
          </div>
        </div>
        <div style={styles.signatureBox1}>
          <div>
            <div style={{ paddingTop: '1.5rem' }}>___________________________</div>
            <div style={{ textAlign: 'left', fontSize: '.8rem' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Time
            </div>
          </div>
        </div>
      </div>
      <br></br>
      <div style={{ borderBottom: '1px solid black' }}>
        <div style={styles.oneColumn}>
          <b>PRE-SURGICAL ASSESSMENT/HISTORY AND PHYSICAL UPDATE</b>
          <MedicalPassportCheckbox
            name="page6.preSurgicalNoChanges"
            label="Pre-Surgical Assessment Completed upon admission. No changes noted. Patient is cleared for surgery in an ambulatory setting."
          />
          <MedicalPassportCheckbox
            name="page6.preSuricalChanges"
            label="Pre-Surgical Assessment Completed upon admission. Changes are noted above."
          />
          <br></br>
          Provider Signature _________________________________ Date ________________ Time_______________
        </div>
      </div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>      
      <br></br>
      <br></br>
      <br></br>      
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <Footer pageNumber={6} pageCount={10} />
    </div>
  );
};

export default Page6;
