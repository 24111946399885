import React, { useEffect, useRef } from 'react';
import { fieldStyle } from './MedicalPassportField';
import { useValue } from '../ValueContext';
import { useQuestionnaireValue } from '../hooks';
import { set } from 'lodash/fp';
const styles = {
  row: {
    display: 'grid',
    gridTemplateColumns: '1fr 4fr',
    border: '1px solid black',
    borderBottom: 0,
    resize: 'none',
    width: '100%',
  },
};

const MedicalPassportRow = ({ index, label, name, reducer, path, paths = [], style, background = undefined, fontSize=undefined }) => {
  const { defaultValue, value, setValue } = useValue(name);

  const questionnaireValue = useQuestionnaireValue(reducer || toString, ...[path, ...paths].filter(path => path));

  const textareaRef = useRef(null);

  useEffect(() => {
    textareaRef.current.style.height = '0px';
    const scrollHeight = textareaRef.current.scrollHeight;
    textareaRef.current.style.height = scrollHeight + 'px';
  }, [value]);

  const inputFieldStyle = !!background ? set('background', background)(fieldStyle.inputField) : fieldStyle.inputField;

  return (
    <div style={{ ...styles.row, ...style }}>
      <div style={{ borderRight: '1px solid black', paddingLeft: '.5em' }}>{label}</div>
      <div style={{ display: 'flex' }}>
        <textarea
          ref={textareaRef}
          name={name}
          value={value || questionnaireValue || defaultValue}
          reducer={reducer}
          onChange={e => setValue(e.target.value)}
          style={{
            ...inputFieldStyle,
            ...(isFinite(index) && index % 2 === 0 ? fieldStyle.inputFieldOdd : {}),
            margin: 0,
            width: '100%',
            resize: 'none',
            padding: '.25em .5em',
            boxSizing: 'border-box',
            fontSize:fontSize
          }}
        />
      </div>
    </div>
  );
};

export default MedicalPassportRow;
