import { Container } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Alert } from '@material-ui/lab';
import get from 'lodash/get';
import identity from 'lodash/identity';
import queryString from 'query-string';
import React, { Fragment, useState } from 'react';
import { Redirect } from 'react-router';
import { compose, withState } from 'recompose';
import loginIllustration from '../../../../assets/images/surgery_center.svg';

import Form from '../../../../se/components/Form';
import Link from '../../../../se/components/html/Link';
import CredentialsInput from '../../../../se/components/inputs/CredentialsInput';
import ForgotPasswordInput from '../../../../se/components/inputs/ForgotPasswordInput';
import PasswordChangeInput from '../../../../se/components/inputs/PasswordChangeInput';
import PasswordInput from '../../../../se/components/inputs/PasswordInput';
import PWAInstallBanner from '../../../../se/components/PWAInstallBanner';
import { withSession } from '../../../../state/Session';

import Copyright from '../../../Copyright';
import Logo from '../../../Logo';
import ReactivationBanner from '../ReactivationBanner';
import QuestionAction from './QuestionAction';

const Login = ({
  session,
  createUsingEmailPassword,
  changePasswordWithCredentials,
  resendConfirmationLinkEmail,
  sendPasswordResetLinkEmail,
  destroy,
  location,
  history,
  setBusy,
}) => {
  const classes = useStyles();

  const [forceChangePassword, setForceChangePassword] = useState(null);
  const [isFirstLogin, setIsFirstLogin] = useState(null);
  const [forceConfirmation, setForceConfirmation] = useState(null);
  const [forgotPassword, setForgotPassword] = useState(null);
  const [resetPasswordLinkSent, setResetPasswordLinkSent] = useState(null);

  const isSessionUnknown = !session || !session.account || !session.account.id || !session.account.email;

  const isSessionValid = session && session.valid;

  const nextUrl = () => queryString.parse(location.search).next || '/';

  const reactivationCode = queryString.parse(location.search).reactivation;

  if (isSessionValid) {
    return <Redirect to={nextUrl()} />;
  }

  const reactToError = (e, payload) => {
    if (get(e, 'message') === 'password.change') {
      setForceChangePassword(payload);
    } else if (get(e, 'message') === 'password.initialize') {
      setForceChangePassword(payload);
      setIsFirstLogin(true);
    } else if (get(e, 'message') === 'password.attempts') {
      setForceConfirmation(payload);
    } else {
      throw e;
    }
  };

  const logInWithCredentials = async ({ email, password } = {}) => {
    setBusy(true);
    try {
      await createUsingEmailPassword(email, password);
    } catch (e) {
      reactToError(e, { email });
    }
    setBusy(false);
  };

  const logInWithPassword = async password => {
    setBusy(true);
    const email = get(session, 'account.email.Email.address');
    try {
      await createUsingEmailPassword(email, password);
    } catch (e) {
      reactToError(e, { email });
    }
    setBusy(false);
  };

  const changePassword = async ({ oldPassword, newPassword }) => {
    setBusy(true);
    const email = get(forceChangePassword, 'email');
    await changePasswordWithCredentials(email, oldPassword, newPassword);
    setBusy(false);
    setForceChangePassword(null);
    setIsFirstLogin(null);
  };

  const resendConfirmation = async () => {
    setBusy(true);
    const email = get(forceConfirmation, 'email');
    await resendConfirmationLinkEmail(email);
    setBusy(false);
  };

  const sendResetPasswordLink = async ({ email }) => {
    setBusy(true);
    await sendPasswordResetLinkEmail(email);
    setBusy(false);
    setResetPasswordLinkSent(true);
  };

  const goToApp = () => history.replace(nextUrl());

  const clickForgotPassword = () => {
    setForgotPassword(true);
  };

  return (
    <Box className={classes.page}>
      <Container maxWidth="lg" className={classes.container}>
        <Box className={classes.wrapper}>
          <Box className={classes.heading}>
            <Logo />
          </Box>
          <Box className={classes.form}>
            <PWAInstallBanner />
            {reactivationCode && <ReactivationBanner code={reactivationCode} />}
            {forceConfirmation ? (
              <Fragment>
                <Typography variant="h2" gutterBottom>
                  Too Many Attempts
                </Typography>
                <Typography
                  style={{ marginBottom: '2em' }}
                >{`Your account is under lockdown. We sent you a reactivation link to your email address ${get(
                  forceConfirmation,
                  'email'
                )}`}</Typography>
                <Form input={() => null} label="Resend" onSubmit={resendConfirmation} />
                <QuestionAction question="Not you?" onClick={() => setForceConfirmation(null)}>
                  Click here.
                </QuestionAction>
                <Box mt={2}>
                  <Link
                    onClick={() => {
                      clickForgotPassword(true);
                      setForceConfirmation(false);
                    }}
                  >
                    Or Forgot Password?
                  </Link>
                </Box>
              </Fragment>
            ) : forceChangePassword ? (
              <Fragment>
                <Typography variant="h2" gutterBottom>
                  Change Password
                </Typography>
                {isFirstLogin ? (
                  <Typography>Our security policy requires all users to set their password on first login.</Typography>
                ) : (
                  <Typography>
                    We noticed that you didn‘t change your password in a while, please do so now to proceed.
                  </Typography>
                )}
                <Typography variant="h3">{get(forceChangePassword, 'email')}</Typography>
                <Form input={PasswordChangeInput} onSubmit={changePassword} label="Change" />
                <QuestionAction question="Not you?" onClick={() => setForceChangePassword(null)}>
                  Click here.
                </QuestionAction>
              </Fragment>
            ) : forgotPassword ? (
              <Fragment>
                {!!resetPasswordLinkSent && (
                  <Box mb={2}>
                    <Alert severity="info">
                      In a few moments, you will receive an email with a link to reset your password. Please check your
                      email.
                    </Alert>
                  </Box>
                )}
                <Box mb={4}>
                  <Typography variant="h2" gutterBottom>
                    Please enter your email
                  </Typography>
                  <Typography variant="body1">We will send an email with a link to reset your password</Typography>
                </Box>
                <Form autoFocus input={ForgotPasswordInput} label="Send" onSubmit={sendResetPasswordLink} />
              </Fragment>
            ) : isSessionUnknown ? (
              <Fragment>
                <Typography variant="h2" component="h1" gutterBottom>
                  Log In
                </Typography>
                <Form autoFocus input={CredentialsInput} label="Log In" onSubmit={logInWithCredentials} />
                <Box mt={2}>
                  <Link onClick={clickForgotPassword}>Forgot Password?</Link>
                </Box>
              </Fragment>
            ) : isSessionValid ? (
              <Fragment>
                <Typography variant="h2">{session.user.name}</Typography>
                <Form input={() => null} label="Continue" onSubmit={goToApp} />
                <QuestionAction question="Not you?" onClick={destroy}>
                  Click here.
                </QuestionAction>
              </Fragment>
            ) : (
              <Fragment>
                <Typography variant="h2">{session.user.name}</Typography>
                <Form autoFocus input={PasswordInput} label="Log In" onSubmit={logInWithPassword} />
                <QuestionAction question="Not you?" onClick={destroy}>
                  Click here.
                </QuestionAction>
              </Fragment>
            )}
          </Box>
          <Copyright />
        </Box>
        <Box className={classes.illustrationWrapper}>
          <img src={loginIllustration} alt="login illustration" className={classes.illustration} />
        </Box>
      </Container>
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  page: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  wrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      flex: 1,
      maxWidth: 400,
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
    },
  },
  container: {
    flex: 1,
    display: 'flex',
    gap: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  heading: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  form: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    maxWidth: 400,
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      maxWidth: '100%',
    },
  },
  illustrationWrapper: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  illustration: {
    maxWidth: 600,
    margin: 'auto',
  },
}));

export default compose(withSession(identity), withState('busy', 'setBusy', false))(Login);
