import { gql } from '@apollo/client';
import { procedureFragment } from './fragments';

const staffMemberOnly = gql`
  fragment StaffMember on Staff {
    id
    staffId
    hstId
    name
    phoneNumber
    email
    primarySpecialty
    otherSpecialties
    title
    titleAbbr
    titleJson
    employment
    compensation
    hasScheduleAccess
    scheduleAccessAll
    hourlyRate
  }
`;

const openPosition = gql`
  fragment OpenPositionData on StaffShiftOpenPosition {
    id
    staffHospitalId
    staffHospitalName
    staff {
      id
      staffId
      name
      primarySpecialty
      otherSpecialties
      title
      titleAbbr
      titleJson
      phoneNumber
    }
    openPositionSmsCount
    date
    from
    to
    additionalText
    unseen
    description {
      primarySpecialty
      otherSpecialties
      title
      isProcedure
      isRoom
      description
    }
    staffShiftId
    staffShiftHospitalGroupId
    staffShiftHospitalId
    staffShiftHospitalName
    state
    logs {
      type
      state
      userId
      userHospitalId
      userName
      userPhoneNumber
      at
      messageStatus
    }
  }
`;

const all = gql`
  fragment StaffData on Staff {
    id
    staffId
    hstId
    name
    phoneNumber
    email
    primarySpecialty
    otherSpecialties
    title
    titleAbbr
    titleJson
    employment
    compensation
    hasScheduleAccess
    scheduleAccessAll
    hourlyRate
    availability(date: $date) {
      id
      from
      to
      available
    }
  }
`;

export const fragments = {
  openPosition,
  staffMemberOnly,
  all,
  roomShift: gql`
    fragment StaffShiftRoomData on StaffShiftRoom {
      id
      shiftState
      room {
        id
        name
      }
      coworkersShifts {
        id
        shiftState
        staff {
          id
          staffId
          name
          primarySpecialty
          title
          titleAbbr
        }
        from
        to
        note
      }
      procedures {
        ...ProcedureData
      }
      staff {
        id
        staffId
        name
        primarySpecialty
        title
        titleAbbr
        hourlyRate
      }
      date
      from
      to
      additionalText
      note
    }
    ${procedureFragment}
  `,
  shiftOnly: gql`
    fragment StaffShiftData on StaffShift {
      id
      shiftState
      staff {
        ...StaffMember
      }
      from
      to
      additionalText
      note
      hourlyRate
      openPosition {
        primarySpecialty
        otherSpecialties
        title
        description
      }
    }
    ${staffMemberOnly}
  `,
  shift: gql`
    fragment StaffShiftData on StaffShift {
      id
      shiftState
      staff {
        ...StaffData
      }
      from
      to
      additionalText
      note
      hourlyRate
      openPosition {
        primarySpecialty
        otherSpecialties
        title
        description
      }
    }
    ${all}
  `,
  availability: gql`
    fragment StaffAvailabilityData on StaffAvailability {
      id
      staffId
      date
      from
      to
      available
    }
  `,
};

export const list = gql`
  query staffMembers($date: Date) {
    staffMembers: staff {
      ...StaffData
      pin
    }
  }
  ${fragments.all}
`;

export const listAssignedStaffMembers = gql`
  query listAssignedStaffMembers($date: Date!) {
    listAssignedStaffMembers(date: $date) {
      ...StaffMember
    }
  }
  ${fragments.staffMemberOnly}
`;

export const hasAssigned = gql`
  query hasAssigned($date: Date!) {
    hasAssignedStaffMembers(date: $date)
    hasAssignedAnesthesiologists(date: $date)
    hasAssignedVendors(date: $date)
  }
`;

export const listHiringManagers = gql`
  query listHiringManagers {
    listHiringManagers {
      id
      name
      hospitalId
      hospitalName
      email
      phoneNumber
    }
  }
`;

export const listForShift = gql`
  subscription staffForProcedure($date: Date!, $roomId: Long, $procedureId: Long) {
    staffMembers: staff {
      ...StaffData
      assignedShift(date: $date, roomId: $roomId, procedureId: $procedureId) {
        id
        from
        to
        additionalText
      }
    }
  }
  ${fragments.all}
`;

export const listForShiftByPrimarySpecialty = gql`
  subscription staffForProcedureByPrimarySpecialty(
    $date: Date!
    $roomId: Long
    $procedureId: Long
    $primarySpecialty: StaffPrimarySpecialty
  ) {
    staffMembers: staffByPrimarySpecialty(primarySpecialty: $primarySpecialty) {
      ...StaffData
      assignedShift(date: $date, roomId: $roomId, procedureId: $procedureId) {
        id
        from
        to
        additionalText
      }
    }
  }
  ${fragments.all}
`;

export const item = gql`
  query staffMember($id: Long!, $date: Date) {
    staffMember(id: $id) {
      ...StaffData
    }
  }
  ${fragments.all}
`;

export const create = gql`
  mutation createStaff(
    $name: String!
    $phoneNumber: String
    $email: String
    $hstId: HstId
    $primarySpecialty: StaffPrimarySpecialty
    $otherSpecialties: [OtherSpecialty!]
    $title: Json
    $employment: StaffEmployment
    $compensation: StaffCompensation
    $hourlyRate: Int
  ) {
    createStaff(
      name: $name
      phoneNumber: $phoneNumber
      email: $email
      hstId: $hstId
      primarySpecialty: $primarySpecialty
      otherSpecialties: $otherSpecialties
      title: $title
      employment: $employment
      compensation: $compensation
      hourlyRate: $hourlyRate
    ) {
      ...StaffMember
    }
  }
  ${fragments.staffMemberOnly}
`;

export const update = gql`
  mutation editStaff(
    $id: Long!
    $name: String!
    $phoneNumber: String
    $email: String
    $hstId: HstId
    $primarySpecialty: StaffPrimarySpecialty
    $otherSpecialties: [OtherSpecialty!]
    $title: Json
    $employment: StaffEmployment
    $compensation: StaffCompensation
    $hourlyRate: Int
  ) {
    editStaff(
      id: $id
      name: $name
      phoneNumber: $phoneNumber
      email: $email
      hstId: $hstId
      primarySpecialty: $primarySpecialty
      otherSpecialties: $otherSpecialties
      title: $title
      employment: $employment
      compensation: $compensation
      hourlyRate: $hourlyRate
    ) {
      ...StaffMember
    }
  }
  ${fragments.staffMemberOnly}
`;

export const remove = gql`
  mutation deleteStaff($id: Long!) {
    deleteStaff(id: $id)
  }
`;

export const assignMultipleShifts = gql`
  mutation assignMultipleShifts(
    $date: Date!
    $staffId: Long
    $roomIds: [Long!]!
    $procedureIds: [Long!]!
    $hourlyRate: Int
  ) {
    assignMultipleShifts(
      date: $date
      staffId: $staffId
      roomIds: $roomIds
      procedureIds: $procedureIds
      hourlyRate: $hourlyRate
    )
  }
`;

export const openPositionCounterReset = gql`
  mutation openPositionCounterReset($userId: Long!) {
    openPositionCounterReset(userId: $userId)
  }
`;

export const openPositionSeenBy = gql`
  mutation openPositionSeenBy($userId: Long!, $id: Long!, $shiftHospitalId: Long!, $isProcedure: Boolean!) {
    openPositionSeenBy(userId: $userId, id: $id, shiftHospitalId: $shiftHospitalId, isProcedure: $isProcedure)
  }
`;

export const addStaffShiftProcedure = gql`
  mutation addStaffShiftProcedure($procedureId: Long!, $staffHospitalId: Long!, $staffId: Long, $hourlyRate: Int) {
    addStaffShiftProcedure(
      procedureId: $procedureId
      staffHospitalId: $staffHospitalId
      staffId: $staffId
      hourlyRate: $hourlyRate
    )
  }
`;

export const assignStaffShiftProcedure = gql`
  mutation assignStaffShiftProcedure($id: Long!, $shiftHospitalId: Long!, $staffHospitalId: Long!, $staffId: Long!) {
    assignStaffShiftProcedure(
      id: $id
      shiftHospitalId: $shiftHospitalId
      staffHospitalId: $staffHospitalId
      staffId: $staffId
    )
  }
`;

export const bookPositionProcedure = gql`
  mutation bookPositionProcedure($id: Long!, $shiftHospitalId: Long!, $staffHospitalId: Long!, $staffId: Long!) {
    bookPositionProcedure(
      id: $id
      shiftHospitalId: $shiftHospitalId
      staffHospitalId: $staffHospitalId
      staffId: $staffId
    )
  }
`;

export const hiringManagerApproveProcedure = gql`
  mutation hiringManagerApproveProcedure(
    $id: Long!
    $shiftHospitalId: Long!
    $staffHospitalId: Long!
    $staffId: Long!
    $phoneNumber: String!
  ) {
    hiringManagerApproveProcedure(
      id: $id
      shiftHospitalId: $shiftHospitalId
      staffHospitalId: $staffHospitalId
      staffId: $staffId
      phoneNumber: $phoneNumber
    )
  }
`;

export const hiringManagerRejectProcedure = gql`
  mutation hiringManagerRejectProcedure($id: Long!, $shiftHospitalId: Long!, $staffHospitalId: Long!, $staffId: Long!) {
    hiringManagerRejectProcedure(
      id: $id
      shiftHospitalId: $shiftHospitalId
      staffHospitalId: $staffHospitalId
      staffId: $staffId
    )
  }
`;

export const sendOpenPositionProcedureNotification = gql`
  mutation sendOpenPositionProcedureNotification(
    $id: Long!
    $shiftHospitalId: Long!
    $staffHospitalId: Long!
    $staffId: Long!
  ) {
    sendOpenPositionProcedureNotification(
      id: $id
      shiftHospitalId: $shiftHospitalId
      staffHospitalId: $staffHospitalId
      staffId: $staffId
    )
  }
`;

export const sendOpenPositionRoomNotification = gql`
  mutation sendOpenPositionRoomNotification(
    $id: Long!
    $shiftHospitalId: Long!
    $staffHospitalId: Long!
    $staffId: Long!
  ) {
    sendOpenPositionRoomNotification(
      id: $id
      shiftHospitalId: $shiftHospitalId
      staffHospitalId: $staffHospitalId
      staffId: $staffId
    )
  }
`;

export const removeStaffShiftProcedure = gql`
  mutation removeStaffShiftProcedure($id: Long!, $shiftHospitalId: Long!, $staffHospitalId: Long!) {
    removeStaffShiftProcedure(id: $id, shiftHospitalId: $shiftHospitalId, staffHospitalId: $staffHospitalId)
  }
`;

export const addStaffShiftRoom = gql`
  mutation addStaffShiftRoom($roomId: Long!, $date: Date!, $staffHospitalId: Long!, $staffId: Long, $hourlyRate: Int) {
    addStaffShiftRoom(
      roomId: $roomId
      date: $date
      staffHospitalId: $staffHospitalId
      staffId: $staffId
      hourlyRate: $hourlyRate
    )
  }
`;

export const assignStaffShiftRoom = gql`
  mutation assignStaffShiftRoom($id: Long!, $shiftHospitalId: Long!, $staffHospitalId: Long!, $staffId: Long!) {
    assignStaffShiftRoom(
      id: $id
      shiftHospitalId: $shiftHospitalId
      staffHospitalId: $staffHospitalId
      staffId: $staffId
    )
  }
`;

export const bookPositionRoom = gql`
  mutation bookPositionRoom($id: Long!, $shiftHospitalId: Long!, $staffHospitalId: Long!, $staffId: Long!) {
    bookPositionRoom(id: $id, shiftHospitalId: $shiftHospitalId, staffHospitalId: $staffHospitalId, staffId: $staffId)
  }
`;

export const hiringManagerApproveRoom = gql`
  mutation hiringManagerApproveRoom(
    $id: Long!
    $shiftHospitalId: Long!
    $staffHospitalId: Long!
    $staffId: Long!
    $phoneNumber: String!
  ) {
    hiringManagerApproveRoom(
      id: $id
      shiftHospitalId: $shiftHospitalId
      staffHospitalId: $staffHospitalId
      staffId: $staffId
      phoneNumber: $phoneNumber
    )
  }
`;

export const hiringManagerRejectRoom = gql`
  mutation hiringManagerRejectRoom($id: Long!, $shiftHospitalId: Long!, $staffHospitalId: Long!, $staffId: Long!) {
    hiringManagerRejectRoom(
      id: $id
      shiftHospitalId: $shiftHospitalId
      staffHospitalId: $staffHospitalId
      staffId: $staffId
    )
  }
`;

export const removeStaffShiftRoom = gql`
  mutation removeStaffShiftRoom($id: Long!, $shiftHospitalId: Long!, $staffHospitalId: Long!) {
    removeStaffShiftRoom(id: $id, shiftHospitalId: $shiftHospitalId, staffHospitalId: $staffHospitalId)
  }
`;

export const setStaffShiftRoomFrom = gql`
  mutation setStaffShiftRoomFrom($id: Long!, $from: Time) {
    setStaffShiftRoomFrom(id: $id, from: $from)
  }
`;

export const setStaffShiftRoomAdditionalText = gql`
  mutation setStaffShiftRoomAdditionalText($id: Long!, $additionalText: String) {
    setStaffShiftRoomAdditionalText(id: $id, additionalText: $additionalText)
  }
`;

export const setStaffShiftRoomTo = gql`
  mutation setStaffShiftRoomTo($id: Long!, $to: Time) {
    setStaffShiftRoomTo(id: $id, to: $to)
  }
`;

export const setStaffShiftRoomWorkingTime = gql`
  mutation setStaffShiftRoomWorkingTime($id: Long!, $from: Time, $to: Time) {
    setStaffShiftRoomWorkingTime(id: $id, from: $from, to: $to)
  }
`;

export const setStaffShiftProcedureFrom = gql`
  mutation setStaffShiftProcedureFrom($id: Long!, $from: Time) {
    setStaffShiftProcedureFrom(id: $id, from: $from)
  }
`;

export const setStaffShiftProcedureTo = gql`
  mutation setStaffShiftProcedureTo($id: Long!, $to: Time) {
    setStaffShiftProcedureTo(id: $id, to: $to)
  }
`;

export const setStaffShiftProcedureWorkingTime = gql`
  mutation setStaffShiftProcedureWorkingTime($id: Long!, $from: Time, $to: Time) {
    setStaffShiftProcedureWorkingTime(id: $id, from: $from, to: $to)
  }
`;

export const setStaffShiftProcedureAdditionalText = gql`
  mutation setStaffShiftProcedureAdditionalText($id: Long!, $additionalText: String) {
    setStaffShiftProcedureAdditionalText(id: $id, additionalText: $additionalText)
  }
`;

export const editOpenPositionProcedure = gql`
  mutation editOpenPositionProcedure(
    $id: Long!
    $primarySpecialty: StaffPrimarySpecialty
    $otherSpecialties: [OtherSpecialty!]
    $title: Json
    $description: String
  ) {
    editOpenPositionProcedure(
      id: $id
      primarySpecialty: $primarySpecialty
      otherSpecialties: $otherSpecialties
      title: $title
      description: $description
    )
  }
`;

export const editOpenPositionRoom = gql`
  mutation editOpenPositionRoom(
    $id: Long!
    $primarySpecialty: StaffPrimarySpecialty
    $otherSpecialties: [OtherSpecialty!]
    $title: Json
    $description: String
  ) {
    editOpenPositionRoom(
      id: $id
      primarySpecialty: $primarySpecialty
      otherSpecialties: $otherSpecialties
      title: $title
      description: $description
    )
  }
`;

export const grantScheduleAccess = gql`
  mutation grantScheduleAccessToStaff($id: Long!) {
    grantScheduleAccessToStaff(id: $id) {
      ...StaffMember
    }
  }
  ${fragments.staffMemberOnly}
`;

export const resendScheduleAccessLink = gql`
  mutation resendScheduleAccessLinkToStaff($id: Long!) {
    resendScheduleAccessLinkToStaff(id: $id) {
      ...StaffMember
    }
  }
  ${fragments.staffMemberOnly}
`;

export const sendNewScheduleNotification = gql`
  mutation sendNewScheduleNotification($staffContacts: [StaffMemberContactInput!]!, $message: String!, $date: Date!) {
    sendNewScheduleNotification(staffContacts: $staffContacts, message: $message, date: $date)
  }
`;

export const sendNewOpenPositionNotification = gql`
  mutation sendNewOpenPositionNotification(
    $openPositionId: String!
    $hiringManagersContacts: [HiringManagerContactInput!]!
    $message: String!
  ) {
    sendNewOpenPositionNotification(
      openPositionId: $openPositionId
      hiringManagersContacts: $hiringManagersContacts
      message: $message
    )
  }
`;

export const revokeScheduleAccess = gql`
  mutation revokeScheduleAccessToStaff($id: Long!) {
    revokeScheduleAccessToStaff(id: $id) {
      ...StaffMember
    }
  }
  ${fragments.staffMemberOnly}
`;

export const setScheduleAccessAll = gql`
  mutation setScheduleAccessAll($id: Long!, $scheduleAccessAll: Boolean!) {
    setScheduleAccessAll(id: $id, scheduleAccessAll: $scheduleAccessAll)
  }
`;

export const getScheduleAccessAll = gql`
  query getScheduleAccessAll($id: Long) {
    getScheduleAccessAll(id: $id)
  }
`;

export const staffAvailabilitySuggestedTimeRanges = gql`
  query staffAvailabilitySuggestedTimeRanges($staffId: Long, $available: Boolean!) {
    staffAvailabilitySuggestedTimeRanges(staffId: $staffId, available: $available) {
      from
      to
    }
  }
`;

export const listStaffAvailability = gql`
  subscription listStaffAvailability($from: Date!, $to: Date!, $staffId: Long!) {
    listStaffAvailability(from: $from, to: $to, staffId: $staffId) {
      ...StaffAvailabilityData
    }
  }
  ${fragments.availability}
`;

export const listOthersPTOs = gql`
  subscription listOthersPTOs($from: Date!, $to: Date!, $staffId: Long!) {
    listOthersPTOs(from: $from, to: $to, staffId: $staffId) {
      ...StaffAvailabilityData
      staff {
        ...StaffMember
      }
    }
  }
  ${fragments.availability}
  ${fragments.staffMemberOnly}
`;

export const listStaffPTOs = gql`
  subscription listStaffPTOs($from: Date!, $to: Date!) {
    listStaffPTOs(from: $from, to: $to) {
      ...StaffAvailabilityData
    }
  }
  ${fragments.availability}
`;

export const addStaffAvailability = gql`
  mutation addStaffAvailability($staffId: Long!, $dates: [Date!]!, $from: Time, $to: Time, $available: Boolean!) {
    addStaffAvailability(staffId: $staffId, dates: $dates, from: $from, to: $to, available: $available) {
      ...StaffAvailabilityData
    }
  }
  ${fragments.availability}
`;

export const updateStaffAvailability = gql`
  mutation updateStaffAvailability($eventIds: [Long!]!, $from: Time, $to: Time) {
    updateStaffAvailability(eventIds: $eventIds, from: $from, to: $to)
  }
`;

export const removeStaffAvailability = gql`
  mutation removeStaffAvailability($eventIds: [Long!]!) {
    removeStaffAvailability(eventIds: $eventIds)
  }
`;

export const openPositionCount = gql`
  query openPositionCount($userId: Long!) {
    openPositionCount(userId: $userId)
  }
`;

export const getOpenPositions = gql`
  query openPosition($id: String!) {
    openPosition(id: $id) {
      ...OpenPositionData
    }
  }
  ${fragments.openPosition}
`;

export const getClosedPositions = gql`
  query closedPosition($id: String!) {
    closedPosition(id: $id) {
      ...OpenPositionData
    }
  }
  ${fragments.openPosition}
`;

export const listOpenPositions = gql`
  query openPositions($filter: Filter) {
    openPositions(filter: $filter) {
      ...OpenPositionData
    }
  }
  ${fragments.openPosition}
`;

export const listClosedPositions = gql`
  query closedPositions($filter: Filter) {
    closedPositions(filter: $filter) {
      ...OpenPositionData
    }
  }
  ${fragments.openPosition}
`;

export default {
  list,
  item,
  create,
  update,
  remove,
  grantScheduleAccess,
  resendScheduleAccessLink,
  revokeScheduleAccess,
  setScheduleAccessAll,
};
