import {
  Box,
  Button,
  createStyles,
  Dialog,
  DialogTitle,
  makeStyles,
  Tab,
  Tabs,
  ThemeProvider,
  Typography,
  TextField,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import Chat from './Chat';
import { gql, useMutation, useQuery } from '@apollo/client';
import React, { FC, useEffect, useState } from 'react';
import { Patient } from '../../types/Patient';
import ThreadInfo from '../../types/ThreadInfo';
import { list as listMessage } from '../../graph/customMessages';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import { tabletThemeDark } from '../../themes/tabletTheme';
import { useScope } from '../../hooks/useScope';
import { editCaretakerNote } from '../../graph/patients';

export interface ChatDialogProps {
  thread?: ThreadInfo;
  createThread: () => Promise<string>;
  markAsRead: () => Promise<void>;
  dialogTitle: string;
  dialogSubtitle: string;
  dialogAdditionalInfo?: string;
  open: boolean;
  handleClose: () => void;
  patient: Patient;
}

export const ChatDialog = ({
  thread,
  createThread,
  markAsRead,
  dialogTitle,
  dialogSubtitle,
  dialogAdditionalInfo,
  open,
  handleClose,
  patient,
}: ChatDialogProps) => {
  const scope = useScope();
  const hospitalName = scope?.hospital?.name;

  const { data, loading } = useQuery(listMessage);

  const classes = useStyles();

  const [markingAsRead, setMarkingAsRead] = useState(false);

  const markAsReadAndClose = async () => {
    try {
      setMarkingAsRead(true);

      await markAsRead();

      handleClose?.();
    } finally {
      setMarkingAsRead(false);
    }
  };

  const [height, setHeight] = useState<string>('calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom))');
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);

  // Add an event listener to detect changes in window.innerHeight.
  useEffect(() => {
    const handleResize = () => {
      // Calculate the height of the visible area after the keyboard opens.
      const windowHeight = window.innerHeight;
      const keyboardHeight = 30;
      const visibleHeight = windowHeight - keyboardHeight;

      // Check if the keyboard is open based on the change in visible height.
      setIsKeyboardOpen(visibleHeight < windowHeight);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (!open) {
      return;
    }

    const { visualViewport } = window;

    if (!visualViewport) {
      return;
    }

    const updateHeight = () => {
      if (isKeyboardOpen) {
        // Reduce the modal height when the keyboard is open.
        setHeight('calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom) - keyboardHeight)');
      } else {
        // Reset the modal height when the keyboard is closed.
        setHeight('calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom)');
      }
    };

    updateHeight();

    visualViewport.addEventListener('resize', updateHeight);

    return () => {
      visualViewport.removeEventListener('resize', updateHeight);
      setHeight('calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom))');
    };
  });

  const stopPropagation = (e: React.BaseSyntheticEvent<unknown, unknown>) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const [selectedTab, setSelectedTab] = useState(-1);
  const [selectedMessageContent, setSelectedMessageContent] = useState('');

  const handleChangeTab = async (event, newValue) => {
    // Update selectedMessageContent with the content of the selected message
    setSelectedTab(newValue);
    const messageContent = data.customMessages[newValue].content;
    setSelectedMessageContent(replacePlaceholders(messageContent, patient, hospitalName));
  };

  useEffect(() => {
    if (!open) {
      setSelectedTab(-1);
      setSelectedMessageContent('');
    }
  }, [open]);

  const resetSelectedTab = () => {
    setSelectedTab(-1);
    setSelectedMessageContent('');
  };

  const replacePlaceholders = (content: string, patient: Patient, hospitalName: string) => {
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString();
    const formattedTime = currentDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });

    let physicianName = '';
    if (typeof patient?.physician === 'string') {
      physicianName = patient?.physician;
    } else if (typeof patient?.physician === 'object') {
      physicianName = patient?.physician?.name || '';
    }

    return content
      .replace(/{{patientFullName}}/g, patient?.name || '')
      .replace(/{{physicianName}}/g, physicianName)
      .replace(/{{patientInitials}}/g, patient?.initials || '')
      .replace(/{{hospitalName}}/g, hospitalName)
      .replace(/{{date}}/g, formattedDate)
      .replace(/{{currentTime}}/g, formattedTime);
  };

  let tabHeight = '100vh';
  if (/iPhone|iPad|iPod|Android|Samsung|Tablet/.test(window.navigator.userAgent)) {
    tabHeight = '600px';
  }

  const [isEditingNote, setIsEditingNote] = useState(false);
  const [editedNote, setEditedNote] = useState(dialogAdditionalInfo);

  const [updateCaretakerNote] = useMutation(editCaretakerNote);

  const handleEditClick = () => {
    setIsEditingNote(true);
  };

  const handleNoteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditedNote(event.target.value);
  };

  const handleSaveNote = async () => {
    try {
      await updateCaretakerNote({ variables: { id: patient.id, note: editedNote } });
      setIsEditingNote(false);
    } catch (error) {
      console.error('Error updating caretaker note:', error);
    }
  };

  const handleCancelEdit = () => {
    setIsEditingNote(false);
    setEditedNote(dialogAdditionalInfo);
  };

  return (
    <ThemeProvider theme={tabletThemeDark}>
      <Box onClick={stopPropagation}>
        <Dialog open={open} onClose={handleClose} maxWidth="md">
          <DialogTitle id="form-dialog-title">
            <Box display="flex" justifyContent="space-between">
              <div>
                <Typography variant="h4">{dialogTitle}</Typography>
                <Typography color="textSecondary" style={{ fontSize: '1.25rem' }}>
                  {dialogSubtitle}
                </Typography>
                {isEditingNote ? (
                  <Box display="flex" alignItems="center" gap={1}>
                    <TextField
                      value={editedNote}
                      onChange={handleNoteChange}
                      variant="outlined"
                      size="small"
                      fullWidth
                      autoFocus
                    />
                    <Button variant="contained" color="primary" onClick={handleSaveNote}>
                      Save
                    </Button>
                    <Button variant="text" onClick={handleCancelEdit}>
                      Cancel
                    </Button>
                  </Box>
                ) : (
                  <Typography color="textSecondary" onClick={handleEditClick} style={{ cursor: 'pointer' }}>
                    {dialogAdditionalInfo ? `Notes: ${dialogAdditionalInfo}` : ''}
                  </Typography>
                )}
              </div>
              {handleClose && (
                <div>
                  {thread?.hasUnreadMessages && (
                    <Button aria-label="close" onClick={markAsReadAndClose} disabled={markingAsRead}>
                      {markingAsRead ? 'Marking as Read…' : 'Mark as Read and Close'}
                    </Button>
                  )}
                  <IconButton aria-label="close" onClick={handleClose}>
                    <Close />
                  </IconButton>
                </div>
              )}
            </Box>
          </DialogTitle>
          <DialogContent className={classes.content} dividers>
            <Box>
              <Typography variant="h5" gutterBottom>
                Templates
              </Typography>
              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={selectedTab}
                onChange={handleChangeTab}
                aria-label="Chat Tabs"
                indicatorColor="primary"
              >
                {data &&
                  data.customMessages &&
                  Array.isArray(data.customMessages) &&
                  data.customMessages.map((item, index) => <Tab key={index} label={item.title} />)}
              </Tabs>
            </Box>
            <div>
              <Divider orientation="vertical" />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Chat
                threadId={thread?.id}
                createThread={createThread}
                messageContent={selectedMessageContent}
                resetSelectedTab={resetSelectedTab}
              />
            </div>
          </DialogContent>
        </Dialog>
      </Box>
    </ThemeProvider>
  );
};

function useMarkAsRead(threadId?: string) {
  const [markMessagesAsRead] = useMutation(gql`
    mutation markMessagesAsRead($threadId: String!) {
      markMessagesAsRead(threadId: $threadId)
    }
  `);

  return async () => {
    if (!threadId) {
      return;
    }

    await markMessagesAsRead({ variables: { threadId } });
  };
}

interface CaretakerChatDialogProps {
  patient: Patient;
  open: boolean;
  handleClose: () => void;
}

export const CaretakerChatDialog: FC<CaretakerChatDialogProps> = ({ patient, open, handleClose }) => {
  const [createCaretakerThread] = useMutation(gql`
    mutation createCaretakerThread($patientId: Long!) {
      createCaretakerThread(patientId: $patientId)
    }
  `);

  const createThread = async () => {
    const result = await createCaretakerThread({ variables: { patientId: patient.id } });

    return result.data.createCaretakerThread;
  };

  const markAsRead = useMarkAsRead(patient?.caretakerThread?.id);

  return (
    <ChatDialog
      thread={patient?.caretakerThread}
      markAsRead={markAsRead}
      createThread={createThread}
      dialogTitle={`Send Message to Caretaker`}
      dialogSubtitle={`Patient ${patient?.name} • Caretaker Phone Number ${patient?.notificationNumbers}${
        patient?.caretakerName ? ` • Caretaker Name ${patient.caretakerName}` : ''
      }`}
      dialogAdditionalInfo={patient?.caretakerNote}
      open={open}
      handleClose={handleClose}
      patient={patient}
    />
  );
};

interface PatientChatDialogProps {
  patient: Patient;
  open: boolean;
  handleClose: () => void;
}

export const PatientChatDialog: FC<PatientChatDialogProps> = ({ patient, open, handleClose }) => {
  const [createPatientThread] = useMutation(gql`
    mutation createPatientThread($patientId: Long!) {
      createPatientThread(patientId: $patientId)
    }
  `);

  const createThread = async () => {
    const result = await createPatientThread({ variables: { patientId: patient?.id } });

    return result.data.createPatientThread;
  };

  const markAsRead = useMarkAsRead(patient?.patientThread?.id);

  return (
    <ChatDialog
      thread={patient?.patientThread}
      markAsRead={markAsRead}
      createThread={createThread}
      dialogTitle={`Send Message to Patient`}
      dialogSubtitle={`Patient ${patient?.name} • Patient Phone Number ${patient?.phoneNumber}`}
      open={open}
      handleClose={handleClose}
      patient={patient}
    />
  );
};

interface PreOpPatientChatDialogProps {
  patient: Patient;
  open: boolean;
  handleClose: () => void;
}

export const PreOpPatientChatDialog: FC<PreOpPatientChatDialogProps> = ({ patient, open, handleClose }) => {
  const [createPatientThreadForProcedure] = useMutation(gql`
    mutation createPatientThreadForProcedure($procedureId: Long!) {
      createPatientThreadForProcedure(procedureId: $procedureId)
    }
  `);

  const createThread = async () => {
    const result = await createPatientThreadForProcedure({ variables: { procedureId: patient?.procedureId } });

    return result.data.createPatientThreadForProcedure;
  };

  const markAsRead = useMarkAsRead(patient?.patientThread?.id);

  return (
    <ChatDialog
      thread={patient?.patientThread}
      markAsRead={markAsRead}
      createThread={createThread}
      dialogTitle={`Send Message to Patient`}
      dialogSubtitle={`Patient ${patient?.name} • Patient Phone Number ${patient?.phoneNumber}`}
      open={open}
      handleClose={handleClose}
      patient={patient}
    />
  );
};

const useStyles = makeStyles(theme =>
  createStyles({
    content: {
      display: 'flex',
      gap: theme.spacing(2),
    },
  })
);
