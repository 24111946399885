import React from 'react';
import Header from './components/Header';
import styles, { getGridChildStyles, getGridHeaderChildStyles, getGridParentStyles } from './styles';
import { useValue } from '../ValueContext';
import PatientInfo from './components/PatientInfo';
import Footer from './components/Footer';
import MedicalPassportField from '../components/MedicalPassportField';
import MedicalPassportCheckbox from '../components/MedicalPassportCheckbox';
import toSelectedCheckBox from '../../../../../questionnaire/toSelectedCheckbox';
import get from 'lodash/get';
import MedicalPassportTextArea from '../components/MedicalPassportTextArea';

const Page2 = () => {
  const { questionnaireAnswers } = useValue();
  const policy = get(questionnaireAnswers, 'sections.documentElectronicSignature.value.patientRights', false);

  return (
    <div id="page2" className="page" style={{...styles.root, fontFamily:'Arial'}}>
      <Header pageNumber={2} pageCount={10} />
      <div>POLICIES ACKNOWLEDGEMENT</div>
      <PatientInfo />
      <br />
      <br />
      <br />
      <div style={getGridParentStyles(4, true)}>
        <div style={getGridHeaderChildStyles(4)}>Pre-Visit Instructions</div>

        <div style={getGridChildStyles(1)}>NPO</div>
        <div style={getGridChildStyles(1)}>
          <input type="checkbox" name="npo" checked={policy} />
          <label htmlFor="npolabel">Yes</label>
        </div>

        <div style={getGridChildStyles(1)}>No Shaving</div>
        <div style={getGridChildStyles(1)}>
          <input type="checkbox" name="shaving" checked={policy} />
          <label htmlFor="shavinglabel">Yes</label>
        </div>

        <div style={getGridChildStyles(1)}>Delay for arrival</div>
        <div style={getGridChildStyles(1)}>
          <input type="checkbox" name="arrival" checked={policy} />
          <label htmlFor="arrivallabel">Yes</label>
        </div>

        <div style={getGridChildStyles(1)}>Leave valuables at home</div>
        <div style={getGridChildStyles(1)}>
          <input type="checkbox" name="leave" checked={policy} />
          <label htmlFor="leavelabel">Yes</label>
        </div>

        <div style={getGridChildStyles(1)}>Bring photo ID & insurance</div>
        <div style={getGridChildStyles(1)}>
          <input type="checkbox" name="ID" checked={policy} />
          <label htmlFor="IDlabel">Yes</label>
        </div>

        <div style={getGridChildStyles(1)}>Advance Directive</div>
        <div style={getGridChildStyles(1)}>
          <input type="checkbox" name="advance" checked={policy} />
          <label htmlFor="advancelabel">Yes</label>
        </div>

        <div style={getGridChildStyles(1)}>Shower/Brush teeth</div>
        <div style={getGridChildStyles(1)}>
          <input type="checkbox" name="shower" checked={policy} />
          <label htmlFor="showerlabel">Yes</label>
        </div>

        <div style={getGridChildStyles(1)}>Clothing</div>
        <div style={getGridChildStyles(1)}>
          <input type="checkbox" name="clothing" checked={policy} />
          <label htmlFor="clothinglabel">Yes</label>
        </div>

        <div style={getGridChildStyles(1)}>No make-up / perfume / lotion / nail polish</div>
        <div style={getGridChildStyles(1)}>
          <input type="checkbox" name="makeup" checked={policy} />
          <label htmlFor="makeuplabel">Yes</label>
        </div>

        <div style={getGridChildStyles(1)}>No Jewelry</div>
        <div style={getGridChildStyles(1)}>
          <input type="checkbox" name="jewelry" checked={policy} />
          <label htmlFor="jewerlylabel">Yes</label>
        </div>

        <div style={getGridChildStyles(1)}>Glasses/dentures</div>
        <div style={getGridChildStyles(1)}>
          <input type="checkbox" name="glasses" checked={policy} />
          <label htmlFor="glasseslabel">Yes</label>
        </div>

        <div style={getGridChildStyles(1)}>Take prescribed meds</div>
        <div style={getGridChildStyles(1)}>
          <input type="checkbox" name="meds" checked={policy} />
          <label htmlFor="medslabel">Yes</label>
        </div>

        <div style={getGridChildStyles(1)}>Bring list of meds</div>
        <div style={getGridChildStyles(1)}>
          <input type="checkbox" name="list" checked={policy} />
          <label htmlFor="listlabel">Yes</label>
        </div>

        <div style={getGridChildStyles(1)}>Ride home</div>
        <div style={getGridChildStyles(1)}>
          <input type="checkbox" name="ride" checked={policy} />
          <label htmlFor="ridelabel">Yes</label>
        </div>

        <div style={getGridChildStyles(1)}>Arrival time</div>
        <div style={getGridChildStyles(1)}>
          <input type="checkbox" name="arrivalT" checked={policy} />
          <label htmlFor="arrivalTlabel">Yes</label>
        </div>

        <div style={getGridChildStyles(1)}>Questions / concerns</div>
        <div style={getGridChildStyles(1)}>
          <input type="checkbox" name="question" checked={policy} />
          <label htmlFor="questionlabel">Yes</label>
        </div>

        <div style={getGridHeaderChildStyles(4)}>Discharge Screening</div>

        <div style={getGridChildStyles(1)}>Ride home</div>
        <MedicalPassportCheckbox
          name="page2.rideHome"
          label="Yes"
          style={getGridChildStyles(1)}
          path={['demographicInformation', 'ridehomeInfo']}
        />
        <div style={getGridChildStyles(2, 3)} />

        <div style={getGridChildStyles(1)}>Driver name</div>
        <MedicalPassportField
          name="page2.driverName"
          label=""
          style={getGridChildStyles(1)}
          path={['demographicInformation', 'ridehomeInfo', 'yes', 'name']}
        />

        <div style={getGridChildStyles(1)}>Driver cell number</div>
        <MedicalPassportField
          name="page2.driverCell"
          label=""
          style={getGridChildStyles(1)}
          path={['demographicInformation', 'ridehomeInfo', 'yes', 'phone']}
        />
      </div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <Footer pageNumber={2} pageCount={11} />
    </div>
  );
};

export default Page2;
