import useHasAccessRight from '../../../hooks/useHasAccessRight';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import NoPermissionText from '../../NoPermissionText';
import React, { forwardRef, ReactNode, useEffect, useMemo, useRef } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import styled from 'styled-components';
import isFunction from 'lodash/isFunction';
import get from 'lodash/get';
import { useTheme } from '@material-ui/core';
import ReactHtmlParser from 'react-html-parser';
import Frame from 'react-frame-component';

interface FormIframeProps {
  children: ReactNode;
  onReady?: () => void;
}

const FormIframe = forwardRef<HTMLIFrameElement, FormIframeProps>(({ children, onReady }, ref) => {
  const hasAccessRight = useHasAccessRight();
  const isAllowedToView = hasAccessRight('patient.view');

  const classes = useStyles();

  return (
    <Box className={clsx({ [classes.content]: !isAllowedToView })}>
      {!isAllowedToView && (
        <Box className={clsx({ [classes.blurred]: !isAllowedToView })}>
          <NoPermissionText withText={true} style={{ top: '50%', left: '25%' }} />
        </Box>
      )}
      <RootFrame ref={ref} onReady={onReady}>
        {children}
      </RootFrame>
    </Box>
  );
});

export default FormIframe;

const useStyles = makeStyles(theme => ({
  content: {
    position: 'relative',
    textAlign: 'center',
    verticalAlign: 'center',
  },
  blurred: {
    backgroundColor: 'white',
    color: theme.palette.background.default,
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 100,
  },
}));

const pageSize = {
  width: 8.5,
  height: 11,
};

const tabletPageSize = {
  width: 182,
  height: 259.4,
};

const scale = 0.8;

const StyledFrame = styled(Frame)`
  width: 8.5in;
  height: 11in;
  overflow: hidden;
  font-size: ${scale}rem;
`;

interface RootFrameProps {
  children: ReactNode;
  onReady?: () => void;
  isTablet?: boolean;
  allowPageBreaks?: boolean;
}

export const RootFrame = React.forwardRef<HTMLIFrameElement, RootFrameProps>(
  ({ children, onReady, isTablet, allowPageBreaks }, ref) => {
    const rootRef = useRef();

    const frameRef = r => {
      rootRef.current = r;

      if (!ref) {
        return;
      }

      if (isFunction(ref)) {
        ref(r);
      } else {
        ref.current = r;
      }
    };

    useEffect(() => {
      const curr = get(rootRef, 'current.node');

      if (!curr) {
        return;
      }

      let animationFrame;

      const loop = () => {
        const w = curr?.contentWindow;

        if (!w) {
          return;
        }

        const style = w.getComputedStyle(w.document.body);
        curr.style.width = style.width;
        curr.style.height = style.height;

        requestAnimationFrame(loop);
      };

      loop();

      return () => {
        cancelAnimationFrame(animationFrame);
      };
    }, []);

    const theme = useTheme();
    const backgroundColor = theme.palette.background.paper;
    const head = useMemo(
      () =>
        ReactHtmlParser(`
    <style>
      html {
        background-color: ${backgroundColor};
      }
      body {
        margin: 0;
        margin-bottom: -0.5rem;
        font-size: 3.9mm;
        line-height: 1.15;
      }
      .page {
        min-height: ${pageSize.height}in;
        margin: 0;
        margin-bottom: 0.5rem;
        font-size: 1em;
        background: white;
        color: black;
        border: 1px solid rgba(0, 0, 0, 0.3);
        box-shadow: 2px 2px 7px 4px rgba(0, 0, 0, 0.15);
        padding: 2rem;
      }

      @media screen {
        .page {
          width: ${pageSize.width}in;
        }
      }

      @media print {
        .page {
          border: none;
        }
        @page {
          width: ${pageSize.width}in;
          min-height: ${pageSize.height}in;
          ${
            allowPageBreaks
              ? `
              margin-top: 2rem;
              margin-bottom: 2rem;
            `
              : `
              margin: 0;
            `
          }
        }

        .page {
          width: ${pageSize.width}in;
          ${
            allowPageBreaks
              ? `
              padding-top: 0;
              padding-bottom: 0;
            `
              : `
              min-height: ${pageSize.height}in;
              margin: 0;

            `
          }
          page-break-after: always;
          font-size: 1em;
          box-shadow: none;
        }
      }
    </style>
  `),
      [allowPageBreaks, backgroundColor]
    );

    useEffect(() => {
      const abortController = new AbortController();

      void (async () => {
        const frame = await tryWaitDefined(() => rootRef.current as { node: HTMLIFrameElement } | undefined, 30000);

        if (abortController.signal.aborted) {
          return;
        }

        if (!frame) {
          onReady?.();
          return;
        }

        const contentDocument = await tryWaitDefined(() => frame.node.contentDocument, 30000);

        if (abortController.signal.aborted) {
          return;
        }

        if (!contentDocument) {
          onReady?.();
          return;
        }

        const pages = contentDocument.querySelectorAll('.page');

        try {
          await Promise.all([...pages].map(page => removeSurplus(page as HTMLElement, abortController.signal)));
        } finally {
          onReady?.();
        }
      })();

      return () => abortController.abort();
    }, []);

    return (
      <StyledFrame ref={frameRef} head={head} frameBorder="0" scrolling="no">
        <style>
          {`
          html {
            font-family: sans-serif;
          }
          p {
            margin: 0;
          }
          .page {
            border: none;
            box-sizing: border-box;
            width: 8.5in !important;
            height: 11in !important;
            overflow: hidden;
          }
          @media print {
            ::-webkit-scrollbar {
              display: none;
            }

            html, body {
              background: none;
              -webkit-print-color-adjust: exact;
              print-color-adjust: exact;
              width: 8.5in;
              overflow-x: hidden;
            }

            .page {
              border: none;
              box-sizing: border-box;
              width: 8.5in !important;
              height: 11in !important;
              overflow: hidden;
            }

            textarea {
              resize: none;
            }
          `}
        </style>
        {children}
      </StyledFrame>
    );
  }
);

export async function tryWaitDefined<T>(fn: () => T | undefined, timeout: number): Promise<T | undefined> {
  return new Promise(resolve => {
    const start = Date.now();

    function loop() {
      const value = fn();

      if (value) {
        resolve(value);
      } else {
        const current = Date.now();

        if (current - start > timeout) {
          resolve(undefined);
        } else {
          requestAnimationFrame(loop);
        }
      }
    }

    loop();
  });
}

export function removeSurplus(page: HTMLElement, signal: AbortSignal): Promise<void> {
  return new Promise((resolve, reject) => {
    let zoom = 1;
    let iteration = 0;

    function loop() {
      if (signal.aborted) {
        reject();
        return;
      }

      const surplus = page.scrollHeight - page.offsetHeight;

      if (surplus > 0) {
        iteration += 1;
        zoom = 1 - iteration * 0.01;

        if (zoom < 0.67) {
          resolve();
          return;
        }

        for (let child of page.children) {
          try {
            (child as HTMLElement).style.setProperty('zoom', String(zoom), 'important');
          } catch (e) {
            console.error(e);
          }
        }

        requestAnimationFrame(loop);
      } else {
        resolve();
      }
    }

    loop();
  });
}
