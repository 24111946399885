import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { Tooltip } from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';
import BulkPrintDialog from './BulkPrintDialog';

interface BulkPrintViewProps {
  items: any[];
}

const BulkPrintView = ({ items }: BulkPrintViewProps) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Tooltip
        title="Select one or more procedures to print documents."
        disableFocusListener={items.length > 0}
        disableHoverListener={items.length > 0}
        disableTouchListener={items.length > 0}
      >
        <div>
          <Button
            size="small"
            color="primary"
            startIcon={<PrintIcon />}
            onClick={() => setOpen(true)}
            disabled={items.length === 0}
          >
            Print Documents
          </Button>
        </div>
      </Tooltip>
      <BulkPrintDialog open={open} onClose={() => setOpen(false)} items={items} />
    </>
  );
};

export default BulkPrintView;
