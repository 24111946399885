import { capitalize } from 'lodash';
import toString from './toString';

const toStringWithoutQuestions2 = toString.with({
  Checkboxes: (label, checkboxes) => {
    const visibleCheckboxes = checkboxes.filter(checkbox => checkbox.checked);
    if (visibleCheckboxes.length === 0) {
      return null;
    } else if (visibleCheckboxes.length === 1) {
      const checkbox = visibleCheckboxes[0];
      if (checkbox.label === 'Other Allergies') {
        return checkbox.child ? `${checkbox.child}` : '';
      }
      if (checkbox.label === 'IV X-ray, Imaging Contrast or Topical Iodine (Betadine)') {
        return checkbox.child ? `${checkbox.child}` : '';
      }
      return `${checkbox.label.replace(/\?/g, '')}${checkbox.child ? ` (${checkbox.child})` : ''}`;
    } else {
      return visibleCheckboxes
        .map(checkbox => {
          if (checkbox.label === 'Other Allergies') {
            return checkbox.child ? `${checkbox.child}` : '';
          }
          if (checkbox.label === 'IV X-ray, Imaging Contrast or Topical Iodine (Betadine)') {
            return checkbox.child ? `${checkbox.child}` : '';
          }
          return `${checkbox.label.replace(/\?/g, '')}${checkbox.child ? ` (${checkbox.child})` : ''}`;
        })
        .join(', ');
    }
  },
  OneOf: (label, options, answer) => (answer ? `${answer.label}${answer.child ? ` (${answer.child})` : ''}` : null),
  YesNo: (question, positive, child) => {
    if (question === 'Do you have any other known allergy (or allergies), adverse reactions or sensitivities your medical team should be aware of?') {
      return child ? `${child}` : '';
    }
    const item = capitalize(
      question
        .replace(/\?/g, '')
        .replace(/^Do you have\s/g, '')
        .replace(/^Are you a\s/g, '')
        .replace(/^Are you\s/g, '')
        .replace(/^Any chance you may be\s/g, '')
        .replace(/^Did you\s/g, '')
        .replace(/^Any\s/g, '')
        .replace(/^Have you ever\s/g, '')
        .replace(/^Have you\s/g, '')
        .replace(/^Is it a\s/g, '')
        .replace(/^Do you\s/g, '') 
        .replace(/^Does your\s/g, '')
        .replace(/^Has\s/g, '')
        .replace(/\(difficulty placing breathing tube for general anesthesia\)/g, '')
        .replace(/^Postoperative nausea and vomiting \(PONV\)/g, 'PONV')
        .replace(/^allergies \(including medications, antibiotics, foods, bee sting, iodine\/shellfish, rubber, contrast dye, medical tape\)/g, '')
    );
    return positive ? `${item}${child ? `(${child})` : ''}` : '';
  },
  
  
});

export default toStringWithoutQuestions2;
