import { ReactNode } from 'react';
import SelectionContext from './SelectionContext';

interface SelectionViewProps<T> {
  context: SelectionContext;
  getItem: (key: string) => T;
  children: (items: T[]) => ReactNode;
}

const SelectionView = <T,>({ context, getItem, children }: SelectionViewProps<T>) => {
  const selectedIds = context.useSelected();

  const selected = selectedIds.flatMap(id => {
    const item = getItem(id);
    return item ? [item] : [];
  });

  return children(selected);
};

export default SelectionView;
