import React from 'react';
import Header from './components/Header';
import MedicalPassportTextArea from '../components/MedicalPassportTextArea';
import MedicationTextArea from '../components/MedicationTextArea';
import MedicalPassportField from '../components/MedicalPassportField';
import MedicalPassportCheckbox from '../components/MedicalPassportCheckbox';
import styles from '../styles';
import toHighlightsString from '../../../../../questionnaire/toHighlightsString';
import toHighlightsString2 from '../../../../../questionnaire/toHighlightsString2';
import {
  cardiovascular,
  endocrine,
  gastrointestinal,
  gynaecological,
  hematological,
  hepatic,
  musculoskeletal,
  neurological,
  other,
  psychiatric,
  pulmonary,
  renal,
  derm,
  infectious,
  liver,
  assist,
  substance,
  ob,
} from './paths';
import toAge from '../../../../../questionnaire/toAge';
import toHeight from '../../../../../questionnaire/toHeight';
import toWeight from '../../../../../questionnaire/toWeight';
import toBMI from '../../../../../questionnaire/toBMI';
import toWeightLbs from '../../../../../questionnaire/toWeightLbs';
import get from 'lodash/get';
import { useValue } from '../ValueContext';
import { additionalcontent } from '../golfCoast/pages/contentlimit'
import MedicalPassportTextArea2 from '../components/MedicalPassportTextArea2';
import toStringWithoutQuestions2 from '../../../../../questionnaire/toStringWithoutQuestions2';
import toHighlightsString4 from '../../../../../questionnaire/toHighlightsString4';

export const ReviewofSystemWrapper = ({ label, value }) => (
  <div
    style={{
      lineHeight: '12px',
      borderLeft: 0,
      borderRight: 0,
      borderTop: 0,
    }}
  >
    <div style={styles.systemrow2}>
      <div
        style={{
          borderRight: '1px solid black',
          paddingLeft: '5px',
          paddingTop: '3px',
          fontSize: '12px',
        }}
      >
        {label}
      </div>
      <div
        style={{
          display: 'flex',
          padding: '1px',
        }}
      >
        <MedicalPassportTextArea2
          value={value}
          style={{ width: '100%'}}
          fontSize={'11px'}
          nobackground
          placeholder={'No'}
        />
      </div>
    </div>
  </div>
);

const Page7extra = () => {
  const { questionnaireAnswers } = useValue();

  function yesOrNo(value) {
    if (value === true) {
      return 'yes';
    } else if (value === false) {
      return 'no';
    }
    return '';
  }
  let patientProblem = get(
    questionnaireAnswers,
    'sections.PreProcedureAssessment.schema.associatedProblem.yes[1].schema.associatedProblem_text2.text',
    ''
  );

  let familyProblem = get(
    questionnaireAnswers,
    'sections.PreProcedureAssessment.schema.associatedProblem.yes[1].schema.associatedProblem_text.text',
    ''
  );

  // allergy
  const allergyList = get(questionnaireAnswers, 'sections.allergies.schema.q1.yes[1].schema.allergy_list.list', []);
  const allergyList2 = get(questionnaireAnswers, 'sections.allergies.schema.q2.yes[0]', false);
  const allergyList3 = get(questionnaireAnswers, 'sections.allergies.schema.q3.yes[0]', false);

  const allergyName = [];
  const reaction = [];

  for (let i = 0; i < allergyList.length; i++) {
    allergyName.push(get(allergyList[i], 'schema.q1_name.text', ''));
    reaction.push(get(allergyList[i], 'schema.q1_reaction.text', ''));
  }

  if (allergyList2) {
    allergyName.push('Latex');
    reaction.push(get(questionnaireAnswers, 'sections.allergies.schema.q2.yes[1].schema.q2_reaction.text', ''));
  }

  if (allergyList3) {
    allergyName.push(get(questionnaireAnswers, 'sections.allergies.schema.q3.yes[1].schema.q3_name.text', ''));
    reaction.push(get(questionnaireAnswers, 'sections.allergies.schema.q3.yes[1].schema.q3_reaction.text', ''));
  }

  function combineArrays(arr1, arr2) {
    let result = '';

    for (let i = 0; i < arr1.length && i < arr2.length; i++) {
      result += arr1[i] + ', ' + arr2[i] + '\n';
    }

    return result;
  }

  const surgery = additionalcontent(toHighlightsString, [['priorSurgeries']], 100);
  const medication = additionalcontent(toHighlightsString, [['medications']], 600);
  const allergy = additionalcontent(toHighlightsString4, [['allergies']], 100);

  const cardiovascularList = additionalcontent(toHighlightsString2, cardiovascular, 100);
  const endocrineList = additionalcontent(toHighlightsString2, endocrine, 100);
  const GIList = additionalcontent(toHighlightsString2, gastrointestinal, 100);
  const hematologicalList = additionalcontent(toHighlightsString2, hematological, 100);
  const muscularSkeletalList = additionalcontent(toHighlightsString2, musculoskeletal, 100);
  const neurologicalList = additionalcontent(toHighlightsString2, neurological, 100);
  const psychiatricList = additionalcontent(toHighlightsString2, psychiatric, 100);
  const pulmonaryList = additionalcontent(toHighlightsString2, pulmonary, 100);
  const renalList = additionalcontent(toHighlightsString2, renal, 100);
  const dermList = additionalcontent(toHighlightsString2, derm, 100);
  const infectiousList = additionalcontent(toHighlightsString2, infectious, 100);
  const liverList = additionalcontent(toHighlightsString2, liver, 100);
  const assistList = additionalcontent(toHighlightsString2, assist, 100);
  const substanceUseList = additionalcontent(toHighlightsString2, substance, 100);
  const obList = additionalcontent(toHighlightsString2, ob, 100);
  
  if ([surgery, medication, allergy, cardiovascularList, endocrineList, GIList, hematologicalList, muscularSkeletalList, neurologicalList, psychiatricList, pulmonaryList, renalList, dermList, infectiousList, liverList, assistList, substanceUseList, obList].some(item => item !== 'No additional info')) {
    return (
        <div id="page7" className="page" style={{ fontFamily: 'Arial', fontSize: '12px' }}>
          <Header pageNumber={7} pageCount={11} />
          <h2>Pre Operative Anesthesia Assessment</h2>
          <div style={styles.twoNotEqualColumns}>
            <div>
              <div style={{ ...styles.twoNotEqualColumns1, border: '1px solid black' }}>
                <div>Planned Procedure</div>
                <MedicalPassportTextArea name="procedure.name" fontSize={12} />
              </div>
              <div style={{ ...styles.sixEqualColumns, borderTop: '0' }}>
                <MedicalPassportField
                  label="Age"
                  name="page5.age"
                  path={['demographicInformation', 'dob']}
                  reducer={toAge}
                  fontSize={12} />
                <MedicalPassportField
                  label="Sex"
                  name="page5.sex"
                  path={['demographicInformation', 'sex']}
                  reducer={toHighlightsString}
                  fontSize={12} />
                <MedicalPassportField
                  label="Ht"
                  name="page5.Ht"
                  path={['demographicInformation', 'bmi']}
                  reducer={toHeight}
                  fontSize={12} />
                <MedicalPassportField
                  label="Wt"
                  name="page5.Wt"
                  path={['demographicInformation', 'bmi']}
                  reducer={toWeightLbs}
                  fontSize={12} />
                <MedicalPassportField
                  label="KG"
                  name="page5.KG"
                  path={['demographicInformation', 'bmi']}
                  reducer={toWeight}
                  fontSize={12} />
                <MedicalPassportField
                  label="BMI"
                  name="page5.BMI"
                  path={['demographicInformation', 'bmi']}
                  reducer={toBMI}
                  fontSize={12} />
              </div>
              <div style={{ fontSize: '.75rem' }}>Printed data provided through online survey:</div>
            </div>
            <div>
              <div
                style={{
                  borderTop: '1px solid black',
                  borderLeft: '1px solid black',
                  borderRight: '1px solid black',
                  fontSize: '.8rem',
                }}
              >
                <MedicalPassportField label="Patient Name" name="procedure.patient.name" fontSize={12} />
                <MedicalPassportField label="DOB" name="page5.DOB" path={['demographicInformation', 'dob']} fontSize={12} />
                <MedicalPassportField label="Physician" name="procedure.physician.name" fontSize={12} />
                <MedicalPassportField label="DOS" name="procedure.serviceTime" fontSize={12} />
              </div>
            </div>
          </div>
            <div>
              <div style={styles.greyBackground}>Review of Systems</div>
              <section style={{}}>
                <div
                  style={{
                    border: '1px solid black',
                    lineHeight: '1.8em',
                    borderLeft: 0,
                    borderRight: 0,
                  }}
                >
                  <ReviewofSystemWrapper label='Cardiovascular' value={cardiovascularList} />
                  <ReviewofSystemWrapper label='Pulmonary' value={pulmonaryList} />
                  <ReviewofSystemWrapper label='Renal' value={renalList} />
                  <ReviewofSystemWrapper label='Hepatic' value={liverList} />
                  <ReviewofSystemWrapper label='Neurological' value={neurologicalList} />
                  <ReviewofSystemWrapper label='Gastrointestinal' value={GIList} />
                  <ReviewofSystemWrapper label='Hematological' value={hematologicalList} />
                  <ReviewofSystemWrapper label='Endocrine / Metabolic' value={endocrineList} />
                  <ReviewofSystemWrapper label='Musculo-skeletal' value={muscularSkeletalList} />
                  <ReviewofSystemWrapper label='Psychiatric' value={psychiatricList} />
                  <ReviewofSystemWrapper label='OB/Gyn' value={obList} />
                  <ReviewofSystemWrapper label='Coronavirus' value={infectiousList} />
                  <ReviewofSystemWrapper label='ENT / Dermatological' value={dermList} />
                  <ReviewofSystemWrapper label='Substance Use' value={substanceUseList} />
                  <ReviewofSystemWrapper label='Other MRSA, glasses, contacts, assistive devices' value={assistList} />
                  <ReviewofSystemWrapper label='Allergies' value={allergy} />
                  <ReviewofSystemWrapper label='Current Medications' value={medication} />
                  <ReviewofSystemWrapper label='Previous Surgical History' value={surgery} />
                </div>
              </section>
            </div>

        </div>
      );
    }
    else {
        return ''
    };
  }
  
  

export default Page7extra;
