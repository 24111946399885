import React, { useEffect, useMemo, useState } from 'react';
import { createStore } from 'zustand';
import SelectionContext from './SelectionContext';

interface StoreState {
  selection: Record<string, boolean | undefined>;
  setSelection: (key: string, next: boolean) => void;
}

const SelectionContextProvider = ({ children }) => {
  const value = useMemo<SelectionContext>(() => {
    const store = createStore<StoreState>((set, get) => ({
      selection: {},
      setSelection: (key: string, next: boolean) => set({ selection: { ...get().selection, [key]: next } }),
    }));

    const useSelected = () => {
      const [selected, setSelected] = useState<string[]>(
        Object.keys(store.getState().selection).filter(key => store.getState().selection[key])
      );

      useEffect(
        () => store.subscribe(({ selection }) => setSelected(Object.keys(selection).filter(key => selection[key]))),
        []
      );

      return selected;
    };

    const useSelection = (key: string) => {
      const [selection, setSelection] = useState<boolean>(store.getState().selection[key] || false);

      useEffect(() => store.subscribe(({ selection }) => setSelection(selection[key] || false)), []);

      useEffect(() => () => store.getState().setSelection(key, false), []);

      return [selection, (next: boolean) => store.getState().setSelection(key, next)] as const;
    };

    return { useSelected, useSelection };
  }, []);

  return <SelectionContext.Provider value={value}>{children}</SelectionContext.Provider>;
};

export default SelectionContextProvider;
