import { createContext, useContext } from 'react';

interface SelectionContext {
  useSelected: () => string[];
  useSelection: (key: string) => readonly [boolean, (next: boolean) => void];
}

const SelectionContext = createContext<SelectionContext | undefined>(undefined);

export default SelectionContext;
