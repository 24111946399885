import React, { useContext } from 'react';
import { Checkbox, TableCell, TableRow } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import SelectionContext from '../../../features/selection/SelectionContext';

const useStyles = makeStyles({
  tableRow: {
    cursor: 'pointer',
  },
});

const Row = React.forwardRef(
  (
    {
      history,
      location,
      columns,
      item,
      context,
      showUrlProvider,
      idProvider,
      onClick,
      highlighted,
      highlightedRowStyles,
    },
    ref
  ) => {
    const classes = useStyles();
    const theme = useTheme();
    const selectionContext = useContext(SelectionContext);
    return (
      <TableRow ref={ref} hover className={classes.tableRow} style={highlightedRowStyles}>
        {selectionContext && (
          <Selection context={selectionContext} item={item} idProvider={idProvider} className={classes.tableCell} />
        )}
        {columns.map(({ lens, Component, interactive, style, useThemeBackgroundColor }, j) => (
          <TableCell
            key={j}
            className="cell"
            style={
              useThemeBackgroundColor
                ? {
                    whiteSpace: 'nowrap',
                    ...style,
                    backgroundColor: theme.palette.background.default,
                  }
                : {
                    whiteSpace: 'nowrap',
                    ...style,
                  }
            }
            onClick={
              showUrlProvider
                ? () =>
                    history.push({
                      pathname: showUrlProvider(idProvider(item)),
                      search: location.search,
                    })
                : onClick
            }
          >
            <Component data={lens(item, context)} onClick={onClick} />
          </TableCell>
        ))}
      </TableRow>
    );
  }
);

export default Row;

const Selection = ({ context, item, idProvider, className }) => {
  const [checked, setChecked] = context.useSelection(idProvider(item));

  return (
    <TableCell className={className}>
      <Checkbox checked={checked} onChange={e => setChecked(e.target.checked)} />
    </TableCell>
  );
};
