import React from 'react';
import styles from '../../styles';
import MedicalPassportField from '../../components/MedicalPassportField';
import MedicalPassportTextArea from '../../components/MedicalPassportTextArea';
import getAgeAndBmi from './pageUtils';
import toAge from '../../../../../../questionnaire/toAge';
import toSex from '../../../../../../questionnaire/toSex';
import toHighlightsString from '../../../../../../questionnaire/toHighlightsString';
import Barcode from '../../../Barcode';
import toHighlightsString4 from '../../../../../../questionnaire/toHighlightsString4';
import MedicalPassportTextArea2 from '../../components/MedicalPassportTextArea2';
import { additionalcontent } from '../../golfCoast/pages/contentlimit'

const Page4extra = ({ formName, pageNumber, showQRCode, hospitalName, value, defaultValue }) => {
    const providerId = defaultValue?.procedure?.patient?.providerId;
    const { age, bmi } = getAgeAndBmi(
        defaultValue?.demographicInformation?.dob,
        defaultValue?.demographicInformation?.bmi
    );

    const surgery = additionalcontent(toHighlightsString, [['priorSurgeries']], 70);
    const allergy = additionalcontent(toHighlightsString4, [['allergies']], 200);
    const medication = additionalcontent(toHighlightsString, [['medications']], 70);
    const anesthesia = additionalcontent(toHighlightsString4, [['anesthesia complications']], 100);

    if (surgery == 'No additional info' && allergy == 'No additional info' && medication == 'No additional info' && anesthesia == 'No additional info') {
        return ''
    } else {
        return (
            <div id="page4extra" className="page" style={{ fontSize: '11px', fontFamily: 'Arial' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '0.2px' }}>
                        <h2>ANESTHESIA ASSESSMENT </h2>
                        <div>{hospitalName || ''}</div>
                    </div>
                    <div>
                        {showQRCode && (
                            <Barcode
                                formName={formName}
                                pageNumber={pageNumber}
                                providerId={defaultValue?.procedure?.patient?.providerId}
                                serviceTime={defaultValue?.procedure?.serviceTime}
                            />
                        )}
                    </div>
                </div>
                <div style={{ border: '1px solid black', borderBottom: 0, borderLeft: 0 }}>
                    <div
                        style={{
                            display: 'grid',
                            gridTemplateColumns: '55% 45%',
                            borderBottom: '1px solid black',
                        }}
                    >
                        <div>
                            <div
                                style={{
                                    border: '1px solid black',
                                    borderTop: 0,
                                    minHeight: '60px',
                                    height: '100%',
                                    padding: '1px 2px 0 0',
                                    borderBottom: '0px',
                                }}
                            >
                                <div
                                    style={{
                                        paddingBottom: '35px',
                                    }}
                                >
                                    <MedicalPassportTextArea name="procedure.name" label="Procedure" fontSize={12} />
                                </div>
                                <div
                                    style={{
                                        border: '1px solid black',
                                        padding: '2px 1px 0 0',
                                        borderBottom: 0,
                                        borderRight: 0,
                                        borderLeft: 0,
                                    }}
                                >
                                    <MedicalPassportField
                                        name="page4extra.activityLevel"
                                        label="Activity Level"
                                        path={['basicscreening', 'level of physical activity']}
                                        reducer={toHighlightsString}
                                        fontSize={12}
                                    />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div style={{ bottom: '5px', paddingLeft: '3px' }}>
                                <div style={{ marginBottom: '2px' }}>
                                    <MedicalPassportField name="procedure.patient.name" label="Patient" fontWeight={'bold'} fontSize={14} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '2px' }}>
                                    <MedicalPassportField name="dob" label="DOB" path={['demographicInformation', 'dob']} fontSize={12} />
                                    <MedicalPassportField name="procedure.serviceTime" label="DOS" fontWeight={700} fontSize={12} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '2px' }}>
                                    <MedicalPassportField name="procedure.patient.providerId" label="MRN#" fontSize={12} />
                                    <MedicalPassportField name="procedure.physician.name" label="Surgeon" fontSize={12} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '2px', paddingBottom: '1px' }}>
                                    <MedicalPassportField
                                        name="age"
                                        label="Age"
                                        path={['demographicInformation', 'dob']}
                                        reducer={toAge}
                                        warning={age > 70}
                                        fontSize={12} />
                                    <MedicalPassportField name="sex" label="Sex" path={['demographicInformation', 'sex']} reducer={toSex} fontSize={12} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '2px', paddingBottom: '1px' }}>
                                    <MedicalPassportField
                                        name="cardiologist"
                                        label="Cardiologist"
                                        path={['demographicInformation', 'cardiologist']}
                                        fontSize={12} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '2px', paddingBottom: '1px' }}>
                                    <MedicalPassportField
                                        name="procedure.pcp"
                                        path={['demographicInformation', 'primaryCareDoctor']}
                                        label="PCP"
                                        fontSize={12} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        style={{
                            borderLeft: '1px solid black',
                            borderBottom: '1px solid black',
                        }}
                    >
                        <MedicalPassportTextArea2
                            label="Med Allergies/Sensitivities"
                            name="allergies1"
                            value={allergy}
                            warning={true}
                            fontSize={12} />
                    </div>
                    <div style={styles.medicationsInnerDiv}>
                        <MedicalPassportTextArea2
                            label="Current Medications"
                            name="medications1"
                            value={medication}
                            fontSize={12}
                        />
                    </div>
                    <div style={{ ...styles.medicationsInnerDiv }}>
                        <MedicalPassportTextArea2
                            label="Surgical/Anesthesia Hx"
                            name="anesthesia1"
                            value={surgery}
                            fontSize={12}
                        />
                    </div>
                </div>
                <div style={{ borderLeft: '1px solid black', borderRight: '1px solid black' }}>
                    <MedicalPassportTextArea2
                        label={'Hx of Anesthesia Complications'}
                        name="page4extra.hxAnesthesiaComplications"
                        value={anesthesia}
                        fontSize={12}
                    />
                </div>
            </div>
        );
    };
}


export default Page4extra;
