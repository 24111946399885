import StaffShift from './StaffShift';
import { StaffAvailability } from './StaffAvailability';
import ScheduleUser from './ScheduleUser';
import Entity from './Entity';
import { RoomType } from './Room';

export enum PrimarySpecialty {
  PreOp = 'PRE_OP',
  PACU = 'PACU',
  OR = 'OR',
  Sedation = 'SEDATION',
}

export const PrimarySpecialtyLabel = {
  [PrimarySpecialty.PreOp]: 'Pre Op',
  [PrimarySpecialty.PACU]: 'PACU',
  [PrimarySpecialty.OR]: 'OR',
  [PrimarySpecialty.Sedation]: 'Sedation',
};

export const PrimarySpecialtyColors = {
  [PrimarySpecialty.PreOp]: '#7ED321',
  [PrimarySpecialty.PACU]: '#B057FF',
  [PrimarySpecialty.OR]: '#F55323',
  [PrimarySpecialty.Sedation]: '#F8E71C',
};

export enum Employment {
  FullTime = 'FULL_TIME',
  PartTime = 'PART_TIME',
  PerDiem = 'PER_DIEM',
}

export const EmploymentLabel = {
  [Employment.FullTime]: 'Full Time',
  [Employment.PartTime]: 'Part Time',
  [Employment.PerDiem]: 'Per Diem',
};

export enum Compensation {
  Hourly = 'HOURLY',
  Annual = 'ANNUAL',
}

export const CompensationLabel = {
  [Compensation.Annual]: 'Annual',
  [Compensation.Hourly]: 'Hourly',
};

export enum OtherSpecialty {
  PreOp = 'PRE_OP',
  PACU = 'PACU',
  OR = 'OR',
  Sedation = 'SEDATION',
  Hours23 = 'HOURS23_STAY',
  OrthoSportMed = 'ORTHO_SPORTMED',
  OrthoJoint = 'ORTHO_JOINT',
  Spine = 'SPINE',
  Urology = 'UROLOGY',
  Gi = 'GI',
  Cv = 'CV',
  Ophthalmology = 'OPHTHALMOLOGY',
  Pain = 'PAIN',
  EntPlastic = 'ENT_PLASTIC',
}

export const OtherSpecialtyLabel = {
  [OtherSpecialty.PreOp]: 'Pre Op',
  [OtherSpecialty.PACU]: 'PACU',
  [OtherSpecialty.OR]: 'OR',
  [OtherSpecialty.Sedation]: 'Sedation',
  [OtherSpecialty.Hours23]: '23 Hours Stay',
  [OtherSpecialty.OrthoSportMed]: 'Ortho: SportMed / Gen',
  [OtherSpecialty.OrthoJoint]: 'Ortho: Joint',
  [OtherSpecialty.Spine]: 'Spine',
  [OtherSpecialty.Urology]: 'Urology',
  [OtherSpecialty.Gi]: 'GI',
  [OtherSpecialty.Cv]: 'CV',
  [OtherSpecialty.Ophthalmology]: 'Ophthalmology',
  [OtherSpecialty.Pain]: 'Pain',
  [OtherSpecialty.EntPlastic]: 'ENT / Plastic',
};

export type Title = string;

export default interface StaffMember extends Entity, ScheduleUser {
  staffId: number;
  hstId: string;
  phoneNumber?: string | null;
  email?: string | null;
  primarySpecialty?: PrimarySpecialty | null;
  otherSpecialties?: OtherSpecialty[] | null;
  title?: string | null;
  titleAbbr?: string | null;
  titleJson?: string | null;
  employment?: Employment | null;
  compensation?: Compensation | null;
  assignedShift?: StaffShift;
  availability?: StaffAvailability[];
  additionalText?: string;
  hourlyRate?: number;
}

export function primarySpecialtyForRoomType(roomType: RoomType): PrimarySpecialty | undefined {
  switch (roomType) {
    case RoomType.WaitingRoom:
      return undefined;
    case RoomType.PreOp:
      return PrimarySpecialty.PreOp;
    case RoomType.OR:
      return PrimarySpecialty.OR;
    case RoomType.POST_OP:
      return PrimarySpecialty.PACU;
    case RoomType.PACU:
      return PrimarySpecialty.PACU;
    case RoomType.Utility:
      return PrimarySpecialty.OR;
    case RoomType.ANESTHESIOLOGIST:
      return PrimarySpecialty.Sedation;
    default:
      return undefined;
  }
}
