import React from 'react';
import styles from '../../styles';
import MedicalPassportField from '../../components/MedicalPassportField';
import MedicalPassportCheckbox from '../../components/MedicalPassportCheckbox';
import toAge from '../../../../../../questionnaire/toAge';
import toSex from '../../../../../../questionnaire/toSex';
import toWeight from '../../../../../../questionnaire/toWeight';
import getAgeAndBmi from './pageUtils';
import { testReducer } from '../../../../../../questionnaire/fallRiskReducers';
import { useValue } from '../../ValueContext';
import get from 'lodash/get';
import toUsageString from '../../../../../../questionnaire/toUsageString';
import toHighlightsString2 from '../../../../../../questionnaire/toHighlightsString2';
import MedicalPassportAllergiesRow2 from '../../components/MedicalPassportAllergiesRow2';
import MedicalPassportTextArea2 from '../../components/MedicalPassportTextArea2';
import toStringWithoutQuestions2 from '../../../../../../questionnaire/toStringWithoutQuestions2';
import Barcode from '../../../Barcode';

const activityAssessment = [
  {
    label: 'Ambulatory',
    name: 'ambulatory',
  },
  {
    label: 'Ambulatory w/ assistance',
    name: 'ambulatoryWithAssistance',
  },
];

const assistiveDevice = [
  {
    label: 'Crutches',
    name: 'crutches',
  },
  {
    label: 'Walker',
    name: 'walker',
  },
  {
    label: 'Wheelchair',
    name: 'wheelchair',
  },
  {
    label: 'Cane',
    name: 'cane',
  },
];

const CommonPartOne = () => (
  <div>
    <div style={{ ...styles.darkGreyBackground, fontSize: '12px', marginTop: '0px' }}>NURSING NOTES</div>
    {[...Array(5)].map((e, index) => (
      <div style={{ ...styles.sectionEmptyRow, padding: '8.5px', borderTop: '0' }} key={'note' + index}>
        {''}
      </div>
    ))}
  </div>
);

export const CommonPartP5P6 = ({ providerId, showQRCode, age }) => (
  <div style={{ ...styles.border, position: 'relative', lineHeight: '15px', padding: '5px', fontSize: '12px' }}>
    <div>
      <div>
        <MedicalPassportField
          name="procedure.patient.name"
          label="Patient"
          fontWeight={'bold'}
          fontSize={14}
          nobackground
        />
      </div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <MedicalPassportField
          name="dob"
          label="DOB"
          path={['demographicInformation', 'dob']}
          fontSize={12}
          nobackground
        />
        <MedicalPassportField name="procedure.serviceTime" label="DOS" fontWeight={700} fontSize={12} nobackground />
      </div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <MedicalPassportField name="procedure.patient.providerId" label="MRN#" fontSize={12} nobackground />
        <MedicalPassportField name="procedure.physician.name" label="Surgeon" fontSize={12} nobackground />
      </div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <MedicalPassportField
          name="age"
          label="Age"
          path={['demographicInformation', 'dob']}
          reducer={toAge}
          warning={age > 70}
          nobackground
          fontSize={12}
        />
        <MedicalPassportField
          name="sex"
          label="Sex"
          path={['demographicInformation', 'sex']}
          reducer={toSex}
          fontSize={12}
          nobackground
        />
      </div>
    </div>
  </div>
);

const Page7 = ({ formName, pageNumber, showQRCode, hospitalName, value, defaultValue }) => {
  const providerId = defaultValue?.procedure?.patient?.providerId;
  const { age } = getAgeAndBmi(defaultValue?.demographicInformation?.dob, defaultValue?.demographicInformation?.bmi);
  const { questionnaireAnswers } = useValue();

  const rideEmgergencySame = get(
    questionnaireAnswers,
    'sections.contactinformation.schema.ridehomeinformation.yes[1].schema.sameEmergency.yes[0]'
  );
  let riderName = '';
  let riderRelationship = '';
  let riderPhone = '';
  let riderOtherPhone = '';
  if (rideEmgergencySame == false) {
    riderName = get(
      questionnaireAnswers,
      'sections.contactinformation.schema.ridehomeinformation.yes[1].schema.sameEmergency.yes[1].schema.RH_name.text'
    );
    riderRelationship = get(
      questionnaireAnswers,
      'sections.contactinformation.schema.ridehomeinformation.yes[1].schema.sameEmergency.yes[1].schema.RH_relationship.option'
    );
    riderPhone = get(
      questionnaireAnswers,
      'sections.contactinformation.schema.ridehomeinformation.yes[1].schema.sameEmergency.yes[1].schema.RH_phone.text'
    );
    riderOtherPhone = get(
      questionnaireAnswers,
      'sections.contactinformation.schema.ridehomeinformation.yes[1].schema.sameEmergency.yes[1].schema.RH_otherphone.text'
    );
  } else {
    riderName = get(
      questionnaireAnswers,
      'sections.contactinformation.schema.familyEmergency.yes[1].schema.FE_name.text'
    );
    riderRelationship = get(
      questionnaireAnswers,
      'sections.contactinformation.schema.familyEmergency.yes[1].schema.FE_relationship.option'
    );
    riderPhone = get(
      questionnaireAnswers,
      'sections.contactinformation.schema.familyEmergency.yes[1].schema.FE_MobilePhone.text'
    );
    riderOtherPhone = get(
      questionnaireAnswers,
      'sections.contactinformation.schema.familyEmergency.yes[1].schema.FE_HomePhone.text'
    );
  }

  function ridehomeReducer1() {
    const rideHomeYesNo = get(
      questionnaireAnswers,
      'sections.contactinformation.schema.ridehomeinformation.yes[1].schema.RH_consenst1.yes[0]',
      null
    );
    if (rideHomeYesNo === true) {
      return true;
    }
    return false;
  }
  function ridehomeReducer2() {
    const rideHomeYesNo = get(
      questionnaireAnswers,
      'sections.contactinformation.schema.ridehomeinformation.yes[1].schema.RH_consenst1.yes[0]',
      null
    );
    if (rideHomeYesNo === false) {
      return true;
    }
    return false;
  }

  const language = get(questionnaireAnswers, 'sections.demographicInformation.schema["primary language"].option', '');
  function primaryLanguageEnglish(value) {
    if (value === 'English') {
      return true;
    }
    return false;
  }
  function primaryLanguageSpanish(value) {
    if (value === 'Spanish') {
      return true;
    }
    return false;
  }
  function primaryLanguagePolish(value) {
    if (value === 'Polish') {
      return true;
    }
    return false;
  }
  function primaryLanguageOther(value) {
    if (!primaryLanguageEnglish(value) && !primaryLanguageSpanish(value) && !primaryLanguagePolish(value)) {
      return value;
    }
    return '';
  }

  return (
    <div id="page7" className="page" style={{ height: '100%', paddingBottom: '0', fontFamily: 'Arial' }}>
      <div style={{ minHeight: 'rem', paddingBottom: '0' }}>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '50% 50%',
            gridGap: '5px',
            alignContent: 'start',
            marginBottom: '10px',
            fontSize: '13px',
          }}
        >
          <div style={styles.div}>
            <b>
              <div style={{ fontSize: '12px', paddingBottom: '0', lineHeight: '13px' }}>
                {(hospitalName || '').toUpperCase()}
              </div>
              <div style={{ fontSize: '20px', paddingBottom: '0' }}>Nursing Assessment</div>
            </b>
            <div style={{ fontSize: '12px' }}>
              <MedicalPassportTextArea2
                name="procedure.name"
                label="Procedure"
                style={{ display: 'flex' }}
                fontSize={12}
                nobackground
              />
            </div>
          </div>
          <div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '2px' }}>
              {showQRCode && (
                <Barcode
                  formName={formName}
                  pageNumber={pageNumber}
                  providerId={defaultValue?.procedure?.patient?.providerId}
                  serviceTime={defaultValue?.procedure?.serviceTime}
                />
              )}
            </div>
            <CommonPartP5P6 age={age} showQRCode={showQRCode} providerId={providerId} />
          </div>
        </div>
        <div>
          <div style={{ fontWeight: 'bold', borderBottom: '1px solid black', marginBottom: '2px', fontSize: '13px' }}>
            ALLERGIES
          </div>
          <MedicalPassportTextArea2
            name="allergies1"
            path={['allergies']}
            reducer={toStringWithoutQuestions2}
            warning={true}
            fontSize={'13px'}
            style={{ minHeight: '70px' }}
            nobackground
          />
        </div>
        <div style={{ display: 'grid', gridTemplateColumns: '1.2fr 1fr' }}>
          <div
            style={{
              lineHeight: '18px',
              padding: '0 5px',
              fontSize: '12.5px',
            }}
          >
            <div style={{ display: 'flex', gap: '8px' }}>
              <MedicalPassportField name="page5.timeIn" label="Time In" fontSize={'11px'} nobackground underline />
              <MedicalPassportField
                name="page5.pediatricWT"
                label="Pediatric Weight"
                fontSize={'11px'}
                nobackground
                underline
              />
              kg
            </div>

            <div style={{ display: 'flex', gap: '5px' }}>
              <MedicalPassportCheckbox name="page5.H&P" label="H&P, Medications Reviewed" />
            </div>

            <div style={{ display: 'grid', gridTemplateColumns: '23% 11% 13% 50%' }}>
              <div>Blood Thinners:</div>
              <MedicalPassportCheckbox name="page5.bloodThinnerYes" label="Yes" />
              <MedicalPassportCheckbox name="page5.bloodThinnerNo" label="No" />
              <div
                style={{
                  display: 'flex',
                  alignItems: 'baseline',
                }}
              >
                <MedicalPassportField
                  name="page5.bloodThinnerLastTaken"
                  label="Last Taken"
                  fontSize={'11px'}
                  nobackground
                  underline
                />
              </div>
            </div>

            <div style={{ display: 'grid', gridTemplateColumns: '20% 25% 15% 7% 11% 11% 11%' }}>
              <MedicalPassportField name="page5.temp" label="Temp" fontSize={'11px'} nobackground underline />
              <MedicalPassportField name="page5.pulse" label="Pulse" fontSize={'11px'} nobackground underline />
              <div>Reg/Irreg.</div>
              <div>HCG</div>
              <MedicalPassportCheckbox name="page5.hcg.neg" label="Neg" />
              <MedicalPassportCheckbox name="page5.hcg.pos" label="Pos" />
              <MedicalPassportCheckbox name="page5.hcg.na" label="N/A" />
            </div>

            <div style={{ display: 'grid', gridTemplateColumns: '23% 4% 22% 22% 29%' }}>
              <MedicalPassportField name="page5.resp" label="Resp" fontSize={'11px'} nobackground underline />
              <div>O2</div>
              <MedicalPassportField name="page5.o2sat" label="Sat" fontSize={'11px'} nobackground underline />
              <MedicalPassportField name="page5.B/P" label="B/P" fontSize={'11px'} nobackground underline />
              <MedicalPassportField
                name="page5.glucoseField"
                label="Glucose "
                fontSize={'11px'}
                nobackground
                underline
              />
            </div>

            <div style={{ display: 'flex', gap: '5px' }}>
              <div>Hx. Complications previous anesthesia or sedation:</div>
              <MedicalPassportCheckbox name="page5.hx.yes" label="Yes" />
              <MedicalPassportCheckbox name="page5.hx.no" label="No" />
            </div>

            {/* autofill based off of pain section */}
            <div style={{ display: 'flex', gap: '5px' }}>
              <div>Are you having any pain or discomfort:</div>
              <MedicalPassportCheckbox name="page5.pain.yes" label="Yes" path={['Pain', 'chronic pain']} />
              <MedicalPassportCheckbox name="page5.pain.no" label="No" />
            </div>

            <div style={{ display: 'grid', gridTemplateColumns: '19% 24% 5% 50%' }}>
              <div>Pain Scale: 0</div>
              <MedicalPassportField
                name="page5.painScale"
                label=""
                path={['Pain', 'chronic pain', 'yes', 'level']}
                fontSize={'11px'}
                nobackground
                underline
                reducer={toUsageString}
              />
              <div>10</div>
              <MedicalPassportField
                name="page5.painLocation"
                label="Location"
                path={['Pain', 'chronic pain', 'yes', 'painCmt']}
                fontSize={'11px'}
                nobackground
                underline
              />
            </div>

            <div style={{ display: 'grid', gridTemplateColumns: '3% 10.3% 26.5% 27% 55%' }}>
              <div>IV:</div>
              <MedicalPassportCheckbox name="page5.ivNA" label="N/A" />
              <MedicalPassportCheckbox name="page5.lr" label="LR@TKO Rate" />
              <MedicalPassportCheckbox name="page5.ns" label="NS@TKO Rate" />
              <div
                style={{
                  display: 'flex',
                  alignItems: 'baseline',
                }}
              >
                <MedicalPassportCheckbox name="page5.other3" label="Other: " />
                <div>
                  <input
                    type="text"
                    name="handoff"
                    style={{
                      ...styles.inputField,
                      width: '55px',
                      backgroundColor: 'none',
                      borderBottom: '1px solid black',
                    }}
                  />
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', gap: '5px' }}>
              <div>Cath:</div>
              <MedicalPassportCheckbox name="page5.22g" label="22G" />
              <MedicalPassportCheckbox name="page5.20g" label="20G" />
              <div
                style={{
                  display: 'flex',
                  alignItems: 'baseline',
                }}
              >
                <MedicalPassportField
                  name="page5.insert.CathText"
                  label="Inserted by"
                  fontSize={'11px'}
                  nobackground
                  underline
                />
              </div>
            </div>
            <div style={{ display: 'flex' }}>
              <div>Site:</div>
              <MedicalPassportCheckbox name="page5.right" label="Right" />
              <MedicalPassportCheckbox name="page5.left" label="Left" />
              <MedicalPassportCheckbox name="page5.hand" label="Hand" />
              <MedicalPassportCheckbox name="page5.forearm" label="Forearm" />
              <MedicalPassportCheckbox name="page5.anticubital" label="Anticubital" />
            </div>
            <div style={{ display: 'grid', gridTemplateColumns: '16% 10% 11% 10% 10% 10% 33%', fontSize: '10px' }}>
              <div>Saline Lock:</div>
              <MedicalPassportCheckbox name="page5.saline.NA" label="N/A" />
              <MedicalPassportCheckbox name="page5.saline.right" label="Right" />
              <MedicalPassportCheckbox name="page5.saline.left" label="Left" />
              <MedicalPassportCheckbox name="page5.saline.20G" label="20G" />
              <MedicalPassportCheckbox name="page5.saline.22G" label="22G" />
              <MedicalPassportField
                name="page5.inserted"
                label="Inserted by"
                fontSize={'11px'}
                nobackground
                underline
              />
            </div>
          </div>
          <div>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '6fr 1fr 1fr 1fr 3fr',
                fontWeight: 'bold',
                border: '1px solid black',
                borderTop: 0,
                borderBottom: 0,
                lineHeight: '12px',
                padding: '0 2px',
                fontSize: '12.5px',
                borderLeft: '0',
              }}
            >
              <div style={{ borderRight: '1px solid black' }}>&nbsp;</div>
              <div style={{ borderRight: '1px solid black', padding: '0 1px' }}>Yes</div>
              <div style={{ borderRight: '1px solid black', padding: '0 1px' }}>No</div>
              <div style={{ borderRight: '1px solid black', padding: '0 1px' }}>N/A</div>
              <div style={{ padding: '0 1px' }}>Comments:</div>
            </div>
            <div style={{ fontSize: '11px' }}>
              <MedicalPassportAllergiesRow2 text="Loose Teeth/Dentures/Crowns" name="page5.capsCrowns" />
            </div>
            <div style={{ fontSize: '11px' }}>
              <MedicalPassportAllergiesRow2 text="Glasses/Contacts" name="page5.glasses" />
            </div>
            <div style={{ fontSize: '11px' }}>
              <MedicalPassportAllergiesRow2 text="Hearing Aids (in place) L   R" name="page5.hearingAidsIn" />
            </div>
            <div style={{ fontSize: '11px' }}>
              <MedicalPassportAllergiesRow2 text="Hearing Aids (removed) L   R" name="page5.hearingAidsRemove" />
            </div>
            <div style={{ fontSize: '11px' }}>
              <MedicalPassportAllergiesRow2 text="Piercings/Implants/Prosthesis" name="page5.implant" />
            </div>
            <div style={{ borderBottom: '1px solid black', fontSize: '11px' }}>
              <MedicalPassportAllergiesRow2 text="Valuables" name="page5.valuable" />
            </div>
            <div style={{ fontSize: '11.5px', lineHeight: '16px', marginTop: '2px' }}>
              <MedicalPassportField name="npoTime" label="NPO Time/Date" fontSize={'11px'} nobackground underline />
              <MedicalPassportField
                name="medsTakenToday"
                label="Meds Taken Today"
                fontSize={'11px'}
                nobackground
                underline
              />
              <div style={{ display: 'grid', gridTemplateColumns: '1.8fr 1.1fr 1.2fr 1fr 1fr 1fr', fontSize: '11px' }}>
                <div>Primary Language</div>
                <div>
                  <input
                    type="checkbox"
                    name="page5.english"
                    label="English"
                    checked={primaryLanguageEnglish(language)}
                  />
                  English
                </div>
                <div>
                  <input
                    type="checkbox"
                    name="page5.spanish"
                    label="Spanish"
                    checked={primaryLanguageSpanish(language)}
                  />
                  Spanish
                </div>
                <div>
                  <input type="checkbox" name="page5.polish" label="Polish" checked={primaryLanguagePolish(language)} />
                  Polish
                </div>
                <div>
                  <input
                    type="checkbox"
                    name="page5.language.other"
                    label="Other"
                    checked={primaryLanguageOther(language)}
                  />
                  Other:
                </div>
                <MedicalPassportField
                  name="page5.other.field"
                  value={primaryLanguageOther(language)}
                  fontSize={'11px'}
                  nobackground
                  underline
                />
              </div>
              <div style={{ display: 'flex', gap: '4px' }}>
                <div>Interpreter needed?</div>
                <MedicalPassportCheckbox
                  name="page5.interpreterNeededYes"
                  path={['demographicInformation', 'interpreter']}
                  label="Yes"
                />
                <MedicalPassportCheckbox name="page5.interpreterNeededNo" label="N/A" />
              </div>
            </div>
          </div>
        </div>
        <div style={{ fontSize: '11px' }}>
          <div style={{ borderBottom: '1px solid black' }}>
            <div
              style={{
                display: 'inline-flex',
                alignItems: 'center',
                marginRight: '81px',
                marginLeft: '3px',
                fontWeight: 'bold',
              }}
            >
              SCDs Required
            </div>
            <MedicalPassportCheckbox
              name="page5.scd.Yes"
              label="Yes"
              key="page5.scd.Yes"
              style={{ display: 'inline-flex', marginRight: '123px' }}
            />
            <MedicalPassportCheckbox
              name="page5.scd.No"
              label="No"
              key="page5.scd.No"
              style={{ display: 'inline-flex' }}
            />
          </div>

          <div style={{ borderBottom: '1px solid black' }}>
            <div
              style={{
                display: 'inline-flex',
                alignItems: 'center',
                marginRight: '35px',
                marginLeft: '3px',
                fontWeight: 'bold',
              }}
            >
              Level of Consciousness
            </div>
            <MedicalPassportCheckbox
              name="page5.alertOriented"
              label="Alert/Oriented"
              key="page5.alertOriented"
              style={{ display: 'inline-flex', marginRight: '72px' }}
            />
            <MedicalPassportCheckbox
              name="page5.confused"
              label="Confused"
              key="page5.confused"
              style={{ display: 'inline-flex', marginRight: '48px' }}
            />
            <MedicalPassportCheckbox
              name="page5.drowsy"
              label="Drowsy"
              key="page5.drowsy"
              style={{ display: 'inline-flex', marginRight: '30px' }}
            />
            <MedicalPassportCheckbox
              name="page5.sedated"
              label="Sedated"
              key="page5.sedated"
              style={{ display: 'inline-flex' }}
            />
          </div>

          <div style={{ borderBottom: '1px solid black' }}>
            <div
              style={{
                display: 'inline-flex',
                alignItems: 'center',
                marginRight: '71px',
                marginLeft: '3px',
                fontWeight: 'bold',
              }}
            >
              Emotional Status
            </div>
            <MedicalPassportCheckbox
              name="page5.calm"
              label="Calm"
              key="page5.calm"
              style={{ display: 'inline-flex', marginRight: '114px' }}
            />
            <MedicalPassportCheckbox
              name="page5.anxious"
              label="Anxious"
              key="page5.anxious"
              style={{ display: 'inline-flex', marginRight: '55px' }}
            />
            <MedicalPassportCheckbox
              name="page5.agitaded"
              label="Agitaded"
              key="page5.agitaded"
              style={{ display: 'inline-flex', marginRight: '25px' }}
            />
            <MedicalPassportCheckbox
              name="page5.fearful"
              label="Fearful"
              key="page5.fearful"
              style={{ display: 'inline-flex' }}
            />
          </div>

          <div style={{ borderBottom: '1px solid black' }}>
            <div
              style={{
                display: 'inline-flex',
                alignItems: 'center',
                marginRight: '63px',
                marginLeft: '3px',
                fontWeight: 'bold',
              }}
            >
              Respiratory Status
            </div>
            <MedicalPassportCheckbox
              name="page5.clear"
              label="Clear to auscultation"
              key="page5.clear"
              style={{ display: 'inline-flex', marginRight: '39px' }}
            />
            <MedicalPassportCheckbox
              name="page5.spontaneous"
              label="Spontaneous"
              key="page5.spontaneous"
              style={{ display: 'inline-flex', marginRight: '30px' }}
            />
            <MedicalPassportCheckbox
              name="page5.labored"
              label="Labored"
              key="page5.labored"
              style={{ display: 'inline-flex', marginRight: '28px' }}
            />
            <MedicalPassportCheckbox
              name="page5.Nasal"
              label="Nasal/Mask O2"
              key="page5.Nasal"
              style={{ display: 'inline-flex', marginRight: '26px' }}
            />
          </div>

          <div
            style={{
              display: 'inline-flex',
              alignItems: 'center',
              marginRight: '83px',
              marginLeft: '3px',
              fontWeight: 'bold',
            }}
          >
            Skin Condition
          </div>
          <MedicalPassportCheckbox
            name="page5.warmDryIntact"
            label="Warm/dry/intact"
            key="page5.warmDryIntact"
            style={{ display: 'inline-flex', marginRight: '60px' }}
          />
          <MedicalPassportCheckbox
            name="page5.otherSkinCondition"
            label="Other"
            key="page5.otherSkinCondition"
            style={{ display: 'inline-flex' }}
          />
        </div>
        <div style={{ ...styles.darkGreyBackground, fontSize: '12px' }}>ACTIVITY ASSESSMENT</div>
        <div style={{ fontSize: '13px', lineHeight: '18px' }}>
          {activityAssessment.map(e => (
            <MedicalPassportCheckbox
              name={e.name}
              label={e.label}
              key={'assessment' + e.name}
              style={{ display: 'inline-block', marginRight: '8px' }}
            />
          ))}
          {assistiveDevice.map(e => (
            <MedicalPassportCheckbox
              name={e.name}
              label={e.label}
              key={'assessment' + e.name}
              style={{ display: 'inline-block', marginRight: '8px' }}
              path={['basicscreening', 'device', 'yes', 'Checkboxes']}
              reducer={testReducer(e.name.toLowerCase())}
            />
          ))}
        </div>
        <div style={{ ...styles.darkGreyBackground, fontSize: '12px', marginTop: '3px' }}>PREOPERATIVE MEDICATIONS</div>
        <div style={{ fontSize: '13px' }}>
          <MedicalPassportCheckbox name="page5.ophthalmologySheet" label="See Ophthalmology Med Sheet" />
        </div>
        <div style={{ fontSize: '13px' }}>
          <div style={styles.fiveColumns}>
            <div>
              <b>MEDICATION</b>
            </div>
            <div>
              <b>DOSE</b>
            </div>
            <div>
              <b>ROUTE</b>
            </div>
            <div>
              <b>TIME</b>
            </div>
            <div>
              <b>INITIALS</b>
            </div>
          </div>
          {[...Array(6)].map((e, i) => (
            <div style={{ ...styles.fiveColumns, lineHeight: '17px' }} key={'medication' + i}>
              <MedicalPassportField
                name={`medication${i}`}
                label={`${i + 1}`}
                fontSize={'13px'}
                nobackground
                underline
              />
              <MedicalPassportField name={`dose${i}`} fontSize={'13px'} nobackground underline />
              <MedicalPassportField name={`route${i}`} fontSize={'13px'} nobackground underline />
              <MedicalPassportField name={`time${i}`} fontSize={'13px'} nobackground underline />
              <MedicalPassportField name={`initial${i}`} fontSize={'13px'} nobackground underline />
            </div>
          ))}
        </div>
        <div style={{ ...styles.darkGreyBackground, fontSize: '12px', marginTop: '3px' }}>
          RIDE HOME / CAREGIVER / ADVANCE DIRECTIVE INFORMATION
        </div>
        <div style={{ ...styles.twoEqualColumnsNoBorder, fontSize: '13px' }}>
          <div>Ride Home:</div>
          <div>&nbsp;&nbsp;&nbsp;Ride Home Information:</div>
        </div>
        <div style={{ ...styles.twoEqualColumnsNoBorder, fontSize: '13px' }}>
          <div>
            <div style={{ display: 'flex', marginTop: '15px' }}>
              May this person hear your health information:
              <input type="checkbox" name="page5.healthinfoyes" label="Yes" checked={ridehomeReducer1()} />
              Yes
              <input type="checkbox" name="page5.healthinfono" label="No" checked={ridehomeReducer2()} />
              No
            </div>
            <div style={{ display: 'grid', gridTemplateColumns: '78% 12% 10%', marginBottom: '5px' }}>
              <div>If No, discharge instructions to be given preop </div>
              <MedicalPassportField name="discharge.initials" fontSize={'13px'} nobackground underline />
              <div>(initials)</div>
            </div>
          </div>
          <div style={{ display: 'grid', gridTemplateColumns: '35% 65%', fontSize: '13px' }}>
            <div>
              <MedicalPassportField name="page5.rideHomeName" fontSize={'13px'} value={riderName} nobackground />
              <MedicalPassportField
                name="page5.rideHomeRelationship"
                fontSize={'13px'}
                value={riderRelationship}
                nobackground
              />
              <MedicalPassportField name="page5.rideHomePhone" fontSize={'13px'} value={riderPhone} nobackground />
            </div>
            <div style={{ paddingTop: '5px' }}>
              <br />
              <br />
              <MedicalPassportField
                name="page5.rideotherPhone"
                label="Other Phone"
                fontSize={'13px'}
                value={riderOtherPhone}
                nobackground
              />
            </div>
          </div>
        </div>
        <CommonPartOne />
        <div
          style={{
            display: 'flex',
            spaceBetween: 'justify-content',
          }}
        >
          <div style={{ ...styles.signatureBox1, fontSize: '011.5px', marginBottom: '0' }}>
            <div>
              <div style={{ paddingTop: '15px' }}>_____________</div>
              <div style={{ fontSize: '11px' }}>Initials</div>
            </div>
            <div>
              <div style={{ marginLeft: '15px', marginTop: '15px' }}>_____________________________________________</div>
              <div>Signature</div>
            </div>
          </div>
        </div>
      </div>
      <footer
        style={{
          display: 'grid',
          gridTemplateColumns: '13.25% 14.5% 11.5% 17% 0.05% 14.5% 29%',
          fontSize: '11px',
          height: '20px',
        }}
      >
        <div style={{ paddingTop: '3px' }}>Provided by Patient{'('}</div>
        <MedicalPassportField name="questionnaire.firstSubmittedAt" fontSize={11} nobackground />
        <div style={{ paddingTop: '3px' }}>{')'} Last updated by</div>
        <MedicalPassportField name="procedure.patient.name" fontSize={11} nobackground />
        <div style={{ paddingTop: '3px' }}>{'('}</div>
        <MedicalPassportField name="questionnaire.submittedAt" fontSize={11} nobackground />
        <div>
          {')'}{' '}
          <span style={{ fontSize: '13px', marginLeft: '130px' }}>
            <b>Ospitek, Inc</b>
          </span>
        </div>
      </footer>
    </div>
  );
};

export default Page7;
