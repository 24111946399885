import React, { FC, Fragment, useMemo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { RegularText } from './overview/Procedure';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Typography } from '@material-ui/core';
import { useScope } from '../../../../hooks/useScope';
import get from 'lodash/get';
import scope from '../../../../types/Scope';
import { z } from 'zod';
import { tryParseJson2 } from '../../../../util/parseJson';

const Container = styled.span``;

const Highlighted = styled.span<any>`
  color: ${props => props.color || '#f55323'};
`;

const defaultColors = {
  latex: '#f55323',
  iodine: '#f55323',
  pediatric: '#FF5ABB',
  ekg: '#ee9955',
  minor: '#ee9955',
  knee: '#7ED321',
  hip: '#00A7F7',
};

const useColorCoding = () => {
  const scope = useScope();

  const raw = get(scope, 'hospital.scheduleTermColorCoding');

  return useMemo(() => {
    const result = z.record(z.string()).safeParse(tryParseJson2(raw!, null));

    if (result.success) {
      return result.data;
    }

    return defaultColors;
  }, [raw]);
};

const HighlightedText: FC<{ highContrast?: any; children: any }> = ({ children, highContrast }) => {
  const classes = useStyles();
  const colorCoding = useColorCoding();
  const theme = useTheme();

  const result = useMemo(() => {
    if (typeof children !== 'string') {
      return children;
    }

    const tokens: string[] = Object.keys(colorCoding);

    const tokenAtIdx = (children: any, idx: any) => {
      const rest = children.slice(idx);
      return tokens.find(token => rest.match(new RegExp(`^${token}\\b`, 'gi')));
    };

    const indexes = [...children.matchAll(new RegExp(`\\b(${tokens.join('|')})\\b`, 'gi'))].map(match => match.index);

    var lastIndex = indexes.length === 0 ? children.length : 0;
    // @ts-ignore
    var result =
      indexes.length === 0 ? (
        <Typography
          className={clsx(classes.regularText, {
            [classes.highContrast]: highContrast,
            [classes.warning]:
              colorCoding === defaultColors && (children.includes('latex') || children.includes('iodine')),
            [classes.panic]: colorCoding === defaultColors && children.includes('pediatric'),
          })}
        >
          {children}
        </Typography>
      ) : null;

    const appendToResult = (value: any) => {
      result = (
        <Fragment>
          {result}
          {value}
        </Fragment>
      );
    };

    for (var i = 0, n = indexes.length; i < n; i++) {
      const index = indexes[i]!;

      if (lastIndex >= children.length) {
        break;
      }

      if (lastIndex < index) {
        appendToResult(<RegularText theme={theme}>{children.slice(lastIndex, index)}</RegularText>);
      }

      const token = tokenAtIdx(children, index);
      lastIndex = index + (token || '').length;

      appendToResult(
        <Highlighted color={token && (colorCoding as any)[token]}>{children.slice(index, lastIndex)}</Highlighted>
      );
    }

    if (lastIndex < children.length) {
      appendToResult(<RegularText theme={theme}>{children.slice(lastIndex, children.length)}</RegularText>);
    }

    return result;
  }, [children, colorCoding]);

  return <Container>{result}</Container>;
};

HighlightedText.defaultProps = {
  children: '',
};

HighlightedText.propTypes = {
  children: PropTypes.string.isRequired,
};

const useStyles = makeStyles(theme => ({
  regularText: {
    opacity: 0.7,
    lineHeight: 1.2,
    fontSize: 'inherit',
    color: theme.palette.text.primary,
    [theme.breakpoints.down('lg')]: {
      fontSize: '.75em',
    },
  },
  highContrast: {
    opacity: 1,
  },
  warning: {
    color: '#f55323',
  },
  panic: {
    color: '#FF5ABB',
  },
}));

export default HighlightedText;
