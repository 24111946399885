import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import React, { forwardRef } from 'react';
import HipaaBadge from './HipaaBadge';

const Copyright = forwardRef((props, ref) => {
  const classes = useStyles();

  return (
    <Box ref={ref} className={classes.wrapper}>
      <HipaaBadge />
      <Typography variant="caption" color="textSecondary">
        ©{new Date().getFullYear()} Ospitek. All Rights Reserved.
      </Typography>
    </Box>
  );
});

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
}));

export default Copyright;
