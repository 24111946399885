import { ApolloClient, useQuery } from '@apollo/client';
import { gql } from '@apollo/client/core';
import { fragments } from '../../../graph/procedureTypes';
import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { Source } from '../../../components/OmniSearch';
import * as H from 'history';

interface ProcedureType {
  id: number;
  name: string;
  expectedPreOpDuration?: number;
  expectedDuration?: number;
  expectedPacuDuration?: number;
}

const procedureTypeSource: Source<ProcedureType> = {
  name: 'ProcedureTypes',
  useSource: (input: string, client: ApolloClient<any>) => {
    const { data, loading, error } = useQuery<{ searchProcedureTypes?: ProcedureType[] }, { search: string }>(
      gql`
        query searchProcedureTypes($search: String!) {
          searchProcedureTypes(search: $search) {
            ...ProcedureTypeData
          }
        }
        ${fragments.all}
      `,
      {
        client,
        variables: {
          search: input,
        },
      }
    );

    return {
      data: data?.searchProcedureTypes ?? [],
      loading,
      error,
    };
  },
  noOptionsText: (input: string) =>
    input.length > 0 ? 'No procedure types found.' : 'Start typing to search procedure types.',
  renderOption: (procedureType: ProcedureType, { selected: boolean }) => {
    return (
      <Box py={1} display="flex" alignItems="center">
        <Box>
          <Box display="flex" alignItems="baseline">
            <Typography>{procedureType.name || '-'}</Typography>
          </Box>
        </Box>
      </Box>
    );
  },
  getOptionDisabled: (option: ProcedureType) => false,
  getOptionLabel: (option: ProcedureType) => option?.name ?? '-',
  onSelect: (option: ProcedureType, location: H.Location, history: H.History) => {
    const prefix = (location.pathname.match(/\/su\/(\d+)/) ?? [''])[0];
    history.push(`${prefix}/procedure-types/${option.id}`);
  },
};

export default procedureTypeSource;
