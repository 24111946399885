import React, { useCallback, useEffect, useMemo } from 'react';
import styled, { css } from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import Frame from 'react-frame-component';
import get from 'lodash/get';
import set from 'lodash/set';
import { int as randomInt } from '../../../se/utilities/random/index.jsx';
import Handlebars from 'handlebars';
import { LocalDate, Period } from '@js-joda/core';
import safeEval from 'safe-eval';
import flow from 'lodash/fp/flow';
import update from 'lodash/fp/update';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useHasAccessRight from '../../../hooks/useHasAccessRight';
import clsx from 'clsx';
import NoPermissionText from '../../NoPermissionText';
import { useTheme } from '@material-ui/core/styles';
import { format } from 'date-fns';
import { getBarcodeText, getBarcodeTextSrc } from './Barcode';

import { useScope } from '../../../hooks/useScope';
import { removeSurplus, tryWaitDefined } from './FormIframe';

const convertTimezone = (utcDateString, targetTimezone) => {
  const date = new Date(utcDateString);

  if (!utcDateString || !targetTimezone) {
    return '';
  }

  return date.toLocaleString('en-US', {
    timeZone: targetTimezone,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  });
};

const renderAge = dob => {
  try {
    const [monthStr, dayStr, yearStr] = dob.split('/');
    const month = parseInt(monthStr, 10);
    const day = parseInt(dayStr, 10);
    const year = parseInt(yearStr, 10);
    const date = LocalDate.of(year, month, day);
    return Period.between(date, LocalDate.now()).years();
  } catch (e) {
    return undefined;
  }
};

Handlebars.registerHelper('json', function () {
  return JSON.stringify(this, undefined, 2);
});

Handlebars.registerHelper('eval', function (code) {
  return safeEval(code, this);
});

const transformSomeValues = flow(
  update('demographicInformation', demographicInformation => ({
    ...demographicInformation,
    age: renderAge(demographicInformation?.dob),
  })),
  update('measurments', measurments =>
    Array.isArray(measurments)
      ? (measurments || []).map(e => ({
          ...e,
          time: e.time ? e.time : format(e.timestamp ? new Date(e.timestamp) : new Date(), 'HH:mm'),
          bp: e.bp ? e.bp : `${e?.sys}/${e?.dia}`,
        }))
      : measurments
  ),
  update('DoSMed.ifYes.DoSMedList', meds => (Array.isArray(meds) ? (meds || []).filter(e => e?.transfer) : meds))
);

const pageSize = {
  width: 8.5,
  height: 11,
};

const tabletPageSize = {
  width: 182,
  height: 259.4,
};

const scale = 0.8;

const StyledFrame = styled(Frame)`
  width: 8.5in;
  height: 11in;
  overflow: hidden;
  font-size: ${scale}rem;
`;

function iOS() {
  return (
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
}

const marginX = 10;
const marginY = 10;

export const RootFrame = React.forwardRef(({ children, isTablet, allowPageBreaks }, ref) => {
  useEffect(() => {
    const curr = get(ref, 'current.node');

    if (curr) {
      setTimeout(() => {
        const w = curr?.contentWindow;

        if (!w) {
          return;
        }

        const style = w.getComputedStyle(w.document.body);
        curr.style.width = style.width;
        curr.style.height = style.height;
      }, 50);
    }
  }, [ref]);

  const theme = useTheme();
  const backgroundColor = theme.palette.background.paper;
  const head = useMemo(
    () =>
      ReactHtmlParser(`
    <style>
      html {
        background-color: ${backgroundColor};
      }
      body {
        margin: 0;
      }
      body {
        font-family: sans-serif;
        font-size: 3.9mm;
        line-height: 1.15;
      }
      .page {
        box-sizing: border-box;
        ${
          isTablet
            ? `
                min-height: ${tabletPageSize.height + 'mm'};
              `
            : `
                height: ${pageSize.height + 'in'};
              `
        }
        margin: 0;
        font-size: 1em;
        background: white;
        color: black;
        border: 1px solid rgba(0, 0, 0, 0.3);
        box-shadow: 2px 2px 7px 4px rgba(0, 0, 0, 0.15);
        padding: 2rem;
        overflow: hidden;
      }

      .page + .page {
        margin-top: 1em;
      }

      @media screen {
        .page {
          width: ${isTablet ? tabletPageSize.width + 'mm' : pageSize.width + 'in'};
        }
      }

      @media print {
        .page {
          border: none;
          box-sizing: border-box;
          width: ${pageSize.width}in;
          height: ${pageSize.height}in;
        }
        @page {
          size: ${pageSize.width}in ${pageSize.height}in;
          ${
            allowPageBreaks
              ? `
              margin-top: 2rem;
              margin-bottom: 2rem;
            `
              : `
              margin: 0;
            `
          }
        }
${
  iOS()
    ? `
        body {
          margin-top: -${marginY}mm;
          margin-bottom: -${marginY}mm;
          margin-left: -${marginX}mm;
          margin-right: -${marginX}mm;
        }
      `
    : ``
}

        .page {
          ${iOS() ? `width: calc(${pageSize.width}in + ${2 * marginX}mm);` : `width: ${pageSize.width}in;`}
          ${
            allowPageBreaks
              ? `
              padding-top: 0;
              padding-bottom: 0;
              page-break-after: always;
            `
              : `
              ${iOS() ? `height: calc(${pageSize.height}in + ${2 * marginY}mm);` : `height: ${pageSize.height}in;`}
              margin: 0 !important;
            `
          }
          font-size: 1em;
          box-shadow: none;
        }
      }
    </style>
  `),
    [allowPageBreaks, backgroundColor]
  );

  useEffect(() => {
    const abortController = new AbortController();

    void (async () => {
      const frame = await tryWaitDefined(() => ref.current, 30000);

      if (abortController.signal.aborted) {
        return;
      }

      if (!frame) {
        return;
      }

      const contentDocument = await tryWaitDefined(() => frame.node.contentDocument, 30000);

      if (abortController.signal.aborted) {
        return;
      }

      if (!contentDocument) {
        return;
      }

      const pages = contentDocument.querySelectorAll('.page');

      await Promise.all([...pages].map(page => removeSurplus(page, abortController.signal)));
    })();

    return () => abortController.abort();
  }, []);

  return (
    <StyledFrame ref={ref} head={head} frameBorder="0" scrolling="no">
      {children}
    </StyledFrame>
  );
});

const expandType = value => {
  if (value === 'false') {
    return false;
  } else if (value === 'true') {
    return true;
  } else {
    return value;
  }
};

const useStyles = makeStyles(theme => ({
  content: {
    position: 'relative',
    textAlign: 'center',
    verticalAlign: 'center',
  },
  blurred: {
    backgroundColor: 'white',
    color: theme.palette.background.default,
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 100,
  },
}));

const DatabaseHtmlForm = React.forwardRef(
  (
    {
      showQRCode,
      defaultValue,
      formName,
      pageNumber,
      content,
      value: initialValue,
      setValue,
      signature,
      signedAt,
      editable,
      isTablet,
    },
    ref
  ) => {
    const classes = useStyles();
    const hasAccessRight = useHasAccessRight();
    const isAllowedToView = hasAccessRight('patient.view');

    const value = transformSomeValues(initialValue);
    const scope = useScope();
    const timezone = scope?.hospital?.timezone?.name;

    const onChange = id => e => {
      if (e.target.type === 'checkbox') {
        const newVal = { ...value };
        set(newVal, id, e.target.checked);
        setValue(newVal);
      } else if (e.target.type === 'radio') {
        const newVal = { ...value };
        set(newVal, id, expandType(e.target.value));
        setValue(newVal);
      } else {
        const newVal = { ...value };
        set(newVal, id, e.target.value);
        setValue(newVal);
      }
    };

    const memoizedOnChange = useCallback(onChange, [onChange]);

    const transform = useCallback(
      node => {
        const { type, name } = node;
        if (type === 'tag' && name === 'input') {
          const { id, type, value: inputValue, name } = node.attribs;

          if (type === 'radio') {
            const fieldVal = get(value, node.attribs.name);
            const attrs = { checked: String(fieldVal) === node.attribs.value };
            return (
              <input
                {...node.attribs}
                onChange={memoizedOnChange(node.attribs.name)}
                key={`${node.attribs.name}-${id}`}
                {...attrs}
                disabled={!editable}
              />
            );
          } else if (type === 'checkbox') {
            const fieldVal =
              inputValue !== undefined && inputValue !== null && name
                ? String(get(value, name)) === String(inputValue)
                : get(value, id);
            const attrs = { checked: !!fieldVal };
            return <input {...node.attribs} onChange={memoizedOnChange(id)} key={id} {...attrs} disabled={!editable} />;
          } else {
            if ('signedat' in node.attribs) {
              const signedAtTime = convertTimezone(signedAt, timezone);
              return (
                <input
                  {...node.attribs}
                  onChange={memoizedOnChange(id)}
                  key={id}
                  value={signedAtTime}
                  disabled={!editable}
                />
              );
            } else {
              const fieldVal = get(value, id);
              const attrs = { value: fieldVal !== undefined && fieldVal !== null ? fieldVal : '' };
              return (
                <input {...node.attribs} onChange={memoizedOnChange(id)} key={id} {...attrs} disabled={!editable} />
              );
            }
          }
        } else if (type === 'tag' && name === 'textarea') {
          const { id } = node.attribs;
          return (
            <textarea
              {...node.attribs}
              onChange={memoizedOnChange(id)}
              value={get(value, id)}
              key={id}
              disabled={!editable}
            />
          );
        } else if (
          type === 'tag' &&
          name === 'img' &&
          ('data-signature' in node.attribs ||
            'data-signature-md' in node.attribs ||
            'data-signature-chart' in node.attribs)
        ) {
          const signatureKey =
            node.attribs['data-signature'] || node.attribs['data-signature-md'] || node.attribs['data-signature-chart'];
          const src = signatureKey ? get(value, signatureKey) : signature;
          const alt = signatureKey ? signatureKey : 'patient signature';
          if (src) {
            return <img src={src} {...node.attribs} alt={alt} key={`signature-${randomInt()()}`} />;
          }
        } else if (type === 'tag' && name === 'img' && 'barcode-img' in node.attribs && 'page-number' in node.attribs) {
          const alt = 'barcode';
          const pageNumber = node.attribs['page-number'];
          const providerId = defaultValue?.procedure?.patient?.providerId;
          const serviceTime = defaultValue?.procedure?.serviceTime;
          const src = getBarcodeTextSrc(getBarcodeText(providerId, serviceTime, formName, pageNumber));

          return showQRCode ? <img {...node.attribs} src={src} alt={alt} /> : null;
        }
      },
      [value, memoizedOnChange, signature, editable]
    );

    const renderedContent = useMemo(() => {
      const template = Handlebars.compile(content || '');
      return template(value);
    }, [content, value]);

    const node = useMemo(() => ReactHtmlParser(renderedContent, { transform }), [renderedContent, transform]);

    return (
      <Box className={clsx({ [classes.content]: !isAllowedToView })}>
        {!isAllowedToView && (
          <Box className={clsx({ [classes.blurred]: !isAllowedToView })}>
            <NoPermissionText withText={true} style={{ top: '50%', left: '25%' }} />
          </Box>
        )}
        <RootFrame ref={ref} isTablet={isTablet}>
          {node}
        </RootFrame>
      </Box>
    );
  }
);

export default DatabaseHtmlForm;
