import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import MessageIcon from '@material-ui/icons/Message';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';

const BedInfoWrapper = styled.div`
  padding-left: 0.75em;
  margin-left: 1.5em;
  border-left: 1px solid rgba(145, 196, 255, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

interface CaretakerMessageProps {
  message: string;
  phoneNumber: string;
}

const CaretakerMessage = ({ message, phoneNumber }: CaretakerMessageProps) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = (e: any) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleClose = (e: any) => {
    e.stopPropagation();
    setOpen(false);
  };

  return (
    <BedInfoWrapper>
      <Box display="flex" flexDirection="column" justifyContent="center">
        <IconButton color="primary" size="small" edge="end" aria-label="delete" onClick={handleClickOpen}>
          <Badge color="secondary" variant="dot" invisible={!message}>
            <MessageIcon fontSize="large" />
          </Badge>
        </IconButton>
      </Box>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={open}
        onBackdropClick={handleClose}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Confirmation by family member'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message && `Family member (${phoneNumber}) has confirmed the pickup and left the following message.`}
            {!message && `Family member (${phoneNumber}) has confirmed the pickup but did not leave any message.`}
          </DialogContentText>
          {message && <Typography variant="body1">{`"${message}"`}</Typography>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Acknowledge
          </Button>
        </DialogActions>
      </Dialog>
    </BedInfoWrapper>
  );
};

CaretakerMessage.propTypes = {
  bed: PropTypes.string,
};

export default CaretakerMessage;
