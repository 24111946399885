import React from 'react';
import { useValue } from '../ValueContext';
import styles from '../styles';
import MedicalPassportField from '../components/MedicalPassportField';
import MedicalPassportCheckbox from '../components/MedicalPassportCheckbox';
import MedicationReconciliationField from '../components/MedicationReconciliationField';
import get from 'lodash/get';
import MedicationTextArea from '../components/MedicationTextArea';
import MedicalPassportTextArea from '../components/MedicalPassportTextArea';
import Barcode from '../../Barcode';

const Page8Extra = ({ formName, pageNumber, showQRCode, hospitalName, defaultValue, answers }) => {
  const providerId = defaultValue?.procedure?.patient?.providerId;
  const { questionnaireAnswers } = useValue();

  const getMedicationList = answers => {
    const [takesMedication, medicationAnswer] = get(answers, 'sections.medications.yes', []) || [];
    return [takesMedication, get(medicationAnswer, 'list', [])];
  };

  const medicationYes = get(questionnaireAnswers, 'sections.medications.yes', []);
  const medicationList = getMedicationList(questionnaireAnswers);
  const medication = medicationList[1];

  const morethan15 = medication.length > 15;

  let nameArr = [medication.length];
  let doseArr = [medication.length];
  let freqArr = [medication.length];
  let purposeArr = [medication.length];
  let last = [medication.length];

  for (let i = 0; i < medication.length; i++) {
    nameArr[i] = get(medication[i], 'schema.name.text', '');
    doseArr[i] = get(medication[i], 'schema.dosage.text', '');
    if (get(medication[i], 'schema.frequency.option[1].text', '') !== '') {
      freqArr[i] =
        get(medication[i], 'schema.frequency.option[0]', '') +
        ', ' +
        get(medication[i], 'schema.frequency.option[1].text', '');
    } else if (get(medication[i], 'schema.frequency.option[0]', '') === 'Other') {
      freqArr[i] = get(medication[i], 'schema.frequency.option[0]', '');
    } else {
      freqArr[i] = get(medication[i], 'schema.frequency.option', '');
    }
    purposeArr[i] = get(medication[i], 'schema.purpose.text', '');
    last[i] = get(medication[i], 'schema.last.text', '');
  }

  let medicationTableLength = 4;
  if (medication.length <= 10) {
    medicationTableLength = 14 - medication.length;
  }
  const allergies = get(questionnaireAnswers, 'sections.allergies.schema.q1.checkboxes', {});
  const latex = get(allergies, 'Allergic to Latex?[0]');
  let medicationFlag = false;
  if (medicationYes.length === 0) {
    medicationFlag = true;
  }
  let latexFlag = false;
  if (latex === true) {
    latexFlag = true;
  }

  if (morethan15) {
    return (
        <div id="page8extra" className="page" style={{fontFamily:'Arial'}}>
          <div style={{ ...styles.twoColumns, paddingTop: '1rem' }}>
            <div>
              <div>{hospitalName || ''}</div>
              <strong>
                <div style={{ display: 'flex', alignItems: 'left', fontSize: '24px' }}>Medication Reconciliation</div>
              </strong>
              <strong>
                <div style={{ display: 'flex', alignItems: 'left', fontSize:'14px' }}>Allergies</div>
              </strong>
              <div style={{ display: 'flex', alignItems: 'left' ,fontSize:'12px'}}>
                <input type="checkbox" name="page8.latex" label="Latex Allergy / Sensitivity" id="id" checked={latexFlag} />
                Latex Allergy / Sensitivity
              </div>
            </div>
            <div>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div>
                  {showQRCode && (
                    <Barcode
                      formName={formName}
                      pageNumber={pageNumber}
                      providerId={defaultValue?.procedure?.patient?.providerId}
                      serviceTime={defaultValue?.procedure?.serviceTime}
                    />
                  )}
                </div>
              </div>
              <div style={{ float: 'right' }}>
                <div style={styles.box}>
                  <div
                    style={{
                      width: '50%',
                      height: 'auto',
                      margin: '0 auto',
                      padding: '10px',
                      position: 'relative',
                      opacity: '.5',
                    }}
                  >
                    PATIENT LABEL
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={styles.center}>
            <div style={{ display: 'flex', alignItems: 'left', marginTop: '4px', fontSize:'12px' }}>
              <input type="checkbox" name="page8.noMed" label="No Current Medications" id="id2" checked={medicationFlag}/>
              No Current Medications
            </div>
            <div style={{ ...styles.sectionHeader, fontWeight: 'bold', padding: '2px', fontSize: '14px' }}>MEDICATION</div>
                    <div
                        style={{
                            lineHeight: '18px',
                            display: 'grid',
                            gridTemplateColumns: '32% 7.5% 14% 13% 13% 6% 7% 7.5%',
                            textAlign: 'center',
                            border: '1px solid black',
                            borderBottom: 0,
                            borderRight: '1px solid black',
                            fontWeight: 'bold',
                        }}
                    >
                        <div style={{ borderRight: '1px solid black', fontSize: '12px' }}>Name</div>
                        <div style={{ borderRight: '1px solid black', fontSize: '12px' }}>Dose</div>
                        <div style={{ borderRight: '1px solid black', fontSize: '12px' }}>Frequency</div>
                        <div style={{ borderRight: '1px solid black', fontSize: '12px' }}>Reason</div>
                        <div style={{ borderRight: '1px solid black', fontSize: '12px' }}>Last Taken Date & Time</div>
                        <div style={{ borderRight: '1px solid black', fontSize: '10px' }}>Continue</div>
                        <div style={{ borderRight: '1px solid black', fontSize: '10px' }}>Discontinue</div>
                        <div style={{ fontSize: '12px' }}>Date to Resume</div>
                    </div>
                    {[...Array(15)].map((_, i) => (
                        <>
                            <div style={{ ...styles.eightColumns, lineHeight: '15px' }}>
                                <div style={{ borderRight: '1px solid black', wordBreak: 'break-all' }}>
                                    <MedicationTextArea
                                        name={`page8.c${i + 15}.r1`}
                                        value={nameArr[i + 15]}
                                        style={{ fontSize: '10px' }}
                                    />
                                </div>
                                <div style={{ borderRight: '1px solid black' }}>
                                    <MedicationTextArea
                                        name={`page8.c${i + 15}.r2`}
                                        value={doseArr[i + 15]}
                                        style={{ fontSize: '10px' }}
                                    />
                                </div>
                                <div style={{ borderRight: '1px solid black' }}>
                                    <MedicationTextArea
                                        name={`page8.c${i + 15}.r3`}
                                        value={freqArr[i + 15]}
                                        style={{ fontSize: '10px' }}
                                    />
                                </div>
                                <div style={{ borderRight: '1px solid black' }}>
                                    <MedicationTextArea
                                        name={`page8.c${i + 15}.r4`}
                                        value={purposeArr[i + 15]}
                                        style={{ fontSize: '10px' }}
                                    />
                                </div>
                                <div style={{ borderRight: '1px solid black' }}>
                                    <MedicationTextArea name={`page8.c${i + 15}.r5`} style={{ fontSize: '10px' }} />
                                </div>
                                <div style={{ borderRight: '1px solid black' }}>
                                    <div style={{ position: 'relative', left: '30%' }}>
                                        <MedicalPassportCheckbox name={`page8.c${i + 15}.r6`} />
                                    </div>
                                </div>
                                <div style={{ borderRight: '1px solid black' }}>
                                    <div style={{ position: 'relative', left: '30%' }}>
                                        <MedicalPassportCheckbox name={`page8.c${i + 15}.r7`} />
                                    </div>
                                </div>
                                <div>
                                    <MedicationReconciliationField name={`page8.c${i + 15}.r8`} />
                                </div>
                            </div>
                        </>
                    ))}
            
            <div style={{ ...styles.sectionHeader, fontWeight: 'bold',fontSize:'14px' }}>PRESCRIPTIONS GIVEN AT DISCHARGE</div>
            <div
              style={{
                lineHeight: '18px',
                display: 'grid',
                gridTemplateColumns: '35% 7.5% 7.5% 12% 18% 20%',
                textAlign: 'center',
                border: '1px solid black',
                borderBottom: 0,
                borderRight: '1px solid black',
                fontWeight: 'bold',
              }}
            >
              <div style={{ borderRight: '1px solid black', fontSize: '12px' }}>Medication</div>
              <div style={{ borderRight: '1px solid black', fontSize: '12px' }}>Dosage</div>
              <div style={{ borderRight: '1px solid black', fontSize: '12px' }}>Route</div>
              <div style={{ borderRight: '1px solid black', fontSize: '12px' }}>Frequency</div>
              <div style={{ borderRight: '1px solid black', fontSize: '12px' }}>Indication</div>
              <div style={{ fontSize: '12px' }}>Last Dose Given</div>
            </div>
    
            {[...Array(4)].map((_, i) => (
              <>
                <div style={{ ...styles.sixColumns, lineHeight: '13px' }}>
                  <div style={{ borderRight: '1px solid black' }}>
                    <MedicationTextArea name={`page8.pres${i}.c1`} style={{ fontSize: '10px' }} />
                  </div>
                  <div style={{ borderRight: '1px solid black' }}>
                    <MedicationTextArea name={`page8.pres${i}.c2`} style={{ fontSize: '10px' }} />
                  </div>
                  <div style={{ borderRight: '1px solid black' }}>
                    <MedicationTextArea name={`page8.pres${i}.c3`} style={{ fontSize: '10px' }} />
                  </div>
                  <div style={{ borderRight: '1px solid black' }}>
                    <MedicationTextArea name={`page8.pres${i}.c4`} style={{ fontSize: '10px' }} />
                  </div>
                  <div style={{ borderRight: '1px solid black' }}>
                    <MedicationTextArea name={`page8.pres${i}.c5`} style={{ fontSize: '10px' }} />
                  </div>
                  <div>
                    <MedicationTextArea name={`page8.pres${i}.c6`} style={{ fontSize: '10px' }} />
                  </div>
                </div>
              </>
            ))}
            <div style={{fontSize:'12px'}}>
              <MedicalPassportCheckbox name="copy" label="Copy given to Patient" />
            </div>
            <br></br>
            <strong>
              <div style={{ textAlign: 'left', fontSize:'12px' }}>Signature Review of Medications Across the Patient Care Continuum</div>
            </strong>
            <div style={{...styles.signatureBox1,fontSize:'12px'}}>
              <div>
                <div style={{ paddingTop: '22px' }}>______________________________</div>
                <div>Pre-Op Signature</div>
              </div>
              <div>
                <div style={{ marginLeft: '22px', paddingTop: '22px' }}>_________________________</div>
                <div style={{ marginLeft: '22px' }}>PACU Signature</div>
              </div>
              <div>
                <div style={{ marginLeft: '14px', marginTop: '22px' }}>_______________________________</div>
                <div>Physician Signature</div>
              </div>
            </div>
            <br></br>
            <div style={{fontSize:'12px'}}>Please bring this medication record with you to your physician office/pharmacist</div>
          </div>
          
        </div>
      );
    }
    else {
        return ''
    };
    
  }
 
export default Page8Extra;
