import { Switch } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import clsx from 'clsx';
import React from 'react';
import { getNestedValue } from '../../../../se/utilities/data/object';
import { PROCEDURE_STATUSES } from '../../../entities/patient/enums';
import { HospitalInfo } from '../../../HospitalInfo';
import PanelStatus from '../../../Panel/PanelStatus';
import { eventTime, getLogEntries, isHelpFn, patientEnteredTime } from '../tablet/utils';
import BottomPanelStatus from './BottomPanelStatus';
import { PanelsStatus } from './Monitor';

export const MonitorContent = ({
  operationRooms,
  waitingRoom,
  preOp,
  pacu,
  postOps,
  hasPostop,
  hospitalId,
  preOpFocused,
  pacuFocused,
  hasPreOpPriorityModule,
  hasNoteModule,
  isMini,
  isSuperAdmin,
  messagingView,
  scope,
}) => {
  const hasOtherStatuses = waitingRoom || preOp || pacu || (postOps && Array.isArray(postOps) && postOps.length > 0);
  const classes = useStyles();

  return (
    <Box className={clsx(classes.root, { [classes.focused]: preOpFocused || pacuFocused })}>
      <Box display="flex" alignItems="center">
        <Box flex={1}>
          <HospitalInfo scope={scope}/>
        </Box>
      </Box>
      {operationRooms.length > 0 && !hasOtherStatuses ? (
        <PanelsStatus miniView={isMini} wrap="true">
          {operationRooms.map(operationRoom => {
            return (
              <PanelStatus
                key={operationRoom.name}
                roomId={operationRoom.id}
                title={operationRoom.name}
                monitorStatus={operationRoom.monitorStatus}
                status={operationRoom.status}
                statusLabel={operationRoom.statusLabel}
                scheduleProcedure={operationRoom.procedure}
                procedure={getNestedValue('patient.procedureType.name', operationRoom)}
                physician={getNestedValue('patient.physician.name', operationRoom)}
                patient={getNestedValue('patient.name', operationRoom)}
                patientId={getNestedValue('patient.id', operationRoom)}
                patientType={operationRoom?.patient?.type}
                enteredAtTime={patientEnteredTime(getNestedValue('patient', operationRoom), operationRoom)}
                timeoutTime={eventTime(PROCEDURE_STATUSES.TIME_OUT, getNestedValue('patient', operationRoom))}
                procedureStartTime={eventTime(PROCEDURE_STATUSES.ONGOING, getNestedValue('patient', operationRoom))}
                closingTime={eventTime(PROCEDURE_STATUSES.CLOSING, getNestedValue('patient', operationRoom))}
                compactView={operationRooms.length > 3}
                allowWrap={true}
                miniView={operationRooms.length > 8}
                numberOfRooms={operationRooms.length}
                preOpFocused={preOpFocused}
                pacuFocused={pacuFocused}
                isHelp={isHelpFn(getLogEntries(getNestedValue('patient', operationRoom)))}
                called={getNestedValue('patient.called') && hasOtherStatuses}
                isPreOp={preOp}
                room={operationRoom}
                isSuperAdmin={isSuperAdmin}
                messagingView={messagingView}
              />
            );
          })}
        </PanelsStatus>
      ) : operationRooms.length === 0 && hasOtherStatuses ? (
        <BottomPanelStatus
          waitingRoom={waitingRoom}
          preOp={preOp}
          pacu={pacu}
          postOps={postOps}
          hasPostop={hasPostop}
          hospitalId={hospitalId}
          preOpFocused={preOpFocused}
          pacuFocused={pacuFocused}
          hasPreOpPriorityModule={hasPreOpPriorityModule}
          hasNoteModule={hasNoteModule}
          isMini={isMini}
          messagingView={messagingView}
        />
      ) : (
        <>
          <PanelsStatus miniView={isMini} wrap="true">
            {operationRooms.map(operationRoom => {
              return (
                <PanelStatus
                  key={operationRoom.name}
                  roomId={operationRoom.id}
                  title={operationRoom.name}
                  monitorStatus={operationRoom.monitorStatus}
                  status={operationRoom.status}
                  statusLabel={operationRoom.statusLabel}
                  scheduleProcedure={operationRoom.procedure}
                  procedure={getNestedValue('patient.procedureType.name', operationRoom)}
                  physician={getNestedValue('patient.physician.name', operationRoom)}
                  patient={getNestedValue('patient.name', operationRoom)}
                  patientId={getNestedValue('patient.id', operationRoom)}
                  patientData={getNestedValue('patient', operationRoom)}
                  patientType={operationRoom?.patient?.type}
                  enteredAtTime={patientEnteredTime(getNestedValue('patient', operationRoom), operationRoom)}
                  timeoutTime={eventTime(PROCEDURE_STATUSES.TIME_OUT, getNestedValue('patient', operationRoom))}
                  procedureStartTime={eventTime(PROCEDURE_STATUSES.ONGOING, getNestedValue('patient', operationRoom))}
                  closingTime={eventTime(PROCEDURE_STATUSES.CLOSING, getNestedValue('patient', operationRoom))}
                  compactView={operationRooms.length > 3}
                  allowWrap={true}
                  miniView={operationRooms.length > 8}
                  numberOfRooms={operationRooms.length}
                  preOpFocused={preOpFocused}
                  pacuFocused={pacuFocused}
                  isHelp={isHelpFn(getLogEntries(getNestedValue('patient', operationRoom)))}
                  called={getNestedValue('patient.name.called')}
                  isPreOp={preOp}
                  room={operationRoom}
                  isSuperAdmin={isSuperAdmin}
                  hospitalId={hospitalId}
                  messagingView={messagingView}
                />
              );
            })}
          </PanelsStatus>
          <BottomPanelStatus
            waitingRoom={waitingRoom}
            preOp={preOp}
            pacu={pacu}
            postOps={postOps}
            hasPostop={hasPostop}
            hospitalId={hospitalId}
            preOpFocused={preOpFocused}
            pacuFocused={pacuFocused}
            hasPreOpPriorityModule={hasPreOpPriorityModule}
            hasNoteModule={hasNoteModule}
            isMini={isMini}
            messagingView={messagingView}
          />
        </>
      )}
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexFlow: 'column',
    width: '100vw',
    minHeight: '100vh',
    height: 'fit-content',
    padding: '0.5rem 1rem 1rem',
  },
  focused: {
    display: 'grid',
    gridTemplateRows: '1fr 5fr 12fr',
  },
}));

export default MonitorContent;
