import React from 'react';
import styled from 'styled-components';
import { SaveRounded, Settings as SettingsIcon } from '@material-ui/icons';
import { Box, Button, Menu, MenuItem, Paper, Popper, useTheme, useMediaQuery } from '@material-ui/core';
import ColumnSelect from './ColumnSelect';
import exportCSV from './exportCSV';
import exportXLSX from './exportXLSX';
import responsive from '../../utilities/responsive';

const Header = styled.div`
  display: flex;
  ${p =>
    p.showColumnSelection
      ? ''
      : `
    flex-direction: row-reverse;
  `}

  //> div {
  //  width: 100%;
  //  margin-right: 2em;
  //}
  //> button {
  //  min-width: 8em;
  //}

  ${responsive.md.andSmaller`
    flex-direction: column;
    > div {
      width: 100%;
      margin-bottom: 1em;
    }
  `};
`;

export default ({
  showColumnSelection,
  showCSVExport,
  selectedColumns,
  setSelectedColumns,
  columns,
  columnOptions,
  list,
  data,
  children,
}) => {
  if (!(showCSVExport || showColumnSelection)) {
    return null;
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [exportMenuAnchor, setExportMenuAnchor] = React.useState(null);

  const handleSettingsClick = event => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleExportClick = event => {
    setExportMenuAnchor(exportMenuAnchor ? null : event.currentTarget);
  };

  const handleExportOptionClick = format => {
    setExportMenuAnchor(null);
    if (format === 'csv') {
      exportCSV(list, columns, data);
    } else if (format === 'xlsx') {
      exportXLSX(list, columns, data);
    }
  };

  const openSettings = Boolean(anchorEl);
  const openExportMenu = Boolean(exportMenuAnchor);
  const idSettings = openSettings ? 'settings-popper' : undefined;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Header showColumnSelection={showColumnSelection}>
      <Box display="flex" alignItems="center" ml="auto" mt={1}>
        {children}
        {showCSVExport && (
          <Box>
            <Button color="primary" startIcon={<SaveRounded />} onClick={handleExportClick}>
              Export
            </Button>
            <Menu anchorEl={exportMenuAnchor} open={openExportMenu} onClose={() => setExportMenuAnchor(null)}>
              <MenuItem onClick={() => handleExportOptionClick('csv')}>Export as CSV</MenuItem>
              <MenuItem onClick={() => handleExportOptionClick('xlsx')}>Export as XLSX</MenuItem>
            </Menu>
          </Box>
        )}
        {showColumnSelection && matches && (
          <Box ml={1}>
            <Button size="small" onClick={handleSettingsClick} startIcon={<SettingsIcon />}>
              Configure Columns
            </Button>
            <Popper style={{ zIndex: 2 }} id={idSettings} open={openSettings} anchorEl={anchorEl}>
              <Paper>
                <ColumnSelect
                  keepOpen
                  options={columnOptions}
                  values={selectedColumns}
                  onChange={c => setSelectedColumns(c)}
                />
              </Paper>
            </Popper>
          </Box>
        )}
      </Box>
    </Header>
  );
};
