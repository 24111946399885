const styles = {
  header: {
    textDecoration: 'underline',
    fontWeight: 'bold',
  },
  center: {
    textAlign: 'center',
  },
  underline: {
    textDecoration: 'underline',
  },
  underlineHeading: {
    textDecoration: 'underline',
    marginTop: 0,
  },
  inputField: {
    border: 0,
    outline: 0,
    backgroundColor: 'lightgrey',
    width: 'calc(100% - 1.5rem)',
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
    marginLeft: '0.5rem',
  },
  div: {
    lineHeight: '1.5em',
  },
  medicationsGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
  },
  medicationsInnerDiv: {
    border: '1px solid black',
    padding: '0.1rem',
    borderRight: 0,
  },
  reviewsGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr 4fr',
  },
  greyBackground: {
    backgroundColor: 'lightgrey',
    border: '1px solid black',
    fontWeight: 'bold',
    padding: '0.1rem',
    textAlign: 'center',
  },
  darkGreyBackground: {
    backgroundColor: 'grey',
    border: '1px solid black',
    padding: '0.1rem',
    textAlign: 'left',
    color: 'white',
  },
  textareaField: {
    border: 0,
    outline: 0,
    backgroundColor: 'lightgrey',
    width: 'calc(100% - 2rem)',
    height: '250px',
    padding: '0.5rem',
    marginLeft: '0.5rem',
    resize: 'none',
  },
  twoColumns: {
    lineHeight: '1.6em',
    display: 'grid',
    gridTemplateColumns: '50% 50%',
    alignContent: 'start',
  },
  twoColumnsNotEqual: {
    display: 'grid',
    gridTemplateColumns: '68% 32%',
    alignContent: 'start',
  },
  fiveColumns: {
    lineHeight: '1.6em',
    display: 'grid',
    gridTemplateColumns: '20% 20% 20% 20% 20%',
    textAlign: 'center',
  },
  fiveColumns2: {
    lineHeight: '1.6em',
    display: 'grid',
    gridTemplateColumns: '40% 20% 10% 10% 20%',
    textAlign: 'left',
  },
  sixColumns: {
    lineHeight: '1.6em',
    display: 'grid',
    gridTemplateColumns: '35% 7.5% 7.5% 12% 18% 20%',
    textAlign: 'center',
    border: '1px solid black',
  },
  sixColumns2: {
    lineHeight: '1.6em',
    display: 'grid',
    gridTemplateColumns: '26% 18.5% 16.5% 18% 10% 10%',
    textAlign: 'left',
  },
  sixEqualColumns: {
    lineHeight: '1.6em',
    display: 'grid',
    gridTemplateColumns: '14% 19% 16% 16% 16% 19%',
    textAlign: 'center',
    border: '1px solid black',
    borderRight: '1px solid black',
    fontSize: '.8rem',
  },
  eightColumns: {
    lineHeight: '1.6em',
    display: 'grid',
    gridTemplateColumns: '32% 7.5% 14% 13% 13% 6% 7% 7.5%',
    textAlign: 'center',
    border: '1px solid black',
    borderBottom: 0,
    padding: '0px',
  },
  twoNotEqualColumns: {
    lineHeight: '1.6em',
    display: 'grid',
    gridTemplateColumns: '60% 40%',
    alignContent: 'start',
  },
  twoNotEqualColumns1: {
    lineHeight: '1.6em',
    display: 'grid',
    gridTemplateColumns: '30% 70%',
    alignContent: 'start',
  },
  twoNotEqualColumns2: {
    lineHeight: '1.6em',
    display: 'grid',
    gridTemplateColumns: '50% 25% 25%',
    alignContent: 'start',
  },
  border: {
    border: '1px solid black',
  },
  leftDownCorner: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    padding: '.25em',
  },
  rightTopCorner: {
    position: 'absolute',
    right: 0,
    top: 0,
    padding: '.25em',
  },
  allergiesSection: {
    lineHeight: '1rem',
    border: '1px solid black',
  },
  tab: {
    textAlign: 'center',
  },
  activeTab: {
    backgroundColor: 'lightgrey',
    border: '1px solid black',
    textAlign: 'center',
    width: '15%',
  },
  sectionHeader: {
    border: '1px solid black',
    backgroundColor: 'lightgrey',
    textAlign: 'center',
  },
  sectionRow: {
    border: '1px solid black',
    textAlign: 'center',
  },
  sectionEmptyRow: {
    border: '1px solid black',
    textAlign: 'center',
    padding: '10px',
  },
  inlineLeft: {
    display: 'inline',
    textAlign: 'left',
  },
  inlineRight: {
    display: 'inline',
    textAlign: 'left',
  },
  signatureBox1: {
    display: 'flex',
    flexDirection: 'rows',
    textAlign: 'center',
  },
  examLabGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    height: '80px',
  },
  examLabInnerGridDiv: {
    lineHeight: '150%',
    borderLeft: '1px solid black',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  oneColumn: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    textAlign: 'center',
    padding: '0.5rem',
    border: '1px solid black',
    borderBottom: 0,
  },
  oneColumnNoLeftYesBottom: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    textAlign: 'center',
    padding: '0.5rem',
    border: '1px solid black',
    borderBottom: '1px solid black',
    borderLeft: 0,
  },
  oneColumnLeftAlign: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    textAlign: 'left',
    border: '1px solid black',
    borderBottom: 0,
  },
  threeNotEqualColumns3: {
    display: 'grid',
    gridTemplateColumns: '1fr 2fr 1fr',
    border: '1px solid black',
    borderBottom: 0,
  },
  threeEqualColumns3: {
    display: 'grid',
    gridTemplateColumns: '33.33% 33.33% 33.33%',
    borderBottom: 0,
  },
  threeNotEqualColumns: {
    display: 'grid',
    gridTemplateColumns: '25% 25% 50%',
    borderBottom: 0,
  },
  sixNotEqualColumns4: {
    display: 'grid',
    gridTemplateColumns: '13% 17% 10% 40% 7% 13%',
    borderBottom: 0,
  },
  threeEqualColumnsBorder: {
    display: 'grid',
    gridTemplateColumns: '33.33% 33.33% 33.33%',
    border: '1px solid black',
    borderBottom: 0,
  },
  threeNotEqualColumnsBorder: {
    display: 'grid',
    gridTemplateColumns: '20% 20% 60%',
    border: '1px solid black',
    borderBottom: 0,
    lineHeight: '1.6em',
  },
  threeNotEqualColumnsBorder2: {
    display: 'grid',
    gridTemplateColumns: '60% 20% 20%',
    border: '1px solid black',
    borderBottom: 0,
    lineHeight: '1.6em',
  },
  fourNotEqualColumnsBorder: {
    display: 'grid',
    gridTemplateColumns: '40% 20% 20% 20%',
    border: '1px solid black',
    borderBottom: 0,
    lineHeight: '1.6em',
  },
  twoNotEqualColumns4: {
    display: 'grid',
    gridTemplateColumns: '3fr 1fr',
    border: '1px solid black',
  },
  twoEqualColumns3: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    border: '1px solid black',
    borderBottom: 0,
  },
  twoEqualColumnsNoBorder: {
    display: 'grid',
    gridTemplateColumns: '50% 50%',
    borderBottom: 0,
  },
  twoNotEqualColumnsNoBorder: {
    display: 'grid',
    gridTemplateColumns: '40% 60%',
    borderBottom: 0,
  },
  sixNotEqualColumns: {
    display: 'grid',
    gridTemplateColumns: '40% 10% 15% 15% 11% 9%',
    borderBottom: 0,
  },
  sevenNotEqualColumns: {
    display: 'grid',
    gridTemplateColumns: '38% 9% 9% 10% 14% 11% 9%',
    borderBottom: 0,
  },
  box: {
    width: '250px',
    height: '100px',
    border: '1px solid black',
    margin: 0,
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
  },

  systemrow: {
    display: 'grid',
    gridTemplateColumns: '1fr 4fr',
    border: '1px solid black',
    borderBottom: 0,
    resize: 'none',
    width: '100%',
  },
  systemrow2: {
    display: 'grid',
    gridTemplateColumns: '1fr 5fr',
    border: '1px solid black',
    borderBottom: 0,
    resize: 'none',
    width: '100%',
  },

  fallriskgrid: {
    display: 'grid',
    gridTemplateColumns: '92% 8%',
    gridGap: '0.2rem',
  },

  fallriskTitle: {
    borderTop: '1px solid gray',
    padding: '3px',
    width: '100%',
    fontSize: '14px',
  },

  fallriskPoint: {
    textAlign: 'center',
    border: '1px solid gray',
    borderBottom: '0',
    padding: '2px',
    width: '100%',
    fontSize: '14px',
    gridColumn: '2/3',
    gridRow: '1/3',
    paddingTop: '12px',
  },

  tableHeader: {
    textAlign: 'center',
    backgroundColor: '#D3D3D3',
    fontWeight: '900',
    padding: '2px',
  },
  tableData: {
    border: '1px solid lightgray',
    padding: '1px',
  },
};

export default styles;
