import React, { FC, useRef } from 'react';
import Typography from '@material-ui/core/Typography';
import { FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { useStyles } from '../ScheduleProcedureForm';
import { FormParams, PatientDetails, sexTypes } from '../types/types';
import set from 'lodash/fp/set';
import { PhoneNumberInput } from '../components/PhoneNumberInput';
import { phoneNumberFormatPipeline } from '../../../../../../se/components/inputs/PhoneInput';
import { format } from 'date-fns';
import { DateInput } from '../components/DateInput';
import { setValueAndResetError } from '../utils/values';
import { Autocomplete } from '@material-ui/lab';
import MedicalRecordNumberInput from '../components/MedicalRecordNumberInput';
import Divider from '@material-ui/core/Divider';
import { unparsedAllergiesSummary } from '../../../../../../allergies/AllergiesView';

const anesthesologistNotes = ['Drugs in room', 'MH', 'Latex', 'Glidescope', 'ICD/Magnet'];

const PatientInfoForm: FC<FormParams<PatientDetails>> = ({ value, setValue }) => {
  const classes = useStyles();
  const note = value?.basicInfo?.anesthesiologistNotes?.value;

  const [selectNote, setSelectNote] = React.useState<string | null>(
    note ? (anesthesologistNotes.includes(note) ? note : 'custom') : ''
  );
  const [open, setOpen] = React.useState(false);
  const [anesthesiologistNoteValue, setAnesthesiologistNoteValue] = React.useState<string | null>(note);

  const customNoteRef = useRef<HTMLInputElement>(null);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setAnesthesiologistNoteValue(event.target.value as string);
    if (event.target.value === 'custom') {
      setSelectNote('custom');
      setValue(set('basicInfo.anesthesiologistNotes.value', '')(value));
      setTimeout(() => {
        customNoteRef.current?.focus();
      }, 0);
    } else {
      setSelectNote(event.target.value as string);
      setValue(set('basicInfo.anesthesiologistNotes.value', event.target.value)(value));
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <Typography variant="h3" gutterBottom>
        Patient Info
      </Typography>

      <form className={classes.form} noValidate autoComplete="off">
        <Grid container spacing={4}>
          <Grid item md={8}>
            <Typography color="textSecondary" gutterBottom>
              Basic info
            </Typography>
            <Grid container spacing={1}>
              <Grid item md={6}>
                <TextField
                  required
                  error={!!value?.basicInfo?.firstName?.error}
                  helperText={value?.basicInfo?.firstName?.error}
                  value={value?.basicInfo?.firstName?.value}
                  onChange={event => setValue(setValueAndResetError('basicInfo.firstName', event.target.value, value))}
                  label="First Name"
                  variant="filled"
                  margin="dense"
                  fullWidth
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  required
                  error={!!value?.basicInfo?.lastName?.error}
                  helperText={value?.basicInfo?.lastName?.error}
                  value={value?.basicInfo?.lastName?.value}
                  onChange={event => setValue(setValueAndResetError('basicInfo.lastName', event.target.value, value))}
                  label="Last Name"
                  variant="filled"
                  margin="dense"
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item md={5}>
                <MedicalRecordNumberInput
                  filter={{
                    firstName: value?.basicInfo?.firstName?.value,
                    lastName: value?.basicInfo?.lastName?.value,
                    dateOfBirth:
                      value?.basicInfo?.dateOfBirth?.value &&
                      format(value?.basicInfo?.dateOfBirth?.value, 'YYYY-MM-DD'),
                    phoneNumber: value?.contactInfo?.patientPhoneNumber?.value,
                  }}
                  value={value?.basicInfo?.medicalRecord?.value}
                  onChange={v => setValue(setValueAndResetError('basicInfo.medicalRecord', v, value))}
                  error={value?.basicInfo?.medicalRecord?.error || null}
                />
              </Grid>
              <Grid item md={4}>
                <DateInput
                  value={value?.basicInfo?.dateOfBirth?.value}
                  error={!!value?.basicInfo?.dateOfBirth?.error}
                  helperText={value?.basicInfo?.dateOfBirth?.error}
                  onChange={event => setValue(set('basicInfo.dateOfBirth.value', event.target.value)(value))}
                  label="Date of Birth"
                  max={format(new Date(), 'YYYY-MM-DD')}
                  required
                />
              </Grid>
              <Grid item md={3}>
                <Autocomplete
                  options={sexTypes}
                  getOptionLabel={(option: string) => option}
                  renderInput={params => (
                    <TextField {...params} label="Sex" variant="filled" margin="dense" fullWidth />
                  )}
                  value={value?.basicInfo?.sex?.value}
                  onChange={(event, val) => setValue(setValueAndResetError('basicInfo.sex', val || '', value))}
                />
              </Grid>
            </Grid>
            <Grid item md={12}>
              <TextField
                value={value?.basicInfo?.diagnosis?.value}
                onChange={event => setValue(set('basicInfo.diagnosis.value', event.target.value)(value))}
                label="Diagnosis"
                variant="filled"
                margin="dense"
                fullWidth
                multiline
                minRows={2}
              />
            </Grid>
            <Grid item md={12}>
              <TextField
                value={unparsedAllergiesSummary(value?.basicInfo?.allergies?.value)}
                onChange={event => setValue(set('basicInfo.allergies.value', event.target.value)(value))}
                label="Allergies"
                variant="filled"
                margin="dense"
                fullWidth
                multiline
                minRows={6}
              />
            </Grid>
          </Grid>
          <Grid item md={4}>
            <Typography color="textSecondary" gutterBottom>
              Contact info
            </Typography>
            <PhoneNumberInput
              value={value?.contactInfo?.patientPhoneNumber?.value}
              onChange={event =>
                setValue(
                  set('contactInfo.patientPhoneNumber.value', phoneNumberFormatPipeline(event.target.value))(value)
                )
              }
              label="Patient Phone Number"
            />
            <PhoneNumberInput
              value={value?.contactInfo?.careTakerPhoneNumber?.value}
              onChange={event =>
                setValue(
                  set('contactInfo.careTakerPhoneNumber.value', phoneNumberFormatPipeline(event.target.value))(value)
                )
              }
              label="Caretaker Phone Number"
            />
            <PhoneNumberInput
              value={value?.contactInfo?.familyPhoneNumber?.value}
              onChange={event =>
                setValue(
                  set('contactInfo.familyPhoneNumber.value', phoneNumberFormatPipeline(event.target.value))(value)
                )
              }
              label="Family Phone Number"
            />
            <PhoneNumberInput
              value={value?.contactInfo?.homePhoneNumber?.value}
              onChange={event =>
                setValue(set('contactInfo.homePhoneNumber.value', phoneNumberFormatPipeline(event.target.value))(value))
              }
              label="Home Phone Number"
            />
            <TextField
              value={value?.basicInfo?.patientAddress?.value}
              onChange={event => setValue(set('basicInfo.patientAddress.value', event.target.value)(value))}
              label="Address"
              variant="filled"
              margin="dense"
              fullWidth
            />
            <TextField
              value={value?.contactInfo?.patientEmail?.value}
              onChange={event => setValue(set('contactInfo.patientEmail.value', event.target.value)(value))}
              label="Patient Email"
              variant="filled"
              margin="dense"
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item md={12}>
            <TextField
              value={value?.basicInfo?.note?.value}
              onChange={event => setValue(set('basicInfo.note.value', event.target.value)(value))}
              label="Note"
              variant="filled"
              margin="dense"
              fullWidth
              multiline
              minRows={3}
            />
          </Grid>
          <Grid item md={12}>
            <FormControl variant="filled" fullWidth>
              <InputLabel id="demo-controlled-open-select-label">Anesthesiologist Note</InputLabel>
              <Select
                labelId="demo-controlled-open-select-label"
                id="demo-controlled-open-select"
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
                value={selectNote}
                onChange={handleChange}
              >
                <MenuItem value="">None</MenuItem>
                <Divider />
                {anesthesologistNotes.map(note => (
                  <MenuItem key={note} value={note}>
                    {note}
                  </MenuItem>
                ))}
                <Divider />
                <MenuItem key="custom" value="custom">
                  Custom
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {anesthesiologistNoteValue && !anesthesologistNotes.includes(anesthesiologistNoteValue) && (
            <Grid container spacing={1}>
              <Grid item md={1} justifyContent="center" style={{ display: 'flex' }}>
                <Divider orientation="vertical" />
              </Grid>
              <Grid item md={11}>
                <TextField
                  value={value?.basicInfo?.anesthesiologistNotes?.value}
                  onChange={event => setValue(set('basicInfo.anesthesiologistNotes.value', event.target.value)(value))}
                  label="Anesthesiologist Note"
                  variant="filled"
                  margin="dense"
                  fullWidth
                  multiline
                  minRows={3}
                  inputRef={customNoteRef}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </form>
    </>
  );
};

export default PatientInfoForm;
