import React from 'react';
import styles from '../styles';
import MedicalPassportTextArea from '../components/MedicalPassportTextArea';
import MedicalPassportAllergiesRow3 from '../components/MedicalPassportAllergiesRow3';
import MedicalPassportField from '../components/MedicalPassportField';
import MedicalPassportCheckbox from '../components/MedicalPassportCheckbox';
import toHeight from '../../../../../questionnaire/toHeight';
import toHighlightsString from '../../../../../questionnaire/toHighlightsString';
import toWeightKgLbs from '../../../../../questionnaire/toWeightKgLbs';
import toAge from '../../../../../questionnaire/toAge';
import toSex from '../../../../../questionnaire/toSex';
import getAgeAndBmi from './pageUtils';
import { testReducer } from '../../../../../questionnaire/fallRiskReducers';
import { useValue } from '../ValueContext';
import get from 'lodash/get';
import toUsageString from '../../../../../questionnaire/toUsageString';
import skin from '../../../../../assets/images/skin_assessment.png';
import Barcode from '../../Barcode';
import toHighlightsString4 from '../../../../../questionnaire/toHighlightsString4';
import { additionalcontent } from '../golfCoast/pages/contentlimit'
import MedicalPassportTextArea2 from '../components/MedicalPassportTextArea2';

export const CommonPartP5P6 = ({ providerId, showQRCode, age }) => (
    <div style={{ ...styles.border, position: 'relative' }}>
        <div style={{ bottom: '5px', paddingLeft: '3px' }}>
            <div style={{ marginBottom: '2px' }}>
                <MedicalPassportField name="procedure.patient.name" label="Patient" fontWeight={'bold'} fontSize={14} />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '3px' }}>
                <MedicalPassportField name="dob" label="DOB" path={['demographicInformation', 'dob']} fontSize={12} />
                <MedicalPassportField name="procedure.serviceTime" label="DOS" fontWeight={700} fontSize={12} />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '3px' }}>
                <MedicalPassportField name="procedure.patient.providerId" label="MRN#" fontSize={12} />
                <MedicalPassportField name="procedure.physician.name" label="Surgeon" fontSize={12} />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '3px', paddingBottom: '1px' }}>
                <MedicalPassportField
                    name="age"
                    label="Age"
                    path={['demographicInformation', 'dob']}
                    reducer={toAge}
                    warning={age > 70}
                    fontSize={12}
                />
                <MedicalPassportField
                    name="sex"
                    label="Sex"
                    path={['demographicInformation', 'sex']}
                    reducer={toSex}
                    fontSize={12}
                />
            </div>
        </div>
    </div>
);

const Page5 = ({ formName, pageNumber, showQRCode, hospitalName, value, defaultValue }) => {
    const providerId = defaultValue?.procedure?.patient?.providerId;
    const weightLbs = parseInt(defaultValue?.demographicInformation?.bmi?.weightLb);
    const { age } = getAgeAndBmi(defaultValue?.demographicInformation?.dob, defaultValue?.demographicInformation?.bmi);

    const { questionnaireAnswers } = useValue();
    let cardiacPacemaker = get(
        questionnaireAnswers,
        'sections.Cardiac.schema.cardiac_list.checkboxes["Pacemaker/AICD/Other Heart Monitor"][0]',
        false
    );
    let cardiacStent = get(
        questionnaireAnswers,
        'sections.Cardiac.schema.cardiac_list.checkboxes["Cardiac stents"][0]',
        false
    );

    const allergy = additionalcontent(toHighlightsString4, [['allergies']], 170);

    if (allergy == 'No additional info') {
        return ''
    } else {
        return (
            <div id="page5" className="page" style={{ fontSize: '11px', fontFamily:'Arial' }}>
                <div style={{ ...styles.twoColumns, paddingTop: '1rem' }}>
                    <div>
                        <div>{hospitalName || ''}</div>
                        <MedicalPassportTextArea name="procedure.name" label="Procedure" fontSize={12} />
                        <h3 style={{ margin: '5px 0' }}>Pre-Procedure Nursing Assessment</h3>
                    </div>
                    <div>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '2px' }}>
                            {showQRCode && (
                                <Barcode
                                    formName={formName}
                                    pageNumber={pageNumber}
                                    providerId={defaultValue?.procedure?.patient?.providerId}
                                    serviceTime={defaultValue?.procedure?.serviceTime}
                                />
                            )}
                        </div>
                        <CommonPartP5P6 age={age} showQRCode={showQRCode} providerId={providerId} />
                    </div>
                </div>
                <div style={styles.allergiesSection}>
                    <div style={styles.activeTab}>ALLERGIES</div>
                    <MedicalPassportTextArea2
                        name="allergies2"
                        value={allergy}
                        warning={true}
                        fontSize={11}
                    />
                </div>
            </div>
        );
    };
}

export default Page5;
