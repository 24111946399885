import { gql } from '@apollo/client';
import { fragments as statisticsFragments } from './dashboard';

export const fragments = {
  all: gql`
    fragment FeedbackData on Feedback {
      id
      rating
      suggestion
      submittedAt
      patientId
      physician {
        id
        name
      }
      patient {
        id
        name
        dateOfSurgery
        phoneNumber
      }
    }
  `,
};

export const patientFeedback = gql`
  query patientFeedback($id: Long!) {
    feedback: patientFeedback(id: $id) {
      ...FeedbackData
    }
  }
  ${fragments.all}
`;

export const list = gql`
  query ($filter: Filter) {
    feedbacks(filter: $filter) {
      ...FeedbackData
    }
  }
  ${fragments.all}
`;

export const listWPhysician = gql`
  query ($filter: Filter) {
    feedbacks(filter: $filter) {
      ...FeedbackData
      physician {
        id
        name
      }
    }
  }
  ${fragments.all}
`;

export const feedbackStats = gql`
  query feedbackStats($filter: Filter) {
    feedbackStats(filter: $filter) {
      ...StatisticsData
    }

    hospitalRatingStats(filter: $filter) {
      averageRating
      population
      voters
      votes
      turnout
    }
  }
  ${statisticsFragments.all}
`;

export const hospitalRatingStats = gql`
  query hospitalRatingStats($filter: Filter) {
    hospitalRatingStats(filter: $filter) {
      averageRating
      population
      voters
      votes
      turnout
    }
  }
`;

export const feedbackConfigQuery = gql`
  query feedbackConfiguration {
    feedbackConfiguration {
      recipients
      maximumRating
    }
  }
`;

export const patientFeedbackReportQuery = gql`
  query patientFeedbackReport($filter: Filter) {
    patientFeedbackReport(filter: $filter)
  }
`;

export const caretakerFeedbackReportQuery = gql`
  query caretakerFeedbackReport($filter: Filter) {
    caretakerFeedbackReport(filter: $filter)
  }
`;

export const feedbackConfigMutation = gql`
  mutation updateFeedbackConfiguration($recipients: [Long!]!, $maximumRating: Int!) {
    updateFeedbackConfiguration(recipients: $recipients, maximumRating: $maximumRating)
  }
`;

export default {
  list,
  listWPhysician,
  feedbackStats,
};
