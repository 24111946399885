import React from 'react';
import { isFunction } from '../../../../se/utilities/check';
import { Box, Link, Typography } from '@material-ui/core';

interface QuestionActionProps {
  question: string;
  onClick?: () => void;
  children: React.ReactNode;
}

const QuestionAction = ({ question, onClick, children }: QuestionActionProps) => {
  const handleClick = e => {
    e.preventDefault();

    if (onClick && isFunction(onClick)) {
      onClick();
    }
  };

  // eslint-disable-next-line no-script-url
  const nothing = 'javascript:void(0)';

  return (
    <Box>
      <Typography>{question}</Typography>
      <Link href={nothing} onClick={handleClick}>
        {children}
      </Link>
    </Box>
  );
};

export default QuestionAction;
