import React from 'react';
import MedicalPassportCheckbox from './MedicalPassportCheckbox';
import MedicalPassportField from './MedicalPassportField';

const MedicalPassportAllergiesRow = ({ text, name }) => (
  <div
    style={{
      display: 'grid',
      gridTemplateColumns: '3.5fr 1fr 1fr 1fr 4fr',
      border: '1px solid black',
      borderBottom: 0,
      lineHeight: '18px',
      padding: '0 3px',
    }}
  >
    <div style={{ borderRight: '1px solid black' }}>{text}</div>
    <div style={{ borderRight: '1px solid black' }}>
      <MedicalPassportCheckbox name={`${name}Yes`} />
    </div>
    <div style={{ borderRight: '1px solid black' }}>
      <MedicalPassportCheckbox name={`${name}No`} />
    </div>
    <div style={{ borderRight: '1px solid black' }}>
      <MedicalPassportCheckbox name={`${name}NA`} />
    </div>
    <MedicalPassportField name={`${name}Other`} />
  </div>
);

export default MedicalPassportAllergiesRow;
