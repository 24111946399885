import React, { useState } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

import Spinner from '../../../se/components/Spinner';
import { useApolloClient } from '@apollo/client';
import { patientFeedbackReportQuery, caretakerFeedbackReportQuery } from '../../../graph/feedback';

const PDFFeedbackButton = ({ filter }: { filter: any }) => {
  const [numberOfTasksRunning, setNumberOfTasksRunning] = useState<number>(0);
  const busy = numberOfTasksRunning > 0;
  const client = useApolloClient();
  const isPatientTab = location.pathname.includes('/patient');

  const handleClick = async () => {
    if (busy) return;

    setNumberOfTasksRunning(prev => prev + 1);

    try {
      const result = await client.query({
        query: isPatientTab ? patientFeedbackReportQuery : caretakerFeedbackReportQuery,
        variables: { filter },
      });

      window.open(isPatientTab ? result.data.patientFeedbackReport : result.data.caretakerFeedbackReport, '_blank');
    } finally {
      setNumberOfTasksRunning(prev => prev - 1);
    }
  };

  return (
    <Tooltip title={busy ? 'Preparing report…' : 'Download Report'} open={busy || undefined}>
      <IconButton onClick={handleClick} color="primary" disabled={busy}>
        {busy ? <Spinner /> : <PictureAsPdfIcon />}
      </IconButton>
    </Tooltip>
  );
};

export default PDFFeedbackButton;
