import React from 'react';
import styles from '../../styles';
import MedicalPassportField from '../../components/MedicalPassportField';
import MedicalPassportTextArea from '../../components/MedicalPassportTextArea';
import MedicalPassportRow from '../../components/MedicalPassportRow';
import {
  cardiovascular,
  endocrine,
  gastrointestinal,
  hepatic,
  infectious,
  musculoskeletal,
  neurological,
  oncologic,
  pain,
  psychiatric,
  pulmonary,
  renal,
  vascular,
} from '../paths';
import toAge from '../../../../../../questionnaire/toAge';
import toBMI from '../../../../../../questionnaire/toBMI';
import toWeight from '../../../../../../questionnaire/toWeight';
import toHeight from '../../../../../../questionnaire/toHeight';
import toSex from '../../../../../../questionnaire/toSex';
import toHighlightsString from '../../../../../../questionnaire/toHighlightsString';
import MedicalPassportCheckbox from '../../components/MedicalPassportCheckbox';
import getAgeAndBmi from './pageUtils';
import Barcode from '../../../Barcode';
import toHighlightsString4 from '../../../../../../questionnaire/toHighlightsString4';
import {getcontent} from '../../golfCoast/pages/contentlimit'
import MedicalPassportTextArea2 from '../../components/MedicalPassportTextArea2';

export const MedicalPassportCheckboxStyledWrapper = ({ children, borderLeft }) => (
  <div
    style={{
      borderLeft: borderLeft ? borderLeft : '1px solid black',
      padding: '2px 0 0 20px',
    }}
  >
    {children}
  </div>
);

const Page3 = ({ formName, pageNumber, showQRCode, hospitalName, value, defaultValue }) => {
  const providerId = defaultValue?.procedure?.patient?.providerId;
  const { age, bmi } = getAgeAndBmi(
    defaultValue?.demographicInformation?.dob,
    defaultValue?.demographicInformation?.bmi
  );


  const surgery = getcontent(toHighlightsString, [['priorSurgeries']], 70);
  const allergy = getcontent(toHighlightsString4, [['allergies']], 200);
  const medication = getcontent(toHighlightsString, [['medications']], 70);

  return (
    <div id="page3" className="page" style={{ fontSize: '11px', fontFamily:'Arial' }}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '0.2px' }}>
          <h2>History & Physical</h2>
          <div>{hospitalName || ''}</div>
        </div>
        <div>
          {showQRCode && (
            <Barcode
              formName={formName}
              pageNumber={pageNumber}
              providerId={defaultValue?.procedure?.patient?.providerId}
              serviceTime={defaultValue?.procedure?.serviceTime}
            />
          )}
        </div>
      </div>
      <div style={{ border: '1px solid black', borderBottom: 0, borderLeft: 0 }}>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '55% 45%',
            borderBottom: '1px solid black',
          }}
        >
          <div>
            <div
              style={{
                border: '1px solid black',
                borderTop: 0,
                minHeight: '60px',
                height: '100%',
                padding: '1px 2px 0 0',
                borderBottom: '0px',
              }}
            >
              <div
                style={{
                  paddingBottom: '35px',
                }}
              >
                <MedicalPassportTextArea name="procedure.name" label="Procedure" fontSize={12}/>
              </div>
              <div
                style={{
                  border: '1px solid black',
                  padding: '2px 1px 0 0',
                  borderBottom: 0,
                  borderRight: 0,
                  borderLeft: 0,
                }}
              >
                <MedicalPassportField name="preOpDiagnosis" label="Preop Diagnosis" fontSize={12}/>
              </div>
            </div>
          </div>
          <div>
            <div style={{ bottom: '5px', paddingLeft: '3px' }}>
              <div style={{ marginBottom: '2px' }}>
                <MedicalPassportField name="procedure.patient.name" label="Patient" fontWeight={'bold'} fontSize={14} />
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '2px' }}>
                <MedicalPassportField name="dob" label="DOB" path={['demographicInformation', 'dob']} fontSize={12}/>
                <MedicalPassportField name="procedure.serviceTime" label="DOS" fontWeight={700} fontSize={12}/>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '2px' }}>
                <MedicalPassportField name="procedure.patient.providerId" label="MRN#" fontSize={12}/>
                <MedicalPassportField name="procedure.physician.name" label="Surgeon" fontSize={12}/>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '2px', paddingBottom: '1px' }}>
                <MedicalPassportField
                  name="age"
                  label="Age"
                  path={['demographicInformation', 'dob']}
                  reducer={toAge}
                  warning={age > 70}
                fontSize={12}/>
                <MedicalPassportField name="sex" label="Sex" path={['demographicInformation', 'sex']} reducer={toSex} fontSize={12}/>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '2px', paddingBottom: '1px' }}>
                <MedicalPassportField
                  name="cardiologist"
                  label="Cardiologist"
                  path={['demographicInformation', 'cardiologist']}
                fontSize={12}/>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '2px', paddingBottom: '1px' }}>
                <MedicalPassportField
                  name="procedure.pcp"
                  path={['demographicInformation', 'primaryCareDoctor']}
                  label="PCP"
                fontSize={12}/>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            borderLeft: '1px solid black',
            borderBottom: '1px solid black',
          }}
        >
          <MedicalPassportTextArea2
            label="Med Allergies/Sensitivities"
            name="allergies1"
            value={allergy}
            warning={true}
            fontSize={10}
          />
        </div>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(6, 1fr)',
            borderLeft: '1px solid black',
            height: '20px',
          }}
        >
          <MedicalPassportCheckboxStyledWrapper
            borderLeft={'0'}
            children={
              <MedicalPassportCheckbox path={['basicscreening', 'smoking']} label="Smoking Hx" name="page3.smoking" />
            }
          />
          <MedicalPassportCheckboxStyledWrapper
            children={<MedicalPassportCheckbox path={['Psychiatric', 'drinking']} label="ETOH Hx" name="page3.etoh" />}
          />
          <MedicalPassportCheckboxStyledWrapper
            children={<MedicalPassportCheckbox path={['Psychiatric', 'drugs']} label="Drug Hx" name="page3.drugHx" />}
          />
          <MedicalPassportCheckboxStyledWrapper
            children={
              <MedicalPassportField name="ht" label="Ht" path={['demographicInformation', 'bmi']} reducer={toHeight} fontSize={10}/>
            }
          fontSize={10}/>
          <MedicalPassportCheckboxStyledWrapper
            children={
              <MedicalPassportField name="wt" label="Wt" path={['demographicInformation', 'bmi']} reducer={toWeight} fontSize={10}/>
            }
          />
          <MedicalPassportCheckboxStyledWrapper
            children={
              <MedicalPassportField
                name="bmi"
                label="BMI"
                path={['demographicInformation', 'bmi']}
                reducer={toBMI}
                warning={bmi !== '-' ? bmi > 40 : false}
                fontSize={10}
              />
            }
          />
        </div>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
          }}
        >
          <div style={styles.medicationsInnerDiv}>
            <MedicalPassportTextArea2
              label="Current Medications"
              name="medications1"
              value={medication}
              fontSize={10}
            />
          </div>
          <div style={{ ...styles.medicationsInnerDiv }}>
            <MedicalPassportTextArea2
              label="Surgical/Anesthesia Hx"
              name="anesthesia1"
              value={surgery}
              fontSize={10}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          border: '1px solid black',
          padding: '0.1rem',
          borderTop: 0,
          borderRight: '1px solid black',
        }}
      >
        <MedicalPassportTextArea label="History of Present Illness and Response to Care" name="illness1" fontSize={10}/>
      </div>
      <section style={{ breakInside: 'avoid' }}>
        <div style={styles.greyBackground}>
          <div>Review of Systems</div>
        </div>
        <div
          style={{
            border: '1px solid black',
            lineHeight: '18px',
            borderLeft: 0,
            borderRight: 0,
          }}
        >
          <MedicalPassportRow
            label="Cardiovascular"
            name="systems.cardiovascular"
            paths={cardiovascular}
            style={{ borderTop: 0 }}
            index={0}
            fontSize={10}
          />
          <MedicalPassportRow label="Pulmonary" name="systems.pulmonary" paths={pulmonary} index={1} fontSize={10} />
          <MedicalPassportRow label="Renal" name="systems.renal" paths={renal} index={2} fontSize={10} />
          <MedicalPassportRow label="Hepatic" name="systems.hepatic" paths={hepatic} index={3} fontSize={10} />
          <MedicalPassportRow label="Neurological" name="systems.neurological" paths={neurological} index={4} fontSize={10} />
          <MedicalPassportRow
            label="Gastrointestinal"
            name="systems.gastrointestinal"
            paths={gastrointestinal}
            index={5}
          fontSize={10} />
          <MedicalPassportRow label="Vascular" name="systems.vascular" paths={vascular} index={6} fontSize={10} />
          <MedicalPassportRow label="Endocrine/Metabolic" name="systems.endocrine2" paths={endocrine} index={7} fontSize={10} />
          <MedicalPassportRow
            label="Musculoskeletal"
            name="systems.musculoskeletal"
            paths={musculoskeletal}
            index={8}
          fontSize={10} />
          <MedicalPassportRow label="Psychiatric" name="systems.psychiatric" paths={psychiatric} index={9} fontSize={10} />
          <MedicalPassportRow label="Oncology" name="systems.ontological" paths={oncologic} index={10} fontSize={10} />
          <MedicalPassportRow label="Infectious Disease" name="systems.infDis" paths={infectious} index={11} fontSize={10} />
          <MedicalPassportRow label="Other" name="systems.other" paths={pain} index={12} fontSize={10} />
        </div>
      </section>
      <section style={{ breakInside: 'avoid' }}>
        <div
          style={{
            ...styles.greyBackground,
            textAlign: 'center',
          }}
        >
          Physical Exam
        </div>
        <div
          style={{
            lineHeight: '15px',
            border: '1px solid black',
            borderBottom: 0,
          }}
        >
          <MedicalPassportField name="page3.diagnosis" label="Diagnosis" fontSize={10}/>
          <MedicalPassportField name="page3.treatmentPlan" label="Treatment Plan" fontSize={10}/>
        </div>
        <div style={{ border: '1px solid black', padding: '0 5px', lineHeight: '12px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex' }}>
              <div style={{ display: 'flex' }}>
                <MedicalPassportField name="page3.bp" label="BP" fontSize={10}/>
              </div>
              <div style={{ display: 'flex' }}>
                <MedicalPassportField name="page3.pulse" label="Pulse" fontSize={10}/>
              </div>
              <div style={{ display: 'flex' }}>
                <MedicalPassportField name="page3.resp" label="Resp" fontSize={10}/>
              </div>
              <div style={{ display: 'flex', whiteSpace: 'nowrap' }}>
                <MedicalPassportField name="page3.O2Sat" label="O2 Sat" fontSize={10}/>
              </div>
            </div>
            <div style={{ display: 'flex', whiteSpace: 'nowrap', alignItems: 'center' }}>
              <MedicalPassportCheckbox label="Pre-Op Scrub x 5 min" name="page3.preOpScrub" />
            </div>
          </div>
          <div>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 2fr 2fr' }}>
              <div>Mental Status:</div>
              <div>
                <MedicalPassportCheckbox label="A&O x 3" name="page3.mentalStatus.a&o3" />
              </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <MedicalPassportCheckbox name="page3.mentalStatus.other" />
                <MedicalPassportField name="page3.mentalStatus.otherText" label="Other" fontSize={10} />
              </div>
              <div style={{ paddingLeft: '49%' }}>Preop IV Antibiotic</div>
            </div>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 2fr 2fr' }}>
              <div>Heart:</div>
              <div>
                <MedicalPassportCheckbox label="WNL" name="page3.heart.wnl" />
              </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <MedicalPassportCheckbox name="page3.heart.other" />
                <MedicalPassportField name="page3.heart.otherText" label="Other"fontSize={10}  />
              </div>
              <div style={{ paddingLeft: '47%' }}>
                <MedicalPassportCheckbox label="Ancef 1gm/2gm" name="page3.antibiotic.ancef" />
              </div>
            </div>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 2fr 2fr' }}>
              <div>Lungs:</div>
              <div>
                <MedicalPassportCheckbox label="WNL" name="page3.lungs.wnl" />
              </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <MedicalPassportCheckbox name="page3.lungs.other" />
                <MedicalPassportField name="page3.lungs.otherText" label="Other"fontSize={10} />
              </div>
              <div style={{ paddingLeft: '47%' }}>
                <MedicalPassportCheckbox label="Cleocin 900mg" name="page3.antibiotic.cleocin" />
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', gap: '0' }}>
            <div style={{ textDecoration: 'underline', fontWeight: 'bold' }}>ASSESSMENT:</div>
            <div>
              <MedicalPassportCheckbox
                label="History and medical condition reviewed, no changes: pt cleared for procedure"
                name="page3.assessment1"
              />
              <MedicalPassportCheckbox
                label="Medical condition and diagnosis appropriate for planned anesthetic"
                name="page3.assessment2"
              />
              <MedicalPassportCheckbox
                label="I have explained the procedure/surgery including appropriate alternatives, benefits, side effects and risks. I have answered all the patient’s/guardian’s questions. Patient/guardian accepts proposed plan."
                name="page3.assessment3"
              />
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', padding: '0px 10px 0px 10px' }}>
            <div>Physician Signature: ________________________</div>
            <div>Date/Time: ________________________</div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Page3;
